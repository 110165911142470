import Vue from 'vue';
import VueRouter from "vue-router";
import Wallets from '../components/pages/Wallets';
import WalletsMarket from '../components/pages/WalletsMarket';
import Setting from '../components/pages/Setting';
// import SettingSecurity from '../components/pages/SettingSecurity';
// import SettingProfile from '../components/pages/SettingProfile';
import Main from "../components/Main";
import Office from "../components/Office";
import Authentication from "../components/Authentication";
import Home from "../components/pages/Home";
import AuthSignup from "../components/pages/AuthSignup";
import AuthLogin from "../components/pages/AuthLogin";
import AuthForgot from "../components/pages/AuthForgot";
import AuthResetPass from "../components/pages/AuthResetPass";
import AuthSecurity from "../components/pages/AuthSecurity";
import NotFound from '../components/pages/NotFound';

import store from "../store/index";
import middlewarePipeline from "./middlewarePipeline";
import guest from "./middleware/guest";
import auth from "./middleware/auth";
import Terms from "../components/pages/Terms";
import Privacy from "../components/pages/Privacy";
import Dashboard from "../components/pages/Dashboard";
import TransactionHistory from "../components/pages/TransactionHistory";
import Deposit from "../components/pages/Deposit";
import Withdraw from "../components/pages/Withdraw";
import Cards from "../components/pages/Cards";
import Exchange from "../components/pages/Exchange";
import AffiliateProgram from "../components/pages/AffiliateProgram";
import Support from "../components/pages/Support";
import Ticket from "../components/pages/Ticket";
import Business from "../components/Business";
import BusinessSignup from "../components/pages/BusinessSignup";
import HomeBusiness from "../components/pages/HomeBusiness";
import Crypocurrencies from "../components/pages/Crypocurrencies";
import Employees from "../components/pages/Employees";
import PageOrderCard from "../components/pages/Cards/PageOrderCard";
import PageCard from "../components/pages/Cards/PageCard";

import CardSwap from "../components/pages/CardSwap";
import CardSwapWithdraw from "../components/pages/CardSwapWithdraw";
import CardSwapTransactionHistory from "../components/pages/CardSwapTransactionHistory";

Vue.use(VueRouter);
const routes = new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    routes: [
        {
            path: '/',
            component: Main,
            children: [
                {
                    path: (process.env.NODE_ENV === 'production') ? '/' : '/dist/',
                    name: 'home',
                    component: Home,
                    meta: {
                        title: 'Home',
                        middleware: [
                            guest
                        ]
                    },
                    children: [
                        {
                            path: '/u/:id'
                        }
                    ]
                },
                {
                    path: '/:lang?/terms',
                    name: 'terms',
                    component: Terms,
                },
                {
                    path: '/:lang?/privacy',
                    name: 'privacy',
                    component: Privacy,
                },
                // {
                //     path: 'terms',
                //     name: 'terms',
                //     component: Terms,
                //     meta: {
                //         title: 'Terms & Conditions',
                //     },
                // },

                // {
                //     path: 'privacy',
                //     name: 'privacy',
                //     component: Privacy,
                //     meta: {
                //         title: 'Privacy Policy',
                //     },
                // },
            ]
        },
        {
            path: '/authentication',
            component: Authentication,
            children: [
                {
                    path: '/registration',
                    name: 'register',
                    component: AuthSignup,
                    meta: {
                        title: 'Registration',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/business/create',
                    name: 'business_create',
                    component: BusinessSignup,
                    meta: {
                        title: 'Create business',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/login',
                    name: 'login',
                    component: AuthLogin,
                    meta: {
                        title: 'Login',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/forgot',
                    name: 'forgot',
                    component: AuthForgot,
                    meta: {
                        title: 'Forgot',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/security',
                    name: 'security',
                    component: AuthSecurity,
                    meta: {
                        title: 'Authentication security',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/ajax/password/reset/:token',
                    name: 'password_reset',
                    component: AuthResetPass,
                    meta: {
                        title: 'Reset password',
                        middleware: [
                            guest
                        ]
                    },
                },
            ]
        },
        {
            path: '/',
            component: Office,
            children: [
                // {
                //     path: 'setting/profile',
                //     name: 'setting_profile',
                //     component: SettingProfile,
                //     meta: {
                //         title: 'Setting',
                //         middleware: [
                //             auth
                //         ]
                //     },
                // },
                // {
                //     path: 'setting/security/2fa',
                //     name: 'setting_security',
                //     component: SettingSecurity,
                //     meta: {
                //         title: 'Security',
                //         middleware: [
                //             auth
                //         ]
                //     },
                // },
                {
                    path: 'setting',
                    name: 'setting',
                    component: Setting,
                    meta: {
                        title: 'Setting',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: 'history',
                    name: 'history',
                    component: TransactionHistory,
                    meta: {
                        title: 'History',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: (process.env.NODE_ENV === 'production') ? '/' : '/dist/',
                    name: 'dashboard',
                    component: Dashboard,
                    meta: {
                        title: 'Home',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: 'wallets',
                    name: 'wallets',
                    component: Wallets,
                    meta: {
                        title: 'Wallets',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: 'deposit',
                    name: 'deposit',
                    component: Deposit,
                    meta: {
                        title: 'Top up',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: 'withdraw',
                    name: 'withdraw',
                    component: Withdraw,
                    meta: {
                        title: 'Withdraw',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: 'cards',
                    name: 'cards',
                    component: Cards,
                    meta: {
                        title: 'Cards',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: 'cards/order',
                    name: 'card_order',
                    component: PageOrderCard,
                    meta: {
                        title: 'Order Card',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: 'cards/:id',
                    name: 'card_page',
                    component: PageCard,
                    meta: {
                        title: 'Card',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: 'support',
                    name: 'support',
                    component: Support,
                    meta: {
                        title: 'Support',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: 'support/:id',
                    name: 'ticket',
                    component: Ticket,
                    meta: {
                        title: 'Ticket',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: 'affiliate',
                    name: 'affiliate',
                    component: AffiliateProgram,
                    meta: {
                        title: 'Referral Program',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: 'card_swap',
                    name: 'card_swap',
                    component: CardSwap,
                    meta: {
                        title: 'Card swap',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: 'card_swap_history',
                    name: 'card_swap_history',
                    component: CardSwapTransactionHistory,
                    meta: {
                        title: 'Card swap history',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: 'card_swap_withdraw',
                    name: 'card_swap_withdraw',
                    component: CardSwapWithdraw,
                    meta: {
                        title: 'Card swap withdraw',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: 'exchange',
                    name: 'exchange',
                    component: Exchange,
                    meta: {
                        title: 'Exchange',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: 'wallets/market/:currency',
                    name: 'wallets_market',
                    component: WalletsMarket,
                    meta: {
                        title: 'Wallets',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: 'merchant/:id',
                    name: 'merchant',
                    component: Wallets,
                    meta: {
                        title: 'Home',
                        middleware: [
                            auth
                        ]
                    },
                },
            ]
        },
        {
            path: '/business',
            component: Business,
            children: [
                {
                    path: '/business',
                    name: 'business',
                    component: HomeBusiness,
                    meta: {
                        title: 'Business',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/business/cryptocurrencies',
                    name: 'cryptocurrencies',
                    component: Crypocurrencies,
                    meta: {
                        title: 'Cryptocurrencies',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/business/employees',
                    name: 'employees',
                    component: Employees,
                    meta: {
                        title: 'Employees',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/business/support',
                    name: 'business_support',
                    component: Support,
                    meta: {
                        title: 'Support',
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/business/support/:id',
                    name: 'business_ticket',
                    component: Ticket,
                    meta: {
                        title: 'Ticket',
                        middleware: [
                            auth
                        ]
                    },
                },
            ]
        },
        {
            path: '/logout',
            name: 'logout',
        },
        {
            path: '*',
            name: 'not_found',
            component: NotFound,
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition || to.meta=='tab') {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});


routes.beforeEach((to, from, next) => {
    let title = "Neiva Wallet";

    if (to.meta.title) {
        document.title = title + ' | ' + to.meta.title
    } else {
        document.title = title
    }


    if (!to.meta.middleware) {
        return next();
    }
    const middleware = to.meta.middleware;

    const context = {
        to,
        from,
        next,
        store
    };


    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    });
});


export default routes;
