import Vue from 'vue';
import Vuex from 'vuex';

import language from './modules/language';
import auth from './modules/auth';
import chat from './modules/chat';
import preference from './modules/preference';
import business from './modules/business'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        language,
        auth,
        preference,
        chat,
        business
    },
    strict: debug,
});
