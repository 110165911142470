<template>
    <div class="page-ticket">
        <div v-if="ticket" class="block-ticket" ref="block_ticket">

            <div class="head-ticket">
                <router-link :to="{name: 'support'}" class="btn-back">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_829_33681)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8.00025C16 8.26544 15.8947 8.51976 15.7071 8.70728C15.5196 8.8948 15.2653 9.00014 15.0001 9.00014L3.4154 9.00014L7.70893 13.2917C7.89668 13.4794 8.00216 13.7341 8.00216 13.9996C8.00216 14.2651 7.89668 14.5198 7.70893 14.7075C7.52117 14.8953 7.26653 15.0007 7.00101 15.0007C6.73548 15.0007 6.48084 14.8953 6.29308 14.7075L0.293754 8.70818C0.200638 8.61529 0.126761 8.50496 0.0763534 8.38348C0.025946 8.262 -6.00504e-07 8.13177 -6.12001e-07 8.00025C-6.23499e-07 7.86873 0.025946 7.73851 0.0763534 7.61703C0.126761 7.49555 0.200638 7.38521 0.293754 7.29233L6.29308 1.293C6.48084 1.10525 6.73548 0.999775 7.001 0.999775C7.26653 0.999775 7.52117 1.10525 7.70893 1.293C7.89668 1.48076 8.00216 1.7354 8.00216 2.00093C8.00216 2.26645 7.89668 2.52109 7.70893 2.70885L3.4154 7.00037L15.0001 7.00037C15.2653 7.00037 15.5196 7.10571 15.7071 7.29322C15.8947 7.48074 16 7.73507 16 8.00025Z" fill="currentColor"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_829_33681">
                                <rect width="16" height="15.9982" fill="white" transform="translate(16 15.999) rotate(180)"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <!--                <span>{{__('web_page_ticket_btn_back_to_tickets')}}</span>-->
                </router-link>
                <p class="head-ticket__title">Ticket {{ticket.public_id}}</p>

<!--                <div class="head-ticket__right">-->
<!--                    <div class="block-status" :style="{'background': (ticket.status) ? 'rgba(245, 30, 56, 0.1)' : 'rgba(1, 170, 79, 0.12)'}">-->
<!--                        <p v-if="ticket.status" class="block-status__text" style="color: #F51E38">{{__('web_page_support_item_ticket_status_closed')}}</p>-->
<!--                        <p v-else class="block-status__text" style="color: #01AA4F">{{__('web_page_support_item_ticket_status_open')}}</p>-->
<!--                    </div>-->

<!--                    <p class="block-date">{{ticket.created_at | moment('MMM DD, YYYY')}}</p>-->
<!--                </div>-->
            </div>

<!--            <div class="body-ticket">-->
<!--                <p class="body-ticket__title">{{ticket.title}}</p>-->
<!--                <div class="wrap-desc">-->
<!--                    <p class="body-ticket__desc">{{ticket.description | truncate(150)}}</p>-->
<!--                </div>-->
<!--            </div>-->

            <div class="bottom-ticket">
<!--                <div class="count-messages">-->
<!--                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <g clip-path="url(#clip0_829_33307)">-->
<!--                            <path d="M14.0064 12.2658C14.9601 11.4158 15.5002 10.2829 15.5002 9.10716C15.5002 7.85766 14.9152 6.68909 13.8531 5.81653C12.8193 4.96759 11.451 4.5 10 4.5C8.549 4.5 7.18069 4.96759 6.14709 5.81659C5.08494 6.68909 4.5 7.85766 4.5 9.10716C4.5 10.3567 5.08494 11.5252 6.14709 12.3978C7.18069 13.2468 8.549 13.7143 10 13.7143C10.1933 13.7143 10.3879 13.7058 10.5808 13.6888L10.9103 13.9745C12.0457 14.9583 13.4977 15.4999 15 15.5H15.5V14.4357L15.3536 14.2893C14.7759 13.7099 14.3181 13.0223 14.0064 12.2658ZM11.5653 13.2188L10.904 12.6456L10.6835 12.6726C10.4567 12.7004 10.2285 12.7143 10 12.7143C7.51875 12.7143 5.5 11.0961 5.5 9.10716C5.5 7.11819 7.51875 5.5 10 5.5C12.4812 5.5 14.5 7.11816 14.5 9.10716C14.5 10.0981 14.0056 11.0232 13.1079 11.7121L12.82 11.9331L12.9528 12.3092C13.2251 13.0773 13.6254 13.7938 14.1367 14.4284C13.1845 14.2695 12.2948 13.8509 11.5653 13.2188Z" fill="#202934"/>-->
<!--                            <path d="M1.88078 9.92113C2.34882 9.32867 2.7163 8.66331 2.96853 7.95166L3.10041 7.57744L2.81269 7.35663C1.96619 6.70706 1.5 5.83525 1.5 4.90178C1.5 3.02603 3.40653 1.5 5.75 1.5C7.474 1.5 8.96147 2.32594 9.62741 3.50938C9.75106 3.50338 9.87525 3.5 10 3.5C10.2506 3.5 10.4988 3.51216 10.7448 3.53647C10.4875 2.86975 10.0405 2.26113 9.42606 1.75644C8.43975 0.946219 7.13438 0.5 5.75 0.5C4.36562 0.5 3.06025 0.946219 2.07387 1.75644C1.05894 2.59016 0.5 3.70719 0.5 4.90178C0.5 6.02053 1.011 7.0985 1.91406 7.90938C1.61936 8.61859 1.18874 9.2633 0.646469 9.80716L0.5 9.95359V11H1C1.9246 10.9999 2.83648 10.7846 3.66347 10.3711C3.57279 10.0274 3.51965 9.67486 3.505 9.31969C3.00306 9.61387 2.45327 9.81745 1.88078 9.92113Z" fill="#202934"/>-->
<!--                        </g>-->
<!--                        <defs>-->
<!--                            <clipPath id="clip0_829_33307">-->
<!--                                <rect width="16" height="16" fill="white"/>-->
<!--                            </clipPath>-->
<!--                        </defs>-->
<!--                    </svg>-->

<!--                    {{messages.data.length}}-->
<!--                </div>-->
                <v-btn v-if="!ticket.status" v-ripple="false" class="btn-white" v-on:click="dialog= true">{{__('web_btn_close_ticket')}}</v-btn>
            </div>
        </div>
        <div v-else class="block-loading">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            <p class="block-loading__text">{{__('Loading')}}</p>
        </div>

        <div v-if="messages== false" class="block-loading">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            <p class="block-loading__text">{{__('Loading')}}</p>
        </div>
        <Messages
                v-bind:messages.sync="messages"
                v-bind:messages_id.sync="messages_id"
                v-bind:user_id="user_id"
                v-bind:ticket="ticket"
                v-else-if="messages"
                class="body"
        ></Messages>
        <ModalDefault v-if="dialog" width="470px" v-bind:modal_cancel.sync="dialog">
            <div class="modal-close-ticket">
                <p class="modal-close-ticket__text">{{__('web_modal_close_ticket_text')}}</p>

                <div class="block-btns">
                    <span class="btn-white" v-on:click="dialog=false">{{__('web_btn_cancel')}}</span>
                    <v-btn class="btn-site" v-on:click="onSubmit" v-on:loading="submitDisabled">{{__('web_btn_close')}}</v-btn>
                </div>
            </div>
        </ModalDefault>
    </div>
</template>

<script>
    import Messages from "./Ticket/Messages";
    import ModalDefault from "../modules/ModalDefault";
    // import DotLoader from 'vue-spinner/src/DotLoader.vue';
    export default {
        name: 'Support',
        data: function() {
            return {
                messages: [],
                messages_id: [],
                user_id: this.$route.params.id,
                ticket: null,
                submitDisabled: false,
                dialog: false,
            }
        },
        watch: {
            // message(messages) {
            // }
        },
        computed: {
            message() {
                return this.$store.getters.message;
            }
        },
        created() {
            let activeId= this.$route.params.id;
            this.$store.dispatch("asyncActiveId", activeId);


            this.sockets.subscribe('chatEvent', (data)=> {
                let messages= JSON.parse(data.messages);

                let user_id= this.user_id;
                if(messages instanceof Array) {
                    for (let i= 0; i < messages.length; i++) {
                        let message= messages[i];
                        if(message.user_id== user_id || message.to== user_id) {
                            this.updateMessages(message);
                        }
                    }
                } else {
                    if(messages.user_id== user_id || messages.to== user_id) {
                        this.updateMessages(messages);
                    }
                }
                //
                // if(activeId && activeId == data.messages.to) {
                //     // let messages= JSON.parse(data.messages);
                //     //
                //     //
                //     // messages.total= (this.$store.getters.message.total) ? this.$store.getters.message.total++ : 1;
                //
                //     this.$store.dispatch('asyncMessage', messages);
                // }
            });


            this.$http.get("ajax/chat/"+this.user_id)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                this.$store.dispatch('asyncResp', response.resp_tickets);
                response= response.data;
                response.messages.data= response.messages.data.reverse();
                this.messages= response.messages;
                let messages_id= [];

                this.user_id= response.user_id;
                for(let i=0; i < response.messages.data.length; i++) {
                    let message= response.messages.data[i];
                    messages_id.push(message.id);
                }
                this.messages_id= messages_id;
                this.ticket= response.ticket;
            });
        },
        methods: {
            onSubmit() {
                this.submitDisabled = true;

                let response = {
                    id: this.user_id
                };

                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/tickets/close', response)
                    .then(response => {
                        return response.json()
                    })
                    .then((response) => {
                        this.$store.dispatch('asyncResp', response.resp_tickets);
                        // response= {
                        //     "data": {
                        //         "address": "0xbe48fe67d24c47f846b6fd9bcdac6d62bc2108f0",
                        //         "amount": "31.00000000",
                        //         "commission": "1",
                        //         "percent_day": 5,
                        //         "percent_total": 155,
                        //         "payment_id": 3,
                        //         "tag": false,
                        //         "time": "2021/05/05 12:10:43",
                        //         "id": 6
                        //     },
                        //     "status": 200
                        // };

                        this.submitDisabled= false;
                        this.$router.push({name: 'support'});
                    }).catch((err) => {
                    this.submitDisabled= false;
                    this.httpHandler(err);
                });
            },
            updateMessages(message) {
                let messages= this.$cleanObject(this.messages);

                let indexMessage= this.$filterIndexObject(messages.data, message.id);
                message.deleted= null;
                if(indexMessage >= 0) {
                    messages.data[indexMessage]= message;
                } else {
                    this.messages_id.push(message.id);
                    messages.data.push(message);
                    messages.total += 1;
                }
                this.messages= messages;
            },
        },
        components: {
            // DotLoader,
            ModalDefault,
            Messages,
        }
    }
</script>
