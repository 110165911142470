<template>
    <div class="wrap-item-history"

    >
        <div class="item-history">
            <div class="tran">
                <p class="mob">{{__('Transaction type')}}</p>
                <div class="no-mob">
                    <div class="item-history__status">
                        <span  v-if="item.status == 0" class="expectation-status"><img src="/dist/images/clock.svg" alt=""></span>
                        <span  v-else-if="([1].includes(item.status))" class="completed-status"><img src="/dist/images/check-circle.svg" alt=""></span>
                        <span  v-else class="error-status"><img src="/dist/images/times-circle.svg" alt=""></span>
                    </div>
                    <p v-if="item.opp_type == 1" >{{__('web_history_Deposit')}}</p>
                    <p v-else >{{__('web_history_Withdraw')}}</p>
                </div>
            </div>
            <div class="summ">
              <p class="mob">Amount</p>
              <p class="item-history__sum" v-if="item.opp_type== 1">{{item.opp_type == 1 ? '+' : '-'}}{{$primNum(((item.amount_coin_arrived > 0) ? item.amount_coin_arrived : item.amount_coin), wallet.decimals)}} <span>{{wallet.sub}}</span></p>
              <p class="item-history__sum" v-else>{{item.opp_type == 1 ? '+' : '-'}}{{$primNum(item.amount_from, wallet.decimals)}} <span>{{wallet.sub}}</span></p>
            </div>
            <div class="summ">
                <p class="mob">Заявка</p>
                <p class="item-history__sum" v-if="item.opp_type== 1">{{$primNum(item.amount_card, 2)}} <span>RUB</span></p>
                <p class="item-history__sum" v-else>-</p>
            </div>
            <div class="date">
                <p class="mob">{{__('Date')}}</p>
                <p class="item-history__date">{{item.created_at | moment("DD.MM.YYYY, HH:mm:ss")}}</p>
            </div>

            <div class="item-history__asset">
                <p class="mob">{{__('Net')}}</p>
                <div class="ass">
                    <div class="block-img">
                        <img :src='wallet.img'>
                    </div>

                    <div class="block-name">

                        <!--                    <p>{{wallet.name | clearStr}}</p>-->
                        <span>{{wallet.sub}}</span>
                    </div>
                </div>
            </div>
            <div class="item-history__txid">
                <p class="mob">Type swap</p>
                <p v-if="(item.status == 1 || item.opp_type== 1) && item.txid.toString().length > 12">{{item.txid.slice(0, 6) + '...' + item.txid.slice(-6)}}</p>
                <p v-else>{{item.txid}}</p>
            </div>

        </div>
    </div>
</template>

<script>

    import moment from 'moment';

    export default {
        name: 'HistoryItem',
        data: function() {
            return {
            }
        },
        computed: {
            check_date() {
                if(this.history[this.index-1]) {
                    let created_at= moment.utc(this.item.created_at).format('DD.MM.YYYY');
                    let prev_at= moment.utc(this.history[this.index-1].created_at).format('DD.MM.YYYY');
                    return created_at!= prev_at;
                } else {
                    return true;
                }
            },
            user() {
                return this.$store.getters.user
            },
        },
        props: {
            history: {
                type: Array
            },
            index: {
                type: Number
            },
            item: {
                type: Object
            },
            wallet: {
                type: Object
            },
            modal_info: {
                type: Object
            },
        },
        created() {

        },
        methods: {

        },
        components: {
        }
    }
</script>