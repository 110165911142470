export default {
    state: {
        users_list: null,
        // user: null,
        message: null,
        active_id: null,
    },
    getters: {
        users_list(state){
            return state.users_list;
        },
        // user(state){
        //     return state.user;
        // },
        message(state){
            return state.message;
        },
        active_id(state){
            return state.active_id;
        },
    },
    actions: {
        asyncUsersList(context, payload) {
            context.commit('updateUsersList', payload);
        },
        // asyncUser(context, payload) {
        //     context.commit('updateUser', payload);
        // },
        asyncMessage(context, payload) {
            context.commit('asyncMessage', payload);
        },
        asyncActiveId(context, payload) {
            context.commit('asyncActiveId', payload);
        },
    },
    mutations: {
        updateUsersList(state, data) {
            this.state.chat.users_list= data;
        },
        // updateUser(state, data) {
        //     this.state.chat.user= data;
        // },
        asyncMessage(state, data) {
            this.state.chat.message= data;
        },
        asyncActiveId(state, data) {
            this.state.chat.active_id= data;
        },
    }
}
