<template>
  <div class="block-wallet" @mouseenter="blockScroll()" @mouseleave="unblockScroll()">
    <div class="block-wallet__head" :class="(wallets_list.length && wallets_list.length > 1) ? '': 'block-wallet__head--small'">
      <v-btn
         v-ripple="false"
         class="btn-close"
         v-on:click="modalCancel"
      >
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.93966 6.00017L12.7997 1.14017C12.9089 1.01264 12.9659 0.848589 12.9595 0.680805C12.953 0.513021 12.8834 0.353862 12.7647 0.235132C12.646 0.116403 12.4868 0.0468473 12.319 0.0403665C12.1512 0.0338857 11.9872 0.0909568 11.8597 0.200174L6.99966 5.06017L2.13966 0.193508C2.01212 0.0842899 1.84807 0.0272195 1.68029 0.0337003C1.5125 0.0401811 1.35334 0.109736 1.23461 0.228465C1.11589 0.347195 1.04633 0.506354 1.03985 0.674138C1.03337 0.841923 1.09044 1.00597 1.19966 1.13351L6.05966 6.00017L1.19299 10.8602C1.1232 10.9199 1.06652 10.9935 1.02651 11.0762C0.986491 11.1589 0.964004 11.249 0.960458 11.3408C0.956911 11.4326 0.972382 11.5242 1.0059 11.6097C1.03941 11.6953 1.09025 11.773 1.15522 11.8379C1.22019 11.9029 1.29789 11.9538 1.38344 11.9873C1.46899 12.0208 1.56055 12.0363 1.65236 12.0327C1.74417 12.0292 1.83426 12.0067 1.91697 11.9667C1.99968 11.9266 2.07323 11.87 2.13299 11.8002L6.99966 6.94018L11.8597 11.8002C11.9872 11.9094 12.1512 11.9665 12.319 11.96C12.4868 11.9535 12.646 11.8839 12.7647 11.7652C12.8834 11.6465 12.953 11.4873 12.9595 11.3195C12.9659 11.1518 12.9089 10.9877 12.7997 10.8602L7.93966 6.00017Z" fill="#6A6E85"/>
        </svg>
      </v-btn>

      <div class="block-info">
        <img class="block-info__img" :src=wallets_list[0].img>

        <div class="block-info__text">
          <div class="head-balance">
            <p class="head-balance__title">{{__('web_history_total')}} {{wallets_list[0].symbol}} {{__('web_history_balance')}}</p>

            <v-btn v-ripple="false" v-if="show" v-on:click="show= !show">
              <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.7142 9.135C18.1092 9.65167 18.1092 10.3492 17.7142 10.865C16.47 12.4892 13.485 15.8333 9.99999 15.8333C6.51499 15.8333 3.52999 12.4892 2.28582 10.865C2.09364 10.6176 1.98932 10.3133 1.98932 10C1.98932 9.68674 2.09364 9.38239 2.28582 9.135C3.52999 7.51084 6.51499 4.16667 9.99999 4.16667C13.485 4.16667 16.47 7.51084 17.7142 9.135V9.135Z" stroke="#6A6E85" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z" stroke="#6A6E85" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </v-btn>

            <v-btn v-else v-ripple="false" v-on:click="show= !show">
              <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.72753 14.2742C4.19003 13.1825 2.97336 11.7625 2.28586 10.8658C2.09361 10.6183 1.98926 10.3138 1.98926 10.0004C1.98926 9.687 2.09361 9.38251 2.28586 9.13499C3.53003 7.51082 6.51503 4.16666 10 4.16666C11.5634 4.16666 13.025 4.83916 14.275 5.72832" stroke="#6A6E85" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.33337 15.5867C8.87438 15.7477 9.43558 15.8308 10 15.8333C13.485 15.8333 16.47 12.4892 17.7142 10.865C17.9064 10.6173 18.0106 10.3126 18.0105 9.99902C18.0103 9.68547 17.9058 9.38089 17.7134 9.13333C17.2761 8.5629 16.807 8.01759 16.3084 7.5M11.775 8.23916C11.5436 8.00388 11.2679 7.81675 10.9638 7.68857C10.6597 7.56038 10.3332 7.49367 10.0032 7.49229C9.67319 7.4909 9.34617 7.55488 9.041 7.68051C8.73583 7.80615 8.45856 7.99096 8.22517 8.22429C7.99179 8.45762 7.80691 8.73485 7.6812 9.03999C7.55549 9.34513 7.49145 9.67214 7.49275 10.0022C7.49406 10.3322 7.56069 10.6587 7.6888 10.9628C7.81692 11.2669 8.00398 11.5427 8.23921 11.7742L11.775 8.23916ZM3.33337 16.6667L16.6667 3.33333L3.33337 16.6667Z" stroke="#6A6E85" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            </v-btn>
          </div>

          <div class="balance-info">
            <div v-if="show">
              <p>{{$primNum(coin, wallets_list[0].decimals)}}</p>
              <span>{{user.course_current.course_marker}} {{$primNum(balance_usd * user.course_current.course_current)}}</span>
            </div>
            <div v-else>
              <p>*****</p>
              <span >{{user.course_current.course_marker}} *****</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="wallets_list.length && wallets_list.length > 1" class="list-networks">
        <div class="list-networks__item" v-for="(item, key) in wallets_list" :key="key">
          <span>{{item.sub}}</span>
          <p>{{$primNum(item.balance, item.decimals)}} {{item.symbol}}</p>
        </div>
      </div>

      <div class="block-buttons">
        <router-link :to="user.google_2fa ? {name: 'deposit', params: {'wallet_list': wallets_list}} : {name: 'setting', params: {'isTwoFa': true}}" class="btn-deposit">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8337 5.34175L14.6587 4.16675L5.83366 12.9917L5.83366 7.50008H4.16699L4.16699 15.8334L12.5003 15.8334V14.1667L7.00866 14.1667L15.8337 5.34175Z" fill="#01AA4F"/>
          </svg>

          {{__('web_btn_top_up')}}
        </router-link>

        <router-link :to="user.google_2fa ? {name: 'withdraw', params: {'wallet_list': wallets_list}} : {name: 'setting', params: {'isTwoFa': true}}" class="btn-withdraw">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.16634 14.6583L5.34134 15.8333L14.1663 7.00825L14.1663 12.4999L15.833 12.4999L15.833 4.16659L7.49968 4.16659L7.49968 5.83325L12.9913 5.83325L4.16634 14.6583Z" fill="#F51E38"/>
          </svg>

          {{__('web_btn_withdraw')}}
        </router-link>

        <router-link
          v-if="walletSell"
          :to="{name: 'exchange', params: {'walletSell': walletSell}}"
          class="btn-swap"
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3918 9.55813V6.7915C16.3918 5.75817 15.5501 4.9165 14.5168 4.9165H2.9834C2.64173 4.9165 2.3584 4.63317 2.3584 4.2915C2.3584 3.94984 2.64173 3.6665 2.9834 3.6665H14.5168C16.2418 3.6665 17.6418 5.0665 17.6418 6.7915V9.55813C17.6418 9.90813 17.3584 10.1831 17.0168 10.1831C16.6751 10.1831 16.3918 9.89979 16.3918 9.55813Z" fill="#0096FF"/>
            <path d="M5.17507 7.37496L2.54173 4.74164C2.42507 4.62498 2.3584 4.46662 2.3584 4.29995C2.3584 4.13329 2.42507 3.97498 2.54173 3.85831L5.17507 1.22495C5.41674 0.983285 5.81673 0.983285 6.0584 1.22495C6.30007 1.46662 6.30007 1.86667 6.0584 2.10833L3.86676 4.29995L6.0584 6.49162C6.30007 6.73329 6.30007 7.13329 6.0584 7.37496C5.9334 7.49162 5.77507 7.55832 5.61673 7.55832C5.4584 7.55832 5.30007 7.49996 5.17507 7.37496Z" fill="#0096FF"/>
            <path d="M5.4834 16.325C3.7584 16.325 2.3584 14.925 2.3584 13.2V10.4333C2.3584 10.0917 2.64173 9.80835 2.9834 9.80835C3.32507 9.80835 3.6084 10.0917 3.6084 10.4333V13.2C3.6084 14.2334 4.45007 15.075 5.4834 15.075H17.0168C17.3584 15.075 17.6418 15.3584 17.6418 15.7C17.6418 16.0417 17.3584 16.325 17.0168 16.325H5.4834Z" fill="#0096FF"/>
            <path d="M13.941 18.7751C13.6993 18.5334 13.6993 18.1334 13.941 17.8917L16.1327 15.7001L13.941 13.5084C13.6993 13.2668 13.6993 12.8668 13.941 12.6251C14.1827 12.3834 14.5827 12.3834 14.8243 12.6251L17.4577 15.2584C17.5744 15.3751 17.641 15.5334 17.641 15.7001C17.641 15.8668 17.5744 16.0251 17.4577 16.1417L14.8243 18.7751C14.7077 18.9001 14.5493 18.9584 14.3827 18.9584C14.2243 18.9584 14.066 18.9001 13.941 18.7751Z" fill="#0096FF"/>
          </svg>

          {{__('web_btn_swap')}}
        </router-link>

        <div
            v-else
            class="btn-swap-disabled"
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3918 9.55813V6.7915C16.3918 5.75817 15.5501 4.9165 14.5168 4.9165H2.9834C2.64173 4.9165 2.3584 4.63317 2.3584 4.2915C2.3584 3.94984 2.64173 3.6665 2.9834 3.6665H14.5168C16.2418 3.6665 17.6418 5.0665 17.6418 6.7915V9.55813C17.6418 9.90813 17.3584 10.1831 17.0168 10.1831C16.6751 10.1831 16.3918 9.89979 16.3918 9.55813Z" fill="#0096FF"/>
            <path d="M5.17507 7.37496L2.54173 4.74164C2.42507 4.62498 2.3584 4.46662 2.3584 4.29995C2.3584 4.13329 2.42507 3.97498 2.54173 3.85831L5.17507 1.22495C5.41674 0.983285 5.81673 0.983285 6.0584 1.22495C6.30007 1.46662 6.30007 1.86667 6.0584 2.10833L3.86676 4.29995L6.0584 6.49162C6.30007 6.73329 6.30007 7.13329 6.0584 7.37496C5.9334 7.49162 5.77507 7.55832 5.61673 7.55832C5.4584 7.55832 5.30007 7.49996 5.17507 7.37496Z" fill="#0096FF"/>
            <path d="M5.4834 16.325C3.7584 16.325 2.3584 14.925 2.3584 13.2V10.4333C2.3584 10.0917 2.64173 9.80835 2.9834 9.80835C3.32507 9.80835 3.6084 10.0917 3.6084 10.4333V13.2C3.6084 14.2334 4.45007 15.075 5.4834 15.075H17.0168C17.3584 15.075 17.6418 15.3584 17.6418 15.7C17.6418 16.0417 17.3584 16.325 17.0168 16.325H5.4834Z" fill="#0096FF"/>
            <path d="M13.941 18.7751C13.6993 18.5334 13.6993 18.1334 13.941 17.8917L16.1327 15.7001L13.941 13.5084C13.6993 13.2668 13.6993 12.8668 13.941 12.6251C14.1827 12.3834 14.5827 12.3834 14.8243 12.6251L17.4577 15.2584C17.5744 15.3751 17.641 15.5334 17.641 15.7001C17.641 15.8668 17.5744 16.0251 17.4577 16.1417L14.8243 18.7751C14.7077 18.9001 14.5493 18.9584 14.3827 18.9584C14.2243 18.9584 14.066 18.9001 13.941 18.7751Z" fill="#0096FF"/>
          </svg>

          {{__('web_btn_swap')}}
        </div>
      </div>
    </div>


    <WalletsHistory v-bind:wallets_list="wallets_list"></WalletsHistory>
<!--    <div class="mt-4 top-wallet d-flex justify-space-between">-->
<!--      <v-btn class="red" v-on:click="$emit('update:popup',  'withdraw')">Withdraw</v-btn>-->
<!--      <v-btn class="success" v-on:click="$emit('update:popup',  'deposit')">Top Up</v-btn>-->
<!--    </div>-->
  </div>
</template>

<script>
    // import DotLoader from 'vue-spinner/src/DotLoader.vue';
    import WalletsHistory from "./WalletsHistory";
    import _ from 'lodash';
    export default {
      data: function () {
        return {
          show: true,
          swipeDirection: 'None',
          submitDisabled: false,
          history: false,
          coin: 0,
          balance_usd: 0,
          dialog: true,
        }
      },
      props: {
        wallets_list: {
            type: Array
        }
      },
      created() {
        this.updateBalance();

        if (window.innerWidth < 850) {
          document.body.style.overflow = "hidden";
          document.body.style.height = "100vh";
        }
      },
      destroyed() {
        document.body.style.overflow = "";
        document.body.style.height = "";
      },
      watch:{
          wallets() {
              this.updateBalance();
          },
          courses() {
              this.updateBalance();
          }
        },
        computed: {
          wallets() {
              return this.$store.getters.wallets
          },
          courses() {
              return this.$store.getters.courses
          },
          user() {
            return this.$store.getters.user
          },
          walletSell() {
            let arr = _.filter(this.wallets_list, item => item.recv);
            let sortArr = [];
            let result = null;
            if (arr.length) {
              sortArr = _.orderBy(arr, item => item.balance * this.courses[item.symbol], ['desc']);
              result =  sortArr[0];
            }
            return result
          }
        },
        methods: {
          blockScroll() {
            document.body.style.overflow = "hidden";
            document.body.style.height = "100vh";
            },

          unblockScroll() {
            document.body.style.overflow = "";
            document.body.style.height = "";
          },

            modalCancel() {
                // this.$emit('modalCancel')
              this.$emit('update:wallets_list', null)
            },
            updateBalance() {
                let balance_usd= 0;
                let wallets= this.wallets_list;
                let coin= 0;

                for (let i = 0; i < wallets.length; i++) {
                    let wallet= wallets[i];

                    let payment= this.$payment(wallet.id);

                    let course_usd= this.courses[payment.symbol];

                    if(payment.balance > 0) {
                        coin+= payment.balance;
                        balance_usd+= payment.balance* course_usd;
                    }
                }

                this.coin = coin;
                this.balance_usd = balance_usd;
            },
        },
        components: {
            WalletsHistory,
            // ModalDefault,
            // DotLoader
        }
    }
</script>

<style>

</style>
