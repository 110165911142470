<template>
  <v-app id="app" >
      <router-view></router-view>
      <notifications group="message" class="notise" position="bottom right" />
  </v-app>
</template>

<script>
    import _ from 'lodash';
    // import SyncLoader from 'vue-spinner/src/SyncLoader.vue'
export default {
  name: 'App',
  data: function() {
      return {
      }
  },
  watch: {

  },
    methods:{
        // handleSCroll () {
        //     let header = document.querySelector(".home-menu");
        //     if (window.scrollY > 80) {
        //         console.log(window.scrollY);
        //         header.classList.add("active");
        //     }
        //     else {
        //         console.log(window.scrollY);
        //         header.classList.remove("active");
        //     }
        // }

        updateUser(transaction = false) {
            this.$http.get('ajax/wallets')
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    response= response.data;

                    this.$store.dispatch('asyncWallets', response.wallets);
                    this.$store.dispatch('asyncWalletsGroup', _.groupBy(response.wallets, 'symbol'));
                    this.$store.dispatch('asyncCourses', response.courses);

                }).catch((err) => {
                this.httpHandler(err);
            });

            this.$http.get('ajax/user_info')
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    this.$store.dispatch('asyncUser', response.data);

                    this.$store.dispatch('asyncResp', response.resp_tickets);

                    if(transaction) {
                        this.$store.dispatch('asyncTransaction', transaction);
                    }
                    this.$socket.emit('connect_socket', {
                        parent_id: this.user.parent_id
                    });

                }).catch((err) => {
                this.httpHandler(err);
            });
        }
    },
  computed: {
      user() {
          return this.$store.getters.user;
      },
  },
  created () {
      // window.addEventListener('scroll', this.handleSCroll);

      this.$http.get('ajax/languages')
          .then(response => {
              return response.json()
          })
          .then(response => {
              this.$store.dispatch('asyncLanguages', response);
          }).catch((err) => {
          this.httpHandler(err);
      });

      if (window.userAuth) {
          this.updateUser();

          this.$http.get('ajax/store')
              .then(response => {
                  return response.json()
              })
              .then(response => {
                  this.$store.dispatch('asyncStore', response.data);
              }).catch((err) => {
              this.httpHandler(err);
          });

          this.$http.get('ajax/wallet/request/chart')
              .then(response => {
                  return response.json()
              })
              .then(response => {
                  this.$store.dispatch('asyncCharts', response.data);

              }).catch((err) => {
              this.httpHandler(err);
          });

          this.sockets.subscribe('updateTransaction', (data)=> {
              this.updateUser(data);
          });

          this.sockets.subscribe('connect', ()=> {
              this.$socket.emit('connect_socket', {
                  parent_id: (this.user) ? this.user.parent_id : null
              });
          });

          this.sockets.subscribe('updateCourses', (data)=> {
              this.$store.dispatch('asyncCourses', data.courses);
          });
          this.sockets.subscribe('updateCharts', (data)=> {

              this.$store.dispatch('asyncCharts', null);
              setTimeout(()=> {
                  this.$store.dispatch('asyncCharts', data.charts)
              }, 2);
          });

      }
      // if (window.userAuth) {
      //     this.$http.get('ajax/user_info')
      //     .then(response => {
      //         return response.json()
      //     })
      //     .then(response => {
      //         let portfolio = response.data;
      //         // if(portfolio.lang) {
      //         //     this.$store.dispatch("asyncLanguage", portfolio.lang);
      //         // }
      //         this.$store.dispatch('asyncPortfolio', portfolio);
      //         this.$store.dispatch('asyncPayments', portfolio.payment);
      //     }).catch((err) => {
      //         this.httpHandler(err);
      //     });
      // }

      this.$router.beforeEach((to, from, next) => {
          this.$removeAllListeners(window,'scroll');

          return next();
      });
  },
    destroyed () {
        // window.removeEventListener('scroll', this.handleSCroll);

    },
  components: {

  },
}
</script>

<style>

</style>

