export default {
    state: {
        store: null,
    },
    getters: {
        store(state){
            return state.store;
        },
    },
    actions: {
        asyncStore(context, payload) {
            context.commit('updateStore', payload);
        }
    },
    mutations: {
        updateStore(state, data) {
            this.state.business.store = data;
        }
    }
}