<template>
    <div v-if="auth" class="page-swap-card">
      <div class="page-swap">
        <div class="top-all-p">
          <div>
            <p class="name">{{__('Buy Crypto')}}</p>
            <span class="desc">{{__('Text Buy Crypto')}}</span>
          </div>
        </div>
        <div class="block-body">
          <div class="block-body__left">
            <div class="block-coin">
              <p class="block-coin__title">{{__('Currency')}}</p>
              <DropdownDefault
                v-bind:items="dropdownWallets"
                v-model="wallet"
                dropSlot
              >
                <template v-slot:selection="item">
                  <img :src=item.img>
                  {{item.name}}
                </template>
                <template v-slot:item="item">
                  <div class="block-coin">
                    <img :src=item.img>
                    {{item.name}}
                  </div>
                </template>
              </DropdownDefault>
            </div>
          </div>
          <div class="block-body__left">
            <div class="block-coin">
              <p class="block-coin__title">{{__('Payment method')}}</p>
              <DropdownDefault
                v-bind:items="dropdownMethods"
                v-model="method"
                dropSlot
              >
                <template v-slot:selection="item">
                  {{item.name | clearStr}}
                </template>
                <template v-slot:item="item">
                  <div class="block-coin">
<!--                    <img :src=item.img>-->
                    {{item.name | clearStr}}
                  </div>
                </template>
              </DropdownDefault>
            </div>
          </div>
          <div class="block-body__left">
            <div class="block-coin">
              <p class="block-coin__title">{{__('Receipt Coin')}}</p>
              <DropdownDefault
                v-bind:items="dropdownPayments"
                v-model="payment"
                dropSlot
              >
                <template v-slot:selection="item">
                  <img :src=item.img>
                  {{item.name}}
                </template>
                <template v-slot:item="item">
                  <div class="block-coin">
                    <img :src=item.img>
                    {{item.name}}
                  </div>
                </template>
              </DropdownDefault>
            </div>
          </div>
          <div class="block-body__left">
            <p class="block-coin__title">{{__('Amount')}}</p>
            <div class="block-input">
              <InputLabelTextDefault
                      v-model="amount"
                      v-bind:errors="formValidate.amount"
                      @change="updateValidate('amount')"
              >
              </InputLabelTextDefault>
            </div>
          </div>
        </div>

      <div  class="block-btns ">
        <v-btn
                v-ripple="false"
                class="btn-site"
                v-on:click="onSubmit"
                :loading="submitDisabled"
        >
          {{__('Next step')}}
        </v-btn>
      </div>
<!--        <p class="block-coin__title">{{__('Amount btc')}}</p>-->
        <!--        <div class="sidebar" >-->
<!--            <div class="sidebar__item" :class="(page == 'personal') ? 'sidebar__item&#45;&#45;active' : ''" @click="page = 'personal'">-->
<!--                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 7C6.25 3.83 8.83 1.25 12 1.25C15.17 1.25 17.75 3.83 17.75 7C17.75 10.17 15.17 12.75 12 12.75C8.83 12.75 6.25 10.17 6.25 7ZM7.75 7C7.75 9.34 9.66 11.25 12 11.25C14.34 11.25 16.25 9.34 16.25 7C16.25 4.66 14.34 2.75 12 2.75C9.66 2.75 7.75 4.66 7.75 7Z" fill="currentColor"/>-->
<!--                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8196 22.3499C14.5096 22.0399 14.3696 21.5899 14.4396 21.1199L14.6296 19.7699C14.6796 19.4199 14.8896 19.0099 15.1396 18.7499L18.6796 15.2099C20.0996 13.7899 21.3496 14.5999 21.9596 15.2099C22.4796 15.7299 22.7496 16.2899 22.7496 16.8499C22.7496 17.4199 22.4896 17.9499 21.9596 18.4799L18.4195 22.0199C18.1695 22.2699 17.7496 22.4799 17.3996 22.5299L16.0495 22.7198C15.9695 22.7398 15.8996 22.7499 15.8196 22.7499C15.4396 22.7499 15.0796 22.6099 14.8196 22.3499ZM19.7396 16.2699L16.1996 19.8099C16.1696 19.8399 16.1196 19.9399 16.1196 19.9799L15.9396 21.2299L17.1896 21.0499C17.2296 21.0399 17.3295 20.9899 17.3595 20.9599L20.8996 17.4199C21.0596 17.2599 21.2496 17.0299 21.2496 16.8499C21.2496 16.6999 21.1296 16.4899 20.8996 16.2699C20.6596 16.0299 20.4796 15.9199 20.3096 15.9199C20.1296 15.9199 19.9696 16.0399 19.7396 16.2699Z" fill="currentColor"/>-->
<!--                    <path d="M20.7206 19.1899C19.4006 18.8199 18.3506 17.7699 17.9806 16.4499C17.8706 16.0499 18.1006 15.6399 18.5006 15.5299C18.9006 15.4199 19.3106 15.6499 19.4206 16.0499C19.6506 16.8699 20.3006 17.5199 21.1206 17.7499C21.5206 17.8599 21.7506 18.2799 21.6406 18.6699C21.5506 18.9999 21.2506 19.2198 20.9206 19.2198C20.8506 19.2198 20.7806 19.2099 20.7206 19.1899Z" fill="currentColor"/>-->
<!--                    <path d="M2.66016 22C2.66016 17.73 6.85018 14.25 12.0002 14.25C13.0902 14.25 14.1702 14.41 15.1802 14.71C15.5802 14.83 15.8002 15.25 15.6802 15.64C15.5602 16.04 15.1402 16.26 14.7502 16.14C13.8702 15.88 12.9502 15.74 12.0002 15.74C7.68018 15.74 4.16016 18.54 4.16016 21.99C4.16016 22.41 3.82016 22.75 3.41016 22.75C3.00016 22.75 2.66016 22.41 2.66016 22Z" fill="currentColor"/>-->
<!--                </svg>-->

<!--                <p>{{__('web_page_settings_tab_personal')}}</p>-->
<!--            </div>-->

<!--            <div class="sidebar__item" :class="(page == 'security') ? 'sidebar__item&#45;&#45;active' : ''" @click="page = 'security'">-->
<!--                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.98039 21.8099L4.68039 18.5999C3.54039 17.7499 2.65039 15.9799 2.65039 14.5599V7.11994C2.65039 5.57994 3.78039 3.93994 5.23039 3.39994L10.2204 1.52994C11.2104 1.15994 12.7704 1.15994 13.7604 1.52994L18.7604 3.39994C20.2104 3.93994 21.3404 5.57994 21.3404 7.11994V14.5499C21.3404 15.9699 20.4504 17.7399 19.3104 18.5899L15.0104 21.7999C14.1704 22.4399 13.0904 22.7599 12.0004 22.7599C10.9104 22.7599 9.83039 22.4399 8.98039 21.8099ZM5.76039 4.80994C4.90039 5.12994 4.15039 6.20994 4.15039 7.12994V14.5599C4.15039 15.5099 4.82039 16.8399 5.57039 17.3999L9.87039 20.6099C11.0204 21.4699 12.9704 21.4699 14.1204 20.6099L18.4204 17.3999C19.1804 16.8299 19.8404 15.4999 19.8404 14.5599V7.11994C19.8404 6.20994 19.0904 5.12994 18.2304 4.79994L13.2404 2.92994C12.5804 2.68994 11.4204 2.68994 10.7504 2.93994L5.76039 4.80994Z" fill="currentColor"/>-->
<!--                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 10.5C9.25 8.98 10.48 7.75 12 7.75C13.52 7.75 14.75 8.98 14.75 10.5C14.75 12.02 13.52 13.25 12 13.25C10.48 13.25 9.25 12.02 9.25 10.5ZM10.75 10.5C10.75 11.19 11.31 11.75 12 11.75C12.69 11.75 13.25 11.19 13.25 10.5C13.25 9.81 12.69 9.25 12 9.25C11.31 9.25 10.75 9.81 10.75 10.5Z" fill="currentColor"/>-->
<!--                    <path d="M11.25 15.5V12.5C11.25 12.09 11.59 11.75 12 11.75C12.41 11.75 12.75 12.09 12.75 12.5V15.5C12.75 15.91 12.41 16.25 12 16.25C11.59 16.25 11.25 15.91 11.25 15.5Z" fill="currentColor"/>-->
<!--                </svg>-->

<!--                <p>{{__('web_page_settings_tab_security')}}</p>-->
<!--            </div>-->
<!--        </div>-->

      </div>

      <ModalDefault v-if="modal_confirm" width="420" v-bind:modal_cancel.sync="modal_confirm">
        <div class="modal-confirm">
          <p class="modal-text">{{__('Buy Crypto')}}</p>
          <p class="text-text">{{__('We track your payment, as soon as the funds arrive on the card, your btc will be automatically credited to your account')}}</p>
          <v-btn :to="{name: 'wallets'}" class="btn-site">{{__('Check status')}}</v-btn>
        </div>
      </ModalDefault>
    </div>
    <div v-else class="block-loading">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      {{__('Loading')}}
    </div>
</template>

<script>

import ModalDefault from '../modules/ModalDefault';
import DropdownDefault from '../modules/DropdownDefault';
import InputLabelTextDefault from '../modules/InputLabelTextDefault';
import {decimal, maxValue, minValue, required} from "vuelidate/lib/validators";
export default {
  name: 'CardSwap',
    data: function() {
      return {
          modal_confirm: false,
          submitDisabled: false,
          page: 'personal',
              dropdownWallets: [
              {
                  'value': 'rub',
                  'name': 'Rub',
                  'img': '/assets/images/wallets/rub.svg'
              }
          ],
          dropdownMethods: [
              // {
              //     'value': 'rub_swap',
              //     'name': 'Rub swap',
              // },
              {
                  'value': 'tinkoff',
                  'name': 'Тинкофф',
              },
              {
                  'value': 'SBER',
                  'name': 'СБЕР',
              }
          ],
          formValidate: {
              amount: null
          },
          dropdownPayments: [
                {
                    "id": 46,
                    "favorite": 1,
                    "currency": "USDTTRC",
                    "img": "https://neivawallet.io/assets/images/wallets/usdt.png",
                    "token": "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
                    "symbol": "USDT",
                    "network": "TRC20",
                    "sub": "TRC20",
                    "name": "Tether (TRC20)",
                    "precision": 6,
                    "recv": 1,
                    "decimals": 2,
                    "commission_out": 0.5233,
                    "commission_out_swap": 0.5233,
                    "course_usd": 1,
                    "min": 1000,
                    "max": 200000,
                    "balance": 8.71
                }
          ],
          wallet: 'rub',
          method: 'tinkoff',
          payment: {
              "id": 46,
              "favorite": 1,
              "currency": "USDTTRC",
              "img": "https://neivawallet.io/assets/images/wallets/usdt.png",
              "token": "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
              "symbol": "USDT",
              "network": "TRC20",
              "sub": "TRC20",
              "name": "Tether (TRC20)",
              "precision": 6,
              "recv": 1,
              "decimals": 2,
              "commission_out": 0.5233,
              "commission_out_swap": 0.5233,
              "course_usd": 1,
              "min": 1000,
              "max": 200000,
              "balance": 8.71
            },
          twoFaInfo: null,
          amount: null,
          commission_out: {
              'tinkoff': 0
          },
          exchange: null,
          waitingTime: '-',
          intTimer: null
      }
  },
  validations() {
      return {
          wallet: {
              required,
          },
          payment: {
              required,
          },
          method: {
              required,
          },
          amount: {
              required,
              decimal,
              maxValue: maxValue(this.payment.max),
              minValue: minValue(10000),
              // minValue: minValue(this.payment.min+this.commission_out[this.method]),
          }
      }
  },
  computed: {
      auth() {
          return this.$store.getters.user
      },
  },
  created() {
      this.$http[(process.env.NODE_ENV === 'production') ? 'get' : 'get']('ajax/cardswap')
          .then(response => {
              return response.json()
          })
          .then((response) => {
              response= response.data;

              this.exchange= response.cards;
              this.commission_out= response.commission_out;


              if(this.exchange) {
                  this.$router.push({name: 'ticket', params: {
                          id: this.exchange.api_id
                      }});
              }
          }).catch((err) => {

          this.httpHandler(err);
      });
  },
  watch: {
      method() {
          this.payment= this.dropdownPayments[0];
      }
  },
  methods: {
      updateValidate: function (type) {
          this.formValidate= this.$changeValidate(this.formValidate, this.$v[type], type);
      },
      copyMessage() {
          this.open= !this.open;
          this.$copyMessage(this.exchange.card_number);
          this.$refs.eye.classList.toggle('active');
          this.$refs.copy.classList.add('active');
          setTimeout(()=> {
              this.$refs.copy.classList.remove('active');
          }, 2000);
      },
      onSubmit() {

          let valid = this.$v;

          if (valid.$invalid) {
              let massages = {
                  wallet: [],
                  payment: [],
                  method: [],
                  amount: [],
              };

              this.formValidate = this.$formValidate(massages, valid);
          } else {
              this.submitDisabled = true;
              let request = {
                  wallet: this.wallet,
                  payment_id: this.payment.id,
                  method: this.method,
                  amount: this.amount,
              };

              // if(send && ['BEP20'].includes(this.wallet.sub)) {
              //     request['gasPrice']= send.gasPrice;
              // }

              this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/cardswap/create', request)
                  .then(response => {
                      return response.json()
                  })
                  .then((response) => {
                      this.$store.dispatch('asyncResp', response.resp_tickets);
                      response = response.data;

                      this.$router.push({name: 'ticket', params: {
                          id: response.id
                      }});
                  }).catch((err) => {

                  this.submitDisabled= false;
                  let response = this.httpHandler(err);

                  if(response.error) {
                      this.formValidate = response.error.message;
                  }
              });
          }
      }
  },
  components: {
    ModalDefault,
      DropdownDefault,
      InputLabelTextDefault,
      // DotLoader,
  }
}
</script>


<style scoped>

</style>
