<template>
    <div :class="(!auth) ? 'dark' : ''">
        <div class="home-menu">
            <div class="content">
                <div class="left">
                    <div class="logo">
                        <router-link :to="{name: 'home'}"><img src="/dist/images/logo-f.svg" alt=""></router-link>
                    </div>
                    <div class="logo-mini">
<!--                        <router-link :to="{name: 'home'}"><img src="/dist/images/logo-mini.svg" alt=""></router-link>-->
                    </div>
                </div>

<!--                <div class="menu">-->
<!--                    <a href="#" v-scroll-to="'#product'">{{__('Product')}}</a>-->
<!--                    <a href="#" v-scroll-to="'#features'">{{__('Features')}}</a>-->
<!--                    <a href="#" v-scroll-to="'#functionality'">{{__('Functionality')}}</a>-->
<!--                    <a href="#" v-scroll-to="'#cards'">{{__('Cards')}}</a>-->
<!--                </div>-->

                <div class="right">

                    <div class="right-btn" v-if="portfolio">
                        <router-link :to="{name: 'dashboard'}" class="btn-white">{{portfolio.name}}</router-link>
                    </div>
                    <div class="right-btn" v-else>
                        <router-link :to="{name: 'login'}" class="btn-white">{{__('web_login_Log in')}}</router-link>
                        <router-link :to="{name: 'register'}" class="btn-site">{{__('web_sign up')}}</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="terms">
            <div class="block-terms">
                <h1>{{terms.title}}</h1>
                <div v-html="terms.text"></div>
            </div>
<!--            <div class="block-footer">-->
<!--                <Footer></Footer>-->
<!--            </div>-->
        </div>

<!--        <div class="block-terms" v-else>loading...</div>-->
        <div v-else class="block-terms-loading">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            <p>{{__('Loading')}}</p>
        </div>
    </div>

</template>

<script>
    // import Footer from "../modules/Footer";
    // import DotLoader from 'vue-spinner/src/DotLoader.vue'
    export default {
        name: 'Terms',
        data: function () {
            return {
                terms: null,
            }
        },

        computed: {
            languages() {
                return this.$store.state.language.lang;
            },
            auth() {
                return this.$store.state.auth.user
            }
        },

        beforeRouteUpdate (to, from, next) {
            // обрабатываем изменение параметров маршрута...
            // не забываем вызвать next()
            this.createdComponent(to.name);
            return next();
        },

        created() {
            this.createdComponent(this.$route.name);
        },

        methods: {
            createdComponent(page) {
                this.$http.get('ajax/pages/' + page + '/' + this.languages)
                    .then(response => {
                        return response.json()
                    })
                    .then(response => {
                        if(response) {
                            this.terms = response.data;
                        }
                    }).catch(err => {
                    this.httpHandler(err);
                });
            }
        },
        components: {
            // Footer
            // DotLoader
        }
    }
</script>

<style lang="scss">

    .block-terms-loading {
        margin: 0 auto;
        max-width: 1170px;
        padding-top: 100px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        min-height: calc(100vh - 365px);

        p {
            margin-top: 10px;
        }
    }

    .block-terms {
        color: #242132;
        max-width: 1170px;
        margin: 0 auto 40px;
        padding: 40px 20px 0;
        height: 100%;
        /*min-height: calc(100vh - 194px);*/

        h1 {
            margin-bottom: 20px;
            text-align: center;
            font-size: 36px;
            line-height: 50px;
            color: #242132;

        }
        h2,
        h3,
        h4,
        h5 {
            margin-bottom: 20px;
            font-size: 20px;
            line-height: 18px;
            color: #242132;
        }

        ol,
        ul {

            li {
                display: list-item;
                vertical-align: top!important;
                white-space: normal!important;
                margin-bottom: 10px;

                p {
                    margin: 0!important;
                    display: flex;
                    align-items: flex-start;
                }
            }
        }
    }

</style>