<template>
    <div class="wrap-verification">
        <div class="wrap-verification__head">
            <v-btn v-ripple="false" text icon class="icon" @click="modalCancel">
                <img src="/dist/images/close.svg" alt="/" />
            </v-btn>
            <h2 class="verification-title">{{__('web_ver_Verify Identity')}}</h2>
            <p class="desc">Transfiguration lion prince minister werewolf banquet nearly-headless hippogriff. You-know-who nagini.</p>
            <div class="grid-wrapper">
                <div class="block-step">
                    <div class="block-step__head">
                        <div class="block-num block-num--completed">1</div>
<!--                        <div class="block-line block-line&#45;&#45;completed"></div>-->
                    </div>

                    <div class="block-step__body">
<!--                        <span class="block-headline">{{__('web_ver_Step 1')}}</span>-->
                        <p class="block-title">{{__('web_ver_Personal info')}}</p>
<!--                        <span class="block-status block-status&#45;&#45;completed">{{__('web_ver_Completed')}}</span>-->
                    </div>
                </div>

                <div class="block-step">
                    <div class="block-step__head">
                        <div class="block-num block-num--completed">2</div>
<!--                        <div class="block-line block-line&#45;&#45;completed"></div>-->
                    </div>

                    <div class="block-step__body">
<!--                        <span class="block-headline">{{__('web_ver_Step 2')}}</span>-->
                        <p class="block-title">{{__('web_ver_Document')}}</p>
<!--                        <span class="block-status block-status&#45;&#45;completed">{{__('web_ver_Completed')}}</span>-->
                    </div>
                </div>

                <div class="block-step block-step-inProgress">
                    <div class="block-step__head">
                        <div class="block-num block-num--inProgress">3</div>
<!--                        <div class="block-line block-line&#45;&#45;inProgress"></div>-->
                    </div>

                    <div class="block-step__body">
<!--                        <span class="block-headline">{{__('web_ver_Step 3')}}</span>-->
                        <p class="block-title">{{__('web_ver_Photo upload')}}</p>
<!--                        <span class="block-status block-status&#45;&#45;inProgress">{{__('web_ver_In Progress')}}</span>-->
                    </div>
                </div>
            </div>
        </div>

        <div class="wrap-verification__body">
            <div class="block-upload">
                <div class="wrapper-item">
                    <div class="block-upload__item" :class="isErrorUpload ? 'block-upload__item--error' : ''">
                        <DragZone target="/ajax/setting/verification/upload" v-bind:file.sync="form" v-bind:isErrorUpload.sync="isErrorUpload">
                            <div class="block-upload__body" :style="((form.img) ? {background: 'url('+form.img+')'} : null)"></div>

                            <div class="block-control" :style="((form.img) ? {backgroundColor: 'rgba(0, 0, 0, 0.65)'} : 'transparent')">
                                <div class="block-img-upload">

                                    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="96" height="96" rx="48" fill="#0B1326" fill-opacity="0.06"/>
                                        <path d="M69 50.3332V61.9999C69 66.6666 66.6667 68.9999 62 68.9999H34C29.3333 68.9999 27 66.6666 27 61.9999V50.3332C27 45.6666 29.3333 43.3332 34 43.3332H46.25V57.3332C46.25 58.2992 47.034 59.0832 48 59.0832C48.966 59.0832 49.75 58.2992 49.75 57.3332V43.3332H62C66.6667 43.3332 69 45.6666 69 50.3332ZM49.75 31.2255L53.7633 35.2389C54.1039 35.5796 54.552 35.7522 55 35.7522C55.448 35.7522 55.8961 35.5819 56.2367 35.2389C56.9204 34.5553 56.9204 33.4468 56.2367 32.7632L49.2367 25.7632C49.0757 25.6022 48.8817 25.4739 48.6671 25.3852C48.2401 25.2079 47.7571 25.2079 47.3301 25.3852C47.1154 25.4739 46.922 25.6022 46.761 25.7632L39.761 32.7632C39.0773 33.4468 39.0773 34.5553 39.761 35.2389C40.4447 35.9226 41.5531 35.9226 42.2367 35.2389L46.25 31.2255V43.3332H49.75V31.2255Z" fill="#667085"/>
                                    </svg>

                                </div>
                                <div class="block-upload-desc">
                                    <p :style="((form.img) ? {color: '#000000'} : '#242132')">{{__('web_ver_front_Unload a photo of the title page of your document')}}</p>
                                </div>

                                <v-btn
                                    v-ripple="false"
                                    class="btn-site"
                                    :loading="submitDisabled"
                                >
                                    <span>
                                      {{__('web_ver_front_Unload the photo')}}
                                    </span>
                                </v-btn>
                            </div>
                        </DragZone>
<!--                        <div ref="document_img">-->
<!--                            <p v-if="formValidate.front_side_id" class="desc-error">Error upload photo (minimum resolution: 480px; maximum resolution: 7000px; maximum file size: 50 Mb)</p>-->
<!--                        </div>-->
                    </div>
                    <p v-if="isErrorUpload" class="text-error">Error upload photo (minimum resolution: 480px, maximum resolution: 7000px, maximum file size: 50 Mb)</p>
                </div>
            </div>

        </div>

        <div class="wrap-verification__bottom">
            <v-btn
                class="btn-white"
                v-on:click="$emit('update:step', 2)"
            >
                <span>
                  {{__('web_ver_Back')}}
                </span>
            </v-btn>

            <v-btn
                class="btn-site"
                :loading="submitDisabled"
                v-on:click="onSubmit()"
            >
                <span v-if="formVerificationDocument.type == 'PASSPORT' || formVerificationDocument.type == 'DOMESTIC_PASSPORT'">{{__('web_ver_Send to verification')}}</span>
                <span v-else>{{__('web_ver_Continue')}}</span>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import DragZone from '../../modules/DragZone';

    export default {
        name: 'BlockVerificationFrontDocument',
        data: function () {
            return {
                submitDisabled: false,

                form: {
                    file_id: this.formVerificationDocument.front_side_id,
                    img: (this.formVerificationDocument.front_side) ? this.formVerificationDocument.front_side : null,
                },
                formValidate: {
                    fileUpload: null,
                },

                isErrorUpload: false,

            }
        },

        validations: {
            form: {
                file_id: {
                    required,
                },
            }
        },


        props: {
            step: {
                type: Number
            },
            formVerificationDocument: {
                type: Object
            }
        },

        watch: {

        },

        computed: {
            user() {
                return this.$store.getters.user
            },
        },

        created() {

        },
        methods: {
            updateValidate (type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v.form[type], type);
            },

            modalCancel() {
                this.$emit('modalCancel')
            },

            onSubmit() {
                let valid = this.$v.form;



                if (valid.$invalid) {
                    let massages = {
                        file_id: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);

                    const keys = Object.keys(this.formValidate);
                    let itemError = null;
                    keys.forEach((key) => {
                        if (this.formValidate[key].length) {
                            itemError = key;
                        }
                    });
                    console.log('itemError', itemError);

                } else if (this.formVerificationDocument.type == 'PASSPORT' || this.formVerificationDocument.type == 'DOMESTIC_PASSPORT') {
                    this.submitDisabled = true;
                    let form_document = this.$cleanObject(this.formVerificationDocument);

                    form_document.front_side = this.form.img;
                    form_document.front_side_id = this.form.file_id;

                    form_document.back_side = null;
                    form_document.back_side_id = null;

                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/setting/documents", form_document)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        if(response) {
                            this.submitDisabled = false;
                            let user_info = this.$cleanObject(this.user);
                            user_info.applicant_id = response.data.applicant_id;
                            user_info.verified = 1;
                            user_info.application = response.data.application;
                            this.$store.commit('updateUser', user_info);

                            this.$emit('modalCancel');
                        }
                    })
                    .catch((err) => {
                        this.submitDisabled = false;
                        let response = this.httpHandler(err);
                        if (response.error) {
                            this.formValidate = response.error.message;
                        }
                    });
                } else {
                    this.$emit('update:step', 4);
                    let form_document = this.$cleanObject(this.formVerificationDocument);
                    form_document.front_side = this.form.img;
                    form_document.front_side_id = this.form.file_id;
                    this.$emit('update:formVerificationDocument', form_document);
                }
            }
        },
        components: {
            DragZone
        }
    }
</script>

<style>

</style>
