<template>
    <div v-if="isProduction" class="page-business">
        <div v-if="history" class="block-chart">
            <apexchart height="270" :options="options" :series="series"></apexchart>
        </div>

        <div v-if="history" class="block-history">
            <div class="block-head">
                <div class="block-head__left">
                    <p class="head-title">{{__('web_page_home_business_block_operations_title')}}</p>
                </div>

                <div class="block-head__right">

                </div>
            </div>

            <div class="block-aside">
                <p class="block-aside__date">{{__('web_page_transaction_history_Time')}}</p>
                <p class="block-aside__sum">{{__('web_page_transaction_history_Sum')}}</p>
                <p class="block-aside__transfer">{{__('web_page_transaction_history_Transfer')}}</p>
                <p class="block-aside__asset">{{__('web_page_transaction_history_Asset')}}</p>
                <p class="block-aside__txid">{{__('web_page_transaction_history_txid')}}</p>
                <p class="block-aside__status">{{__('web_page_transaction_history_Status')}}</p>
            </div>


            <div v-if="history.data.length" class="list-wrapper">
                <ItemHistory v-for="(item, key) in history.data" :key="key" v-bind:wallet="$payment(item.wallet_id)" v-bind:history="history.data" v-bind:index="key" v-bind:item="item" v-bind:table="table" :modal_info.sync="modal_info"></ItemHistory>

                <ModalInfoTransaction v-if="modal_info" v-bind:wallet="$payment(modal_info.wallet_id)" v-bind:history="history.data" v-bind:table="table" v-bind:modal_info.sync="modal_info"/>
            </div>

            <div class="block-no-result" v-else>
                <svg width="100" height="100" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M80 146.667H113.333C133.333 146.667 146.667 133.333 146.667 113.333V79.9998C146.667 61.9998 135.333 48.6665 118.667 46.6665C117.333 46.6665 115.333 46.6665 113.333 46.6665H46.6667C44.6667 46.6665 43.3334 46.6663 41.3334 47.333C24 49.333 13.3334 61.9998 13.3334 79.9998C13.3334 81.9998 13.3334 84.6665 13.3334 86.6665" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path opacity="0.4" d="M113.328 46.6681H46.6615C44.6615 46.6681 43.3281 46.668 41.3281 47.3346C41.9948 45.3346 43.3282 44.0014 45.3282 42.0014L66.6615 20.0015C75.9948 10.6681 90.6615 10.6681 99.9948 20.0015L111.995 32.0014C115.995 36.0014 117.995 41.3348 118.661 46.6681C117.328 46.6681 115.328 46.6681 113.328 46.6681Z" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path opacity="0.4" d="M146.667 83.3335H126.667C119.333 83.3335 113.333 89.3335 113.333 96.6668C113.333 104 119.333 110 126.667 110H146.667" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M59.9974 121.333C59.9974 109.533 50.4641 100 38.6641 100C26.8641 100 17.3307 109.533 17.3307 121.333C17.3307 133.133 26.8641 142.667 38.6641 142.667C50.4641 142.667 59.9974 133.133 59.9974 121.333Z" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.3334 146.667L20 140" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <p class="block-no-result__text">{{__('web_wallets_No result')}}</p>
            </div>
        </div>

        <div v-else class="block-loading">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            <p class="block-loading__text">{{__('Loading')}}</p>
        </div>
    </div>

    <div v-else  class="business-soon">
        <img src="/dist/images/img-coming_soon.svg" alt="">
        <p class="business-soon__title">{{__('web_page_home_business_block_soon_title')}}</p>

        <p class="business-soon__subtitle">{{__('web_page_home_business_block_soon_subtitle')}}</p>

        <v-btn
            class="btn-site"
            v-ripple="false"
            :to="{name: 'home'}"
        >
            {{__('web_btn_go_to_Neiva Wallet')}}
        </v-btn>
    </div>


</template>

<script>
    // import DotLoader from 'vue-spinner/src/DotLoader.vue';
    import ItemHistory from "./TransactionHistory/ItemHistory";
    import ModalInfoTransaction from "./TransactionHistory/ModalInfoTransaction";
    import VueApexCharts from 'vue-apexcharts';
    export default {
        name: 'HomeBusiness',

        data: function() {
            return {
                isProduction: false,
                history: null,
                table: null,
                modal_info: null,

                series: [{
                    name: 'Acceptance',
                    data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
                }, {
                    name: 'Withdrawal',
                    data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
                }],

                options: {
                    colors: ['#01AA4F', '#F51E38'],

                    legend: {
                        show: true,
                        showForSingleSeries: false,
                        showForNullSeries: true,
                        showForZeroSeries: true,
                        position: 'top',
                        horizontalAlign: 'center',
                        floating: false,
                        fontSize: '14px',
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        formatter: undefined,
                        inverseOrder: false,
                        width: undefined,
                        height: undefined,
                        tooltipHoverFormatter: undefined,
                        customLegendItems: [],
                        offsetX: 0,
                        offsetY: 0,
                        labels: {
                            colors: undefined,
                            useSeriesColors: false,
                        },
                        markers: {
                            width: 14,
                            height: 14,
                            strokeWidth: 0,
                            strokeColor: '#fff',
                            fillColors: undefined,
                            radius: 14,
                            customHTML: undefined,
                            onClick: undefined,
                            offsetX: 0,
                            offsetY: 0,

                        },
                        itemMargin: {
                            horizontal: 10,
                            vertical: 0
                        },
                        onItemClick: {
                            toggleDataSeries: true
                        },
                        onItemHover: {
                            highlightDataSeries: true
                        },
                    },

                    chart: {
                        type: 'bar',
                        toolbar: {
                            show: false
                        },

                        zoom: {
                            enabled: false,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '50%',
                            endingShape: 'rounded',
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: ['2022-12-19', '2022-12-20', '2022-12-21', '2022-12-22', '2022-12-23', '2022-12-24', '2022-12-25', '2022-12-26', '2022-12-27'],
                        type: 'datetime',
                    },
                    yaxis: {
                        title: {
                            // text: 'USDT'
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return "$ " + val
                            }
                        }
                    },
                    grid: {
                        borderColor: "#E0E1E6",
                        yaxis: {
                            lines: {
                                show: true
                            }
                        },
                        xaxis: {
                            lines: {
                                show: false
                            }
                        },
                    },
                }
            }
        },

        watch:{

        },
        computed: {
            wallets() {
                return this.$store.getters.wallets
            }
        },

        created() {
            this.$http.get('ajax/store/history')
                .then(response=> {
                    return response.json()
                })
                .then((response)=> {
                    response = response.data;

                    this.history = response.history;
                    this.table = response.table;
                }).catch((err) => {
                this.httpHandler(err);
            });
        },

        methods: {

        },
        components: {
            ModalInfoTransaction,
            ItemHistory,
            // DotLoader,
            apexchart: VueApexCharts,
        }
    }
</script>

<style scoped>

</style>
