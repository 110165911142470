<template>
    <div class="page-notFound">
        <div class="content">
<!--            <router-link class="content__img" :to="{name: 'home', params: {}}"><img src="/dist/images/404-img.png" alt=""></router-link>-->
            <span class="content__title">{{__('Page not found')}}</span>
            <p class="content__subtitle">{{__('Sorry. We could not find that page. You can try later or')}}</p>
            <v-btn class="btn-site" :to="{name: 'home'}">{{__('Go to Homepage')}}</v-btn>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'NotFound',
        data () {
            return {

            }
        },
        computed: {

        },
        asyncComputed: {

        },
        methods: {
        },
        components: {

        },

    }
</script>
