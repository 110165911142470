<template>
  <div class="chart-block">
      <div class="left bb">
          <div class="block">
             <p class="name">{{__('Total Invested')}}</p>
             <p class="desc">{{user.course_current.course_marker}}<span> {{$primNum(balance * user.course_current.course_current)}}</span></p>
          </div>
          <div class="chart">
              <Charts></Charts>
          </div>
      </div>
      <div class="right bb">
          <div class="block">
            <p class="name">{{__('Card balance')}}</p>
            <p class="desc">{{user.course_current.course_marker}}<span>0,00</span></p>
          </div>
          <div class="chart">
            <Charts></Charts>
          </div>
      </div>
  </div>

</template>

<script>

    import Charts from "./Charts";
    export default {
        name: 'Chart',

        data: function() {
            return {

            }
        },
        props: {

        },
        computed: {

            wallets() {
                return this.$store.getters.wallets
            },
            courses() {
                return this.$store.getters.courses
            },
            balance() {
                let balance = 0;
                let wallets = this.wallets;

                for (let i = 0; i < wallets.length; i++) {
                    let wallet = wallets[i];

                    if(wallet.balance > 0) {

                        let course_usd = this.courses[wallet.symbol];

                        balance += wallet.balance * course_usd;
                    }
                }

                return balance;
            },
            user() {
                return this.$store.getters.user
            },
        },
        methods: {

        },
        components: {
            Charts
        }
    }
</script>

<style>

</style>