<template>
  <div>
    <div class="content-home">
      <div class="page-wrapper">
<!--        <HomeMenu></HomeMenu>-->
<!--        <NavMobileHome></NavMobileHome>-->
        <v-main>
          <router-view/>
        </v-main>
        <HomeFooter></HomeFooter>
      </div>
    </div>
  </div>
</template>

<script>
    // import ModalDefault from "../ModalDefault";
    // import HomeMenu from "./HomeMenu";
    import HomeFooter from "./HomeFooter";
    // import NavMobileHome from "./modules/NavMobileHome";
    export default {
        data: function() {
            return {

            }
        },
        name: 'Home',
        methods:{

        },
        created(){
        },
        destroyed () {

        },
        computed: {
            auth() {
                return this.$store.getters.user
            },
        },
        components: {
          // ModalDefault,
          // NavMobileHome,
          HomeFooter,
          // HomeMenu
        }
    }


</script>

<style scoped>

</style>