<template>
  <li>
    <div class="left">
      <img class="img" :src="wallets[0].img" alt="">
      <div>
        <p class="name">{{wallets[0].symbol}}</p>
        <p class="desc">{{wallets[0].name | clearStr}}</p>
      </div>
    </div>
    <div class="right">
      <p class="summ">{{sumBalance['coin']}} {{wallets[0].symbol}}</p>
      <p class="desc">{{$primNum(sumBalance['usd'] * user.course_current.course_current)}} {{user.course_current.course_ticker}}</p>
    </div>
  </li>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'DashboardWalletItem',

        data: function() {
            return {

            }
        },
        props: {
            wallets: {
                type: Array
            },
            currency: {
                type: String
            },
        },
        created() {
        },
        watch:{

        },
        computed: {
            sumBalance() {
                let wallets = this.$cleanObject(this.wallets);
                let course_usd = this.courses[wallets[0].symbol];
                let coin = _.sumBy(wallets, 'balance');

                return {
                    coin: this.$primNum(coin, wallets[0].decimals),
                    usd: this.$primNum(coin*course_usd)
                }
            },
            courses() {
                return this.$store.getters.courses
            },
            charts() {
                return this.$store.getters.charts
            },
            user() {
                return this.$store.getters.user
            },
        },
        methods: {
            updateExchange(response) {
                this.$emit('update:exchange', response);
            },
            percentProfit(currency) {
                let chart_data = this.charts;
                if (chart_data) {
                    return ((chart_data.day.first[currency] - chart_data.day.last[currency])/(chart_data.day.first[currency] / 100)).toFixed(2);
                }
            },
        },
        components: {

        },
    }
</script>

<style>

</style>
