<template>
    <div class="modal-transaction" @mouseenter="blockScroll()" @mouseleave="unblockScroll()">
        <div class="modal-transaction__head">
            <v-btn
                    text
                    v-ripple="false"
                    class="btn-close"
                    v-on:click="modalCancel"
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.9397 12.0002L17.7997 7.14017C17.9089 7.01264 17.9659 6.84859 17.9595 6.68081C17.953 6.51302 17.8834 6.35386 17.7647 6.23513C17.646 6.1164 17.4868 6.04685 17.319 6.04037C17.1512 6.03389 16.9872 6.09096 16.8597 6.20017L11.9997 11.0602L7.13966 6.19351C7.01212 6.08429 6.84807 6.02722 6.68029 6.0337C6.5125 6.04018 6.35334 6.10974 6.23461 6.22847C6.11589 6.3472 6.04633 6.50635 6.03985 6.67414C6.03337 6.84192 6.09044 7.00597 6.19966 7.13351L11.0597 12.0002L6.19299 16.8602C6.1232 16.9199 6.06652 16.9935 6.02651 17.0762C5.98649 17.1589 5.964 17.249 5.96046 17.3408C5.95691 17.4326 5.97238 17.5242 6.0059 17.6097C6.03941 17.6953 6.09025 17.773 6.15522 17.8379C6.22019 17.9029 6.29789 17.9538 6.38344 17.9873C6.46899 18.0208 6.56055 18.0363 6.65236 18.0327C6.74417 18.0292 6.83426 18.0067 6.91697 17.9667C6.99968 17.9266 7.07323 17.87 7.13299 17.8002L11.9997 12.9402L16.8597 17.8002C16.9872 17.9094 17.1512 17.9665 17.319 17.96C17.4868 17.9535 17.646 17.8839 17.7647 17.7652C17.8834 17.6465 17.953 17.4873 17.9595 17.3195C17.9659 17.1518 17.9089 16.9877 17.7997 16.8602L12.9397 12.0002Z" fill="#6A6E85"/>
                </svg>
            </v-btn>

            <div class="block-info">
                <p class="block-info__title">{{__('web_page_transaction_info_title')}}</p>
                <p class="block-info__id">#{{modal_info.id}}</p>

                <div class="block-info__sum">
                    <p :style="{color: (modal_info.opp_type == 1) ? '#01AA4F' : '#F51E38'}">{{(modal_info.opp_type == 1) ? '+' : '-'}}{{$primNum(modal_info.amount_from, wallet.decimals)}} {{wallet.symbol}}</p>
                    <span>{{$primNum(modal_info.amount_from * modal_info.course_usd * user.course_current.course_current, 2)}} {{user.course_current.course_ticker}}</span>
                </div>

                <div v-if="table" class="block-info__status">
                    <span  v-if="modal_info.status == 0" class="expectation-status">{{__('web_history_Expectation')}}</span>
                    <span  v-else-if="([2,3,4,7].includes(modal_info.status))" class="completed-status">{{__('web_history_Completed')}}</span>
                    <span class="error-status" v-else-if="modal_info.status == 5">{{__('web_history_Overdue')}}</span>
                    <span class="partially-status" v-else-if="([1,6].includes(modal_info.status))">{{__('web_history_Partially')}}</span>
                    <span class="error-status" v-else-if="modal_info.status == 8">{{__('web_history_Canceled')}}</span>
                    <span  v-else class="error-status">{{__('web_history_Error')}}</span>
                </div>
                <div v-else class="block-info__status">
                    <span class="expectation-status" v-if="modal_info.status == 0">{{__('web_history_Expectation')}}</span>
                    <span class="completed-status" v-else-if="([1].includes(modal_info.status))">{{__('web_history_Completed')}}</span>
                    <span class="error-status" v-else>{{__('web_history_Error')}}</span>
                </div>
            </div>
        </div>

        <div class="modal-transaction__body">

            <div class="item-info-transaction">
                <p class="item-info-transaction__title">{{__('web_page_transaction_info_Date')}}</p>
                <p class="item-info-transaction__subtitle">{{modal_info.created_at | moment('DD.MM.YYYY, HH:mm:ss')}}</p>
            </div>

            <div v-if="modal_info.opp_type == 2" class="item-info-transaction">
                <p class="item-info-transaction__title">{{__('web_page_transaction_info_Address')}}</p>
                <p class="item-info-transaction__subtitle">{{modal_info.address}}</p>
            </div>

            <div class="item-info-transaction">
                <p class="item-info-transaction__title">{{__('web_page_transaction_info_Network')}}</p>
                <p class="item-info-transaction__subtitle">{{wallet.sub}}</p>
            </div>

            <div class="item-info-transaction">
                <p class="item-info-transaction__title">{{__('web_page_transaction_info_txid')}}</p>
                <textarea type="text" readonly ref="txid" :value="modal_info.txid"/>

                <div class="item-info-transaction__btns">
                    <a class="btn-link" target="_blank" :href="$txLink[wallet.sub]+modal_info.txid">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.3034 11.9465L13.125 10.7665L14.3034 9.5882C14.6931 9.20191 15.0026 8.74245 15.2143 8.2362C15.4259 7.72995 15.5355 7.18688 15.5367 6.63817C15.5379 6.08946 15.4307 5.54592 15.2213 5.03875C15.0118 4.53158 14.7043 4.07077 14.3163 3.68277C13.9283 3.29477 13.4675 2.98723 12.9603 2.77781C12.4531 2.56838 11.9096 2.46119 11.3609 2.46239C10.8122 2.46359 10.2691 2.57316 9.76287 2.78481C9.25662 2.99645 8.79716 3.30601 8.41086 3.6957L7.23253 4.87487L6.05336 3.69653L7.23336 2.5182C8.32738 1.42418 9.81119 0.80957 11.3584 0.80957C12.9055 0.80957 14.3893 1.42418 15.4834 2.5182C16.5774 3.61222 17.192 5.09603 17.192 6.6432C17.192 8.19038 16.5774 9.67418 15.4834 10.7682L14.3042 11.9465H14.3034ZM11.9467 14.3032L10.7675 15.4815C9.67351 16.5756 8.18971 17.1902 6.64253 17.1902C5.09536 17.1902 3.61155 16.5756 2.51753 15.4815C1.42351 14.3875 0.808899 12.9037 0.808899 11.3565C0.808899 9.80936 1.42351 8.32555 2.51753 7.23153L3.6967 6.0532L4.87503 7.2332L3.6967 8.41153C3.307 8.79783 2.99745 9.25729 2.7858 9.76354C2.57416 10.2698 2.46459 10.8129 2.46339 11.3616C2.46219 11.9103 2.56937 12.4538 2.7788 12.961C2.98823 13.4682 3.29577 13.929 3.68377 14.317C4.07176 14.705 4.53257 15.0125 5.03974 15.2219C5.54692 15.4314 6.09046 15.5385 6.63917 15.5373C7.18788 15.5361 7.73094 15.4266 8.23719 15.2149C8.74344 15.0033 9.2029 14.6937 9.5892 14.304L10.7675 13.1257L11.9467 14.304V14.3032ZM11.3567 5.46403L12.5359 6.6432L6.64336 12.5349L5.4642 11.3565L11.3567 5.46487V5.46403Z" fill="currentColor"/>
                        </svg>
                    </a>


                    <v-btn
                        v-ripple="false"
                        class="btn-copy"
                        text
                        v-on:click="copyLink"
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.5 14.9998C7.04167 14.9998 6.64944 14.8368 6.32333 14.5107C5.99667 14.184 5.83333 13.7915 5.83333 13.3332V3.33317C5.83333 2.87484 5.99667 2.48234 6.32333 2.15567C6.64944 1.82956 7.04167 1.6665 7.5 1.6665H15C15.4583 1.6665 15.8508 1.82956 16.1775 2.15567C16.5036 2.48234 16.6667 2.87484 16.6667 3.33317V13.3332C16.6667 13.7915 16.5036 14.184 16.1775 14.5107C15.8508 14.8368 15.4583 14.9998 15 14.9998H7.5ZM7.5 13.3332H15V3.33317H7.5V13.3332ZM2.5 12.4998H4.16667V10.8332H2.5V12.4998ZM2.5 9.58317H4.16667V7.9165H2.5V9.58317ZM8.33333 18.3332H10V16.6665H8.33333V18.3332ZM2.5 15.4165H4.16667V13.7498H2.5V15.4165ZM4.16667 18.3332V16.6665H2.5C2.5 17.1248 2.66306 17.5173 2.98917 17.844C3.31583 18.1701 3.70833 18.3332 4.16667 18.3332ZM5.41667 18.3332H7.08333V16.6665H5.41667V18.3332ZM11.25 18.3332C11.7083 18.3332 12.1008 18.1701 12.4275 17.844C12.7536 17.5173 12.9167 17.1248 12.9167 16.6665H11.25V18.3332ZM2.5 6.6665H4.16667V4.99984C3.70833 4.99984 3.31583 5.16317 2.98917 5.48984C2.66306 5.81595 2.5 6.20817 2.5 6.6665Z" fill="currentColor"/>
                        </svg>
                    </v-btn>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    // import moment from 'moment';

    export default {
        name: 'ModalInfoTransaction',
        data: function() {
            return {
                submitSuccess: false,
            }
        },
        computed: {
            courses() {
                return this.$store.getters.courses
            },
            user() {
                return this.$store.getters.user
            },
        },
        props: {
            history: {
                type: Array
            },
            modal_info: {
                type: Object
            },
            wallet: {
                type: Object
            },
            table: {
                type: Object
            }
        },
        created() {
            if (window.innerWidth < 850) {
                document.body.style.overflow = "hidden";
                document.body.style.height = "100vh";
            }
        },
        destroyed() {
            document.body.style.overflow = "";
            document.body.style.height = "";
        },
        methods: {
            blockScroll() {
                document.body.style.overflow = "hidden";
                document.body.style.height = "100vh";
            },

            unblockScroll() {
                document.body.style.overflow = "";
                document.body.style.height = "";
            },

            modalCancel() {
                this.$emit('update:modal_info', null)
            },

            copyLink() {
                let copyText = this.$refs.txid;
                copyText.select();
                document.execCommand("copy");

                this.submitSuccess = true;

                setTimeout(()=> {
                    this.submitSuccess = null;
                }, 2000);
            },
        },
        components: {
        }
    }
</script>