<template>
    <div class="item-cryptocurrencies">

        <div class="item-cryptocurrencies__currency">
            <div class="block-img">
                <img :src=wallets[0].img>
            </div>

            <div class="block-name">
                <p>{{wallets[0].symbol}}</p>
                <span>{{wallets[0].name | clearStr}}</span>
            </div>

            <div class="block-name-mob">
                <p>
                    {{wallets[0].name | clearStr}}
                    <span>{{wallets[0].symbol}}</span>
                </p>

                <div class="block-networks">
                    <div class="block-networks__item" v-for="(item, key) in wallets" :key="key">
                        <p>{{item.sub}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="item-cryptocurrencies__networks">
            <div class="networks-item" v-for="(item, key) in wallets" :key="key">
                <p>{{item.sub}}</p>
            </div>
        </div>

        <div class="item-cryptocurrencies__checkbox">
            <v-switch
                    v-model="isActive"
                    color="success"
            ></v-switch>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'ItemCryptocurrencies',

        data: function() {
            return {
                modal: false,
                isActive: false,
            }
        },
        props: {
            wallets: {
                type: Array
            }
        },
        created() {
        },
        watch:{

        },
        computed: {
            courses() {
                return this.$store.getters.courses
            },
            charts() {
                return this.$store.getters.charts
            },
            user() {
                return this.$store.getters.user
            },
        },
        methods: {

        },
        components: {

        },
    }
</script>

<style>

</style>
