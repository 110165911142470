import { render, staticRenderFns } from "./AffiliateProgram.vue?vue&type=template&id=42b24c7e&scoped=true&"
import script from "./AffiliateProgram.vue?vue&type=script&lang=js&"
export * from "./AffiliateProgram.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42b24c7e",
  null
  
)

export default component.exports