<template>
    <div class="wrap-item-history-card">
        <div v-if="check_date && table" class="block-meta">
            <p>{{item.created_at | moment('dddd, DD MMMM YYYY')}}</p>

            <span>+{{$primNum(deposits * user.course_current.course_current)}} {{user.course_current.course_ticker}}</span>
        </div>

        <div class="item-history">
            <p v-if="table" class="item-history__date">{{item.created_at | moment('HH:mm')}}</p>
            <p v-else class="item-history__date">{{item.created_at | moment("DD.MM.YYYY, HH:mm:ss")}}</p>

            <p class="item-history__sum" :style="{'color': (item.opp_type == 1) ? '#01AA4F' : '#DF5150'}">{{item.opp_type == 1 ? '+' : '-'}}{{$primNum(item.amount_from, 2)}}</p>

            <span class="item-history__desc">Lorem ipsum dolor sit amet.</span>

            <div v-if="table" class="item-history__status">
                <span  v-if="item.status == 0" class="expectation-status">{{__('web_history_Expectation')}}</span>
                <span  v-else-if="([2,3,4,7].includes(item.status))" class="completed-status">{{__('web_history_Completed')}}</span>
                <span class="error-status" v-else-if="item.status == 5">{{__('web_history_Overdue')}}</span>
                <span class="partially-status" v-else-if="([1,6].includes(item.status))">{{__('web_history_Partially')}}</span>
                <span class="error-status" v-else-if="item.status == 8">{{__('web_history_Canceled')}}</span>
                <span  v-else class="error-status">{{__('web_history_Error')}}</span>
            </div>
            <div v-else class="item-history__status">
                <span  v-if="item.status == 0" class="expectation-status">{{__('web_history_Expectation')}}</span>
                <span  v-else-if="([1].includes(item.status))" class="completed-status">{{__('web_history_Completed')}}</span>
                <span  v-else class="error-status">{{__('web_history_Error')}}</span>
            </div>
        </div>
    </div>
</template>

<script>

    import moment from 'moment';

    export default {
        name: 'ItemHistoryCard',
        data: function() {
            return {
            }
        },
        computed: {
            check_date() {
                if(this.history[this.index-1]) {
                    let created_at= moment.utc(this.item.created_at).format('DD.MM.YYYY');
                    let prev_at= moment.utc(this.history[this.index-1].created_at).format('DD.MM.YYYY');
                    return created_at!= prev_at;
                } else {
                    return true;
                }
            },
            deposits() {
                if (this.table) {
                    let table = this.table,
                        date = moment.utc(this.item.created_at).format('YYYY-MM-DD');

                    return (table[date]) ? table[date] : 0;
                } else {
                    return true
                }
            },
            user() {
                return this.$store.getters.user
            },
        },
        props: {
            history: {
                type: Array
            },
            index: {
                type: Number
            },
            item: {
                type: Object
            },
            table: {
                type: Object
            }
        },
        created() {

        },
        methods: {

        },
        components: {
        }
    }
</script>