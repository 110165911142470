<template>
    <div class="wrap-verification">

        <div class="wrap-verification__head">
            <v-btn v-ripple="false" text icon class="icon" @click="modalCancel">
                <img src="/dist/images/close.svg" alt="/" />
            </v-btn>
            <h2 class="verification-title">{{__('web_ver_Verify Identity')}}</h2>
            <p class="desc">Transfiguration lion prince minister werewolf banquet nearly-headless hippogriff. You-know-who nagini.</p>
            <div class="grid-wrapper">
                <div class="block-step block-step-inProgress">
                    <div class="block-step__head">
                        <div class="block-num block-num--inProgress">1</div>
                    </div>
                    <div class="block-step__body">
<!--                        <span class="block-headline">{{__('web_ver_Step 1')}}</span>-->
                        <p class="block-title">Personal information</p>
<!--                        <span class="block-status block-status&#45;&#45;inProgress">{{__('web_ver_In Progress')}}</span>-->
                    </div>
                </div>

                <div class="block-step">
                    <div class="block-step__head">
                        <div class="block-num block-num--pending">2</div>
                    </div>

                    <div class="block-step__body">
<!--                        <span class="block-headline">{{__('web_ver_Step 2')}}</span>-->
                        <p class="block-title">Documents</p>
<!--                        <span class="block-status block-status&#45;&#45;pending">{{__('web_ver_Pending')}}</span>-->
                    </div>
                </div>

                <div class="block-step">
                    <div class="block-step__head">
                        <div class="block-num block-num--pending">3</div>
                    </div>

                    <div class="block-step__body">
<!--                        <span class="block-headline">{{__('web_ver_Step 3')}}</span>-->
                        <p class="block-title">Document photo</p>
<!--                        <span class="block-status block-status&#45;&#45;pending">{{__('web_ver_Pending')}}</span>-->
                    </div>
                </div>
            </div>
        </div>

        <div class="wrap-verification__body">

            <p class="caption">{{__('web_ver_pers_Fill your personal info to continue verification')}}</p>

            <div class="wrapper-input">
                <span class="wrapper-input__title">{{__('web_ver_pers_First name')}}</span>

                <InputLabelTextDefault
                        :placeholder="__('web_ver_pers_Enter your first name')"
                        color="main"
                        v-model="form.first_name"
                        v-bind:errors="formValidate.first_name"
                        @change="updateValidate('first_name')"
                ></InputLabelTextDefault>
            </div>

            <div class="wrapper-input">
                <span class="wrapper-input__title">{{__('web_ver_pers_Middle name')}}</span>

                <InputLabelTextDefault
                        :placeholder="__('web_ver_pers_Enter your middle name')"
                        color="main"
                        v-model="form.middle_name"
                        v-bind:errors="formValidate.middle_name"
                        @change="updateValidate('middle_name')"
                ></InputLabelTextDefault>
            </div>

            <div class="wrapper-input">
                <span class="wrapper-input__title">{{__('web_ver_pers_Last name')}}</span>

                <InputLabelTextDefault
                        :placeholder="__('web_ver_pers_Enter your last name')"
                        color="main"
                        v-model="form.last_name"
                        v-bind:errors="formValidate.last_name"
                        @change="updateValidate('last_name')"
                ></InputLabelTextDefault>
            </div>

            <div class="wrapper-input">
                <span class="wrapper-input__title">{{__('web_ver_pers_Nationality')}}</span>

                <DropdownCountry
                        v-bind:value.sync="form.residence_country"
                        @change="updateValidate('residence_country')"
                        v-bind:errors="formValidate.residence_country"
                        @updateCountry="updateCountry"
                ></DropdownCountry>
            </div>

            <div class="wrapper-input">
                <span class="wrapper-input__title">{{__('web_ver_pers_Date of birth')}}</span>

<!--                <VueDatePicker-->
<!--                    :placeholder="__('web_ver_pers_Choose your date of birth')"-->
<!--                    :name="__('web_ver_pers_Date of birth')"-->
<!--                    v-model="form.dob"-->
<!--                    clearable-->
<!--                    fullscreen-mobile-->
<!--                    :max-date="new Date().toISOString().substr(0, 10)"-->
<!--                    :visible-years-number="100"-->
<!--                    @onOpen="openDatepicker"-->
<!--                    @onClose="closeDatepicker"-->
<!--                    ref="menu"-->
<!--                    color="#01AA4F"-->
<!--                    :locale="{ lang: this.lang }"-->
<!--                    format="DD.MM.YYYY"-->
<!--                    @onChange="changeBirth"-->
<!--                    :class="ageValidation ? 'no-valide' : ''"-->
<!--                >-->
<!--                    <template #input-icon>-->
<!--                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                            <path d="M6.04163 4.16663V1.66663C6.04163 1.32496 6.32496 1.04163 6.66663 1.04163C7.00829 1.04163 7.29163 1.32496 7.29163 1.66663V4.16663C7.29163 4.50829 7.00829 4.79163 6.66663 4.79163C6.32496 4.79163 6.04163 4.50829 6.04163 4.16663Z" fill="#999999"/>-->
<!--                            <path d="M12.7084 4.16663V1.66663C12.7084 1.32496 12.9917 1.04163 13.3334 1.04163C13.675 1.04163 13.9584 1.32496 13.9584 1.66663V4.16663C13.9584 4.50829 13.675 4.79163 13.3334 4.79163C12.9917 4.79163 12.7084 4.50829 12.7084 4.16663Z" fill="#999999"/>-->
<!--                            <path d="M6.76667 12.0168C6.65833 11.9751 6.575 11.9168 6.49167 11.8418C6.34167 11.6834 6.25 11.4751 6.25 11.2501C6.25 11.1418 6.275 11.0334 6.31667 10.9334C6.35833 10.8334 6.41667 10.7418 6.49167 10.6584C6.575 10.5834 6.65833 10.5251 6.76667 10.4834C7.06667 10.3584 7.44167 10.4251 7.675 10.6584C7.825 10.8168 7.91667 11.0334 7.91667 11.2501C7.91667 11.3001 7.90833 11.3584 7.9 11.4168C7.89167 11.4668 7.875 11.5168 7.85 11.5668C7.83333 11.6168 7.80833 11.6668 7.775 11.7168C7.75 11.7584 7.70833 11.8001 7.675 11.8418C7.51667 11.9918 7.3 12.0834 7.08333 12.0834C6.975 12.0834 6.86667 12.0584 6.76667 12.0168Z" fill="#999999"/>-->
<!--                            <path d="M9.68329 12.0166C9.57496 11.9749 9.49163 11.9166 9.40829 11.8416C9.25829 11.6832 9.16663 11.4749 9.16663 11.2499C9.16663 11.1416 9.19163 11.0332 9.23329 10.9332C9.27496 10.8332 9.33329 10.7416 9.40829 10.6582C9.49163 10.5832 9.57496 10.5249 9.68329 10.4832C9.98329 10.3499 10.3583 10.4249 10.5916 10.6582C10.7416 10.8166 10.8333 11.0332 10.8333 11.2499C10.8333 11.2999 10.825 11.3582 10.8166 11.4166C10.8083 11.4666 10.7916 11.5166 10.7666 11.5666C10.75 11.6166 10.725 11.6666 10.6916 11.7166C10.6666 11.7582 10.625 11.7999 10.5916 11.8416C10.4333 11.9916 10.2166 12.0832 9.99996 12.0832C9.89163 12.0832 9.78329 12.0582 9.68329 12.0166Z" fill="#999999"/>-->
<!--                            <path d="M12.6 12.0166C12.4917 11.9749 12.4084 11.9166 12.325 11.8416C12.2917 11.7999 12.2584 11.7582 12.225 11.7166C12.1917 11.6666 12.1667 11.6166 12.15 11.5666C12.125 11.5166 12.1084 11.4666 12.1 11.4166C12.0917 11.3582 12.0834 11.2999 12.0834 11.2499C12.0834 11.0332 12.175 10.8166 12.325 10.6582C12.4084 10.5832 12.4917 10.5249 12.6 10.4832C12.9084 10.3499 13.275 10.4249 13.5084 10.6582C13.6584 10.8166 13.75 11.0332 13.75 11.2499C13.75 11.2999 13.7417 11.3582 13.7334 11.4166C13.725 11.4666 13.7084 11.5166 13.6834 11.5666C13.6667 11.6166 13.6417 11.6666 13.6084 11.7166C13.5834 11.7582 13.5417 11.7999 13.5084 11.8416C13.35 11.9916 13.1334 12.0832 12.9167 12.0832C12.8084 12.0832 12.7 12.0582 12.6 12.0166Z" fill="#999999"/>-->
<!--                            <path d="M6.76667 14.9333C6.66667 14.8917 6.575 14.8333 6.49167 14.7583C6.34167 14.6 6.25 14.3833 6.25 14.1667C6.25 14.0583 6.275 13.95 6.31667 13.85C6.35833 13.7417 6.41667 13.65 6.49167 13.575C6.8 13.2667 7.36667 13.2667 7.675 13.575C7.825 13.7333 7.91667 13.95 7.91667 14.1667C7.91667 14.3833 7.825 14.6 7.675 14.7583C7.51667 14.9083 7.3 15 7.08333 15C6.975 15 6.86667 14.975 6.76667 14.9333Z" fill="#999999"/>-->
<!--                            <path d="M9.40829 14.7583C9.25829 14.6 9.16663 14.3833 9.16663 14.1667C9.16663 14.0583 9.19163 13.95 9.23329 13.85C9.27496 13.7417 9.33329 13.65 9.40829 13.575C9.71663 13.2667 10.2833 13.2667 10.5916 13.575C10.6666 13.65 10.725 13.7417 10.7666 13.85C10.8083 13.95 10.8333 14.0583 10.8333 14.1667C10.8333 14.3833 10.7416 14.6 10.5916 14.7583C10.4333 14.9083 10.2166 15 9.99996 15C9.78329 15 9.56663 14.9083 9.40829 14.7583Z" fill="#999999"/>-->
<!--                            <path d="M12.325 14.7583C12.25 14.6833 12.1917 14.5916 12.15 14.4833C12.1084 14.3833 12.0834 14.2749 12.0834 14.1666C12.0834 14.0583 12.1084 13.9499 12.15 13.8499C12.1917 13.7416 12.25 13.6499 12.325 13.5749C12.5167 13.3833 12.8084 13.2916 13.075 13.3499C13.1334 13.3583 13.1834 13.3749 13.2334 13.3999C13.2834 13.4166 13.3334 13.4416 13.3834 13.4749C13.425 13.4999 13.4667 13.5416 13.5084 13.5749C13.6584 13.7333 13.75 13.9499 13.75 14.1666C13.75 14.3833 13.6584 14.5999 13.5084 14.7583C13.35 14.9083 13.1334 14.9999 12.9167 14.9999C12.7 14.9999 12.4834 14.9083 12.325 14.7583Z" fill="#999999"/>-->
<!--                            <path d="M2.91663 8.19983C2.57496 8.19983 2.29163 7.9165 2.29163 7.57483C2.29163 7.23316 2.57496 6.94983 2.91663 6.94983H17.0833C17.425 6.94983 17.7083 7.23316 17.7083 7.57483C17.7083 7.9165 17.425 8.19983 17.0833 8.19983H2.91663Z" fill="#999999"/>-->
<!--                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66667 18.9583C3.625 18.9583 1.875 17.2083 1.875 14.1666V7.08329C1.875 4.04163 3.625 2.29163 6.66667 2.29163H13.3333C16.375 2.29163 18.125 4.04163 18.125 7.08329V14.1666C18.125 17.2083 16.375 18.9583 13.3333 18.9583H6.66667ZM3.125 7.08329V14.1666C3.125 16.55 4.28333 17.7083 6.66667 17.7083H13.3333C15.7167 17.7083 16.875 16.55 16.875 14.1666V7.08329C16.875 4.69996 15.7167 3.54163 13.3333 3.54163H6.66667C4.28333 3.54163 3.125 4.69996 3.125 7.08329Z" fill="#999999"/>-->
<!--                        </svg>-->

<!--                    </template>-->
<!--                </VueDatePicker>-->

                <date-picker
                    v-model="form.dob"
                    :placeholder="__('web_placeholder_DD.MM.YYYY')"
                    :append-to-body="false"
                    value-type="YYYY-MM-DD"
                    format="DD.MM.YYYY"
                    :editable="true"
                    :clearable="true"
                    prefix-class="mx"
                    input-class="datepicker"
                    @change="changeBirth"
                    @open="openDatepicker"
                    @close="closeDatepicker"
                    @clear="clearDatepicker"
                    @focus="onFocus"
                    v-mask="'##.##.####'"
                >
                    <template #icon-calendar>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.75 7.53754V13.5C15.75 15 15 15.75 13.5 15.75H4.5C3 15.75 2.25 15 2.25 13.5V7.53754C2.25 7.41304 2.35054 7.3125 2.47504 7.3125H15.525C15.6495 7.3125 15.75 7.41304 15.75 7.53754ZM13.5 3.375H12.5625V2.25C12.5625 1.9425 12.3075 1.6875 12 1.6875C11.6925 1.6875 11.4375 1.9425 11.4375 2.25V3.375H6.5625V2.25C6.5625 1.9425 6.3075 1.6875 6 1.6875C5.6925 1.6875 5.4375 1.9425 5.4375 2.25V3.375H4.5C3 3.375 2.25 4.125 2.25 5.625V5.96246C2.25 6.08696 2.35054 6.1875 2.47504 6.1875H15.525C15.6495 6.1875 15.75 6.08696 15.75 5.96246V5.625C15.75 4.125 15 3.375 13.5 3.375Z" fill="black"/>
                        </svg>

                    </template>
                </date-picker>

                <p class="wrapper-input__error" v-if="formValidate.dob && formValidate.dob.length">{{formValidate.dob[0]}}</p>
                <p class="wrapper-input__error" v-else-if="ageValidation">{{__('No 18 years old')}}</p>
            </div>

            <div class="wrapper-input">
                <span class="wrapper-input__title mb-3">{{__('web_ver_pers_Gender')}}</span>

                <div class="block-radio">
                    <div class="block-radio__item">
                        <input type="radio" id="male" v-model="form.gender" value="M">
                        <label for="male">{{__('web_ver_pers_Male')}}</label>
                    </div>

                    <div class="block-radio__item">
                        <input type="radio" id="female" v-model="form.gender" value="F">
                        <label for="female">{{__('web_ver_pers_Female')}}</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="wrap-verification__bottom">
            <v-btn
                class="btn-white"
                v-on:click="modalCancel"
            >
                <span>
                  {{__('web_ver_Cancel')}}
                </span>
            </v-btn>

            <v-btn
                class="btn-site"
                :loading="submitDisabled"
                v-on:click="onSubmit"
            >
                <span>
                  Next step
                </span>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import DropdownCountry from "../../modules/DropdownCountry";
    // import moment from "moment";
    import {maxLength, minLength, required} from "vuelidate/lib/validators";
    // import {mask} from 'vue-the-mask';
    // import { VueDatePicker } from '@mathieustan/vue-datepicker';
    // import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
    import moment from "moment";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    // import 'vue2-datepicker/locale/zh-cn';
    import(`vue2-datepicker/locale/${localStorage.language ? localStorage.language : 'en'}`);
    import {mask} from 'vue-the-mask';

    export default {
        name: 'BlockVerificationPersonalInfo',
        data: function () {
            return {
                menu: false,
                // locale: { lang: this.user.lang },
                submitDisabled: false,
                form: {
                    first_name: this.formVerificationPersonal.first_name ? this.formVerificationPersonal.first_name : null,
                    middle_name: this.formVerificationPersonal.middle_name ? this.formVerificationPersonal.middle_name : null,
                    last_name: this.formVerificationPersonal.last_name ? this.formVerificationPersonal.last_name : null,
                    dob: this.formVerificationPersonal.dob ? this.formVerificationPersonal.dob : null,
                    residence_country: this.formVerificationPersonal.residence_country ? this.formVerificationPersonal.residence_country : this.$countries[0].key,
                    gender: this.formVerificationPersonal.gender ? this.formVerificationPersonal.gender : 'M',
                },

                formValidate: {
                    first_name: null,
                    middle_name: null,
                    last_name: null,
                    dob: null,
                    residence_country: null,
                },

                ageValidation: false,
            }
        },

        validations: {
            form: {
                first_name: {
                    required,
                    maxLength: maxLength(50),
                    minLength: minLength(2),
                },
                middle_name: {
                    maxLength: maxLength(50),
                    minLength: minLength(2),
                },
                last_name: {
                    required,
                    maxLength: maxLength(50),
                    minLength: minLength(2),
                },
                dob: {
                    required
                },
                residence_country: {
                    required,
                },
            }
        },
        props: {
            step: {
                type: Number
            },
            formVerificationPersonal: {
                type: Object
            },
            formVerificationDocument: {
                type: Object
            }
        },

        watch: {
            menu (val) {
                val && setTimeout(() => (this.$refs.menu.$refs.agenda.mode = 'year'));

                if (val) {
                    this.formValidate.dob = null;
                }
            },
        },

        computed: {
            user() {
                return this.$store.getters.user
            },
            lang() {
                return this.$store.state.language.lang;
            },
        },


        created() {

        },
        methods: {
            updateCountry(val) {
                this.form.residence_country = val;
            },
            changeBirth() {
                this.validationDate();
                this.updateValidate('dob');
            },
            openDatepicker() {
                this.formValidate.dob = null;
            },

            onFocus() {
                this.formValidate.dob = null;
                this.ageValidation = false;

            },

            clearDatepicker() {
                this.ageValidation = false;
                this.updateValidate('dob');
            },

            closeDatepicker() {
                // this.menu = false;
                this.validationDate();
                this.updateValidate('dob');
            },

            validationDate () {
                let value = moment.utc(this.form.dob).format('DD-MM-YYYY');
                /* убираем все символы маски ввода c разделением через дефиз (05-12-2002) */
                let valueWithoutDash = value.replace(/[^\d]/g, '');

                if (valueWithoutDash.length === 8) {
                    /* проверка на корректность введенной даты, чтобы не было числа 77 или месяца 99 и т.п. Основана на свойстве объекта даты - автоисправление */
                    const arrBirth = value.split("-");
                    const birth = new Date(arrBirth[2], arrBirth[1] - 1, arrBirth[0]);
                    let today = new Date();

                    if ((birth.getFullYear() != arrBirth[2]) || (birth.getMonth() + 1 != arrBirth[1]) || (birth.getDate() != arrBirth[0])) {
                        /* действие на случай введения некорректной даты */
                        return;
                    }

                    const yearsOld18 = new Date();
                    yearsOld18.setFullYear(today.getFullYear() - 18);

                    if (birth > yearsOld18) {
                        /* действие на случай если меньше 18 лет */
                        this.ageValidation = true;
                    } else {
                        this.ageValidation = false;
                    }
                }
            },

            updateValidate (type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v.form[type], type);
            },

            modalCancel() {
                this.$emit('modalCancel')
            },

            onSubmit() {
                this.validationDate();
                let valid = this.$v.form;

                if (valid.$invalid) {
                    let massages = {
                        first_name: [],
                        middle_name: [],
                        last_name: [],
                        dob: [],
                        residence_country: [],
                        gender: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);

                    const keys = Object.keys(this.formValidate);
                    let itemError = null;
                    keys.forEach((key) => {
                        if (this.formValidate[key].length) {
                            itemError = key;
                        }
                    });
                    console.log('itemError', itemError);
                    // if (itemError) {
                    //     this.$refs[itemError].scrollIntoView({block: "center", behavior: "smooth"});
                    // }

                } else if (this.ageValidation) {
                    return false
                }
                 else {
                    this.submitDisabled = true;
                    const request = this.$cleanObject(this.form);
                    // request.residence_country = this.form.residence_country.key;

                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/setting/applicants", request)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            if(response) {

                                // response = {
                                //     "data": {
                                //         "applicant_id": "d5b696d513199043f7083db818fd860b81cd1212",
                                //         "application": {
                                //             "applicant_id": "d5b696d513199043f7083db818fd860b81cd1212",
                                //             "external_applicant_id": null,
                                //             "type": "PERSON",
                                //             "created_at": "2022-10-08 22:36:49",
                                //             "profile_status": null,
                                //             "profile_comment": null,
                                //             "first_name": "Name test",
                                //             "origin_first_name": null,
                                //             "middle_name": "middle",
                                //             "origin_middle_name": null,
                                //             "last_name": "Last",
                                //             "origin_last_name": null,
                                //             "residence_country": "UA",
                                //             "dob": "2022-10-09",
                                //             "gender": "M",
                                //             "email": "ck@gmail.com",
                                //             "phone": null,
                                //             "wallet_address": null,
                                //             "telegram_username": null,
                                //             "pep": null,
                                //             "custom_field_1": null,
                                //             "custom_field_2": null,
                                //             "custom_field_3": null,
                                //             "custom_field_4": null,
                                //             "custom_field_5": null,
                                //             "decline_reasons": [],
                                //             "addresses": [],
                                //             "documents": [],
                                //             "verification_status": "new",
                                //             "verifications_count": "0"
                                //         },
                                //         "status": true
                                //     },
                                //     "status": 200
                                // };


                                this.$emit('update:step', 2);
                                this.$emit('update:formVerificationPersonal', this.form);
                                this.submitDisabled = false;

                                let user_info = this.$cleanObject(this.user);
                                user_info.applicant_id = response.data.applicant_id;
                                user_info.application = response.data.application;
                                this.$store.commit('updateUser', user_info);

                                let form_document = this.$cleanObject(this.formVerificationDocument);
                                form_document.applicant_id = response.data.applicant_id;
                                this.$emit('update:formVerificationDocument', form_document);
                            }
                        })
                        .catch((err) => {
                            this.submitDisabled = false;
                            let response = this.httpHandler(err);
                            if (response.error) {
                                this.formValidate = response.error.message;
                            }
                        });

                }
            },

        },
        components: {
            InputLabelTextDefault,
            DropdownCountry,
            // VueDatePicker,
            DatePicker
        },
        directives: {mask}
    }
</script>

<style>

</style>
