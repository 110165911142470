<template>
    <div class="footer-main">
        <u-animate-container class="container">
            <u-animate
                    name="animate__fadeIn"
                    delay="0s"
                    duration="1s"
                    :iteration="1"
                    :offset="0"
                    animateClass="animated"
                    :begin="false"
            >
            <div class="footer-main__bottom">
                <div class="block-left">
                    <p class="block-rights__text">© {{date}} {{__('web_Cryptowallet')}}</p>
                </div>
                <div class="block-links">
<!--                    <router-link :to="{name: 'privacy', params:{lang: $store.getters.getLang}}">Privacy Policy</router-link>-->
<!--                    <router-link :to="{name: 'terms', params:{lang: $store.getters.getLang}}">Terms & Conditions</router-link>-->
                    <a>{{__('web_Privacy Policy')}}</a>
                    <a>{{__('web_Terms & Conditions')}}</a>
                    <a>{{__('About')}}</a>
                </div>
                <div class="block-languages">
                    <a @click="scrollToTop">{{__('Back to top')}}</a>
                </div>
        </div>
            </u-animate>

        </u-animate-container>
    </div>
</template>

<script>
    import VAnimateCss from 'v-animate-css';
    import {UAnimateContainer, UAnimate} from 'vue-wow';
    export default {
        data: function() {
            return {
                token_price: 0,
                directives: { VAnimateCss },
            }
        },
        name: 'HomeFooter',
        computed: {
            portfolio() {
                return this.$store.state.auth.portfolio
            },

            date() {
                return new Date().getFullYear()
            },
        },
        methods: {
            scrollToTop() {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            },

        },

        components: {
            UAnimateContainer,
            UAnimate,
        }
    }


</script>

<style scoped>

</style>