<template>
    <router-link :to="{name: 'card_page', params: {id: item.id}}" class="item-card">

        <p class="block-status">{{__('web_item_card_status_active')}}</p>
<!--        <p class="block-status">{{__('web_item_card_status_inactive')}}</p>-->

        <div class="block-card">
            <p class="block-card__number">{{item.number.slice(0, 4) + ' •••• ' + '•••• ' + item.number.slice(-4)}}</p>

            <div class="block-card__info">
                <p>Trent Alexander-Arnold</p>
                <span>06 / 25</span>
            </div>
        </div>

        <div class="block-info">
            <p class="block-name">VISA Virtual card</p>

            <p class="block-balance">{{user.course_current.course_marker}} {{$primNum('999' * user.course_current.course_current)}}</p>

            <div class="block-limit">
                <span class="block-limit__title">{{__('web_item_card_block_limit_title')}}</span>
                <p class="block-limit__text">{{user.course_current.course_marker}} {{$primNum('999' * user.course_current.course_current)}}</p>
            </div>

            <div class="block-buttons">
                <router-link :to="user.google_2fa ? {name: 'deposit'} : {name: 'setting', params: {'isTwoFa': true}}" class="btn-deposit">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.8337 5.34175L14.6587 4.16675L5.83366 12.9917L5.83366 7.50008H4.16699L4.16699 15.8334L12.5003 15.8334V14.1667L7.00866 14.1667L15.8337 5.34175Z" fill="#01AA4F"/>
                    </svg>

                    {{__('web_btn_top_up')}}
                </router-link>

                <router-link :to="user.google_2fa ? {name: 'withdraw'} : {name: 'setting', params: {'isTwoFa': true}}" class="btn-withdraw">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.16634 14.6583L5.34134 15.8333L14.1663 7.00825L14.1663 12.4999L15.833 12.4999L15.833 4.16659L7.49968 4.16659L7.49968 5.83325L12.9913 5.83325L4.16634 14.6583Z" fill="#F51E38"/>
                    </svg>

                    {{__('web_btn_withdraw')}}
                </router-link>
            </div>
        </div>
    </router-link>
</template>

<script>

    // import moment from 'moment';

    export default {
        name: 'ItemCard',
        data: function() {
            return {
            }
        },
        computed: {
            user() {
                return this.$store.getters.user
            },
        },
        props: {
            item: {
                type: Object
            }
        },
        created() {

        },
        methods: {

        },
        components: {
        }
    }
</script>