<template>
    <div class="home-page">
        <u-animate-container>

            <u-animate
                    name="animate__fadeIn"
                    delay="0s"
                    duration="1s"
                    :iteration="1"
                    :offset="0"
                    animateClass="animated"
                    :begin="false"
            >
                <div class="home-menu">
            <div class="content">
                <div class="left">
                    <div class="logo">
                        <router-link :to="{name: 'home'}"><img src="/dist/images/logo-f.svg" alt=""></router-link>
                    </div>
                </div>

                <div class="menu">
                    <a href="#" v-scroll-to="'#product'">{{__('web_Product')}}</a>
                    <a href="#" v-scroll-to="'#features'">{{__('web_Features')}}</a>
                    <a href="#" v-scroll-to="'#functionality'">{{__('web_Functionality')}}</a>
                    <a href="#" v-scroll-to="'#cards'">{{__('web_Cards')}}</a>
                </div>

                <div class="right">

                    <div class="right-btn" v-if="portfolio">
                        <router-link :to="{name: 'dashboard'}" class="btn-white">{{portfolio.name}}</router-link>
                    </div>
                    <div class="right-btn" v-else>
                        <router-link :to="{name: 'login'}" class="btn-white">{{__('web_login_Log in')}}</router-link>
                        <router-link :to="{name: 'register'}" class="btn-site">{{__('web_sign up')}}</router-link>
                    </div>
                </div>
            </div>
        </div>

                <div id="product" class="header">
                    <div class="container">
                        <div class="top">
                            <p class="left-headline">{{__('FAST. EASY. EFFECTIVE.')}}</p>
                            <h1 class="left-title">{{__('Cryptocoin Wallet')}} <span>{{__('and Payments')}}</span></h1>
                            <p class="left-text">{{__('One system – one integration, yours Media pay ecosystem')}}</p>
                            <div class="right-btn" v-if="portfolio">
                                <router-link :to="{name: 'dashboard'}" class="btn-white">{{portfolio.name}}</router-link>
                            </div>
                            <div class="right-btn" v-else>
                                <router-link :to="{name: 'login'}" class="btn-white">{{__('web_login_Log in')}}</router-link>
                                <router-link :to="{name: 'register'}" class="btn-site">{{__('web_sign up')}}</router-link>
                            </div>
                        </div>

                        <u-animate
                                name="animate__fadeInUp"
                                delay="0s"
                                duration="1s"
                                :iteration="1"
                                :offset="0"
                                animateClass="animated"
                                :begin="false"
                        >
                        <div class="header-img">
                            <img src="/dist/images/home-header.png" alt="">
                        </div>

                        </u-animate>
                    </div>
                </div>

                <div id="features" class="features">
                    <div class="container">
                        <div class="block">
                            <h2>{{__('Our Features')}}</h2>
                            <p>{{__('Why you should to choose Us?')}}</p>
                        </div>
                        <ul>
                            <u-animate
                                    class="li"
                                    name="animate__fadeIn"
                                    delay="0s"
                                    duration="1s"
                                    :iteration="1"
                                    :offset="0"
                                    animateClass="animated"
                                    :begin="false"

                            >
                                <li>

                                    <div class="img">
                                        <img src="/dist/images/features-icon-1.svg" alt="">
                                    </div>
                                    <div class="text-block">
                                        <p class="text-u">{{__('for business')}}</p>
                                        <p class="text">{{__('Accept cryptocurrency as a payment method')}}</p>
                                        <p class="desc">{{__('Receive payments in cryptocurrencies with conversion into FIAT currencies.')}}</p>
                                        <p class="text-b">{{__('Connecting Cryptocurrency with the Real World')}}</p>
                                    </div>
                                </li>
                            </u-animate>
                            <u-animate
                                    class="li"
                                    name="animate__fadeIn"
                                    delay="0s"
                                    duration="2s"
                                    :iteration="1"
                                    :offset="0"
                                    animateClass="animated"
                                    :begin="false"

                            >
                            <li>
                                <div class="img">
                                    <img src="/dist/images/features-icon-2.svg" alt="">
                                </div>
                                <div class="text-block">
                                    <p class="text-u">{{__('functionality')}}</p>
                                    <p class="text">{{__('Smart chain support')}}</p>
                                    <p class="desc">{{__('The ability to use crypto assets in different blockchain networks with your bank.')}}</p>
                                    <p class="desc">{{__('Relevant and important news in the world of cryptocurrencies.')}}</p>
                                </div>
                            </li>
                            </u-animate>
                                <u-animate
                                        class="li"
                                        name="animate__fadeIn"
                                        delay="0s"
                                        duration="3s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                        :begin="false"

                                >
                            <li>
                                <div class="img">
                                    <img src="/dist/images/features-icon-3.svg" alt="">
                                </div>
                                <div class="text-block">
                                    <p class="text-u">{{__('for business')}}</p>
                                    <p class="text">{{__('Instant exchange of cryptocurrencies and fiat')}}</p>
                                    <p class="desc">{{__('Fast exchange with high liquidity supporting all networks and subnets.')}}</p>
                                </div>
                            </li>
                                </u-animate>
                        </ul>
                    </div>
                </div>

                <div id="cards" class="block-card">
                    <u-animate
                            class="li"
                            name="animate__fadeIn"
                            delay="0s"
                            duration="1s"
                            :iteration="1"
                            :offset="0"
                            animateClass="animated"
                            :begin="false"

                    >
                    <div class="container">
                        <div class="block-text">
                            <p class="text-headline">{{__('FINANCIAL LIFE')}}</p>
                            <h2 class="text-title">{{__('Physical and virtual cards')}}</h2>
                            <p class="text-desc">{{__('Plastic cards with automatic conversion of cryptocurrency to fiat.')}}</p>
                            <p class="text-desc">{{__('Coverage in 60% of the countries of the world.')}}</p>
                        </div>
                        <div class="card-img">
                            <u-animate
                                    class="li"
                                    name="animate__fadeInUp"
                                    delay="0s"
                                    duration="1s"
                                    :iteration="1"
                                    :offset="0"
                                    animateClass="animated"
                                    :begin="false"

                            >
                            <img src="/dist/images/card-home.png" alt="">
                            </u-animate>
                        </div>
                    </div>
                    </u-animate>
                </div>

                <div id="functionality" class="functional">
                    <div class="container">
                        <div class="content">
                            <u-animate
                                    class="li"
                                    name="animate__fadeIn"
                                    delay="0s"
                                    duration="1s"
                                    :iteration="1"
                                    :offset="0"
                                    animateClass="animated"
                                    :begin="false"

                            >
                            <div class="block">
                                <p class="desc-top">{{__('Sign up')}}</p>
                                <p class="text">{{__('Join over 200 000 users worldwide')}}</p>
                                <p class="desc">{{__('Start for free — try our financial instrument now!')}}</p>
                                <router-link :to="{name: 'register'}" class="btn-site">{{__('web_sign up')}}</router-link>
                            </div>
                            </u-animate>
                            <div class="img">
                                <u-animate
                                        class="li"
                                        name="animate__fadeInUp"
                                        delay="0s"
                                        duration="1s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                        :begin="false"

                                >
                                <img src="/dist/images/functi-icon.png" alt="">
                                </u-animate>
                            </div>
                        </div>
                    </div>
                </div>

            </u-animate>


        </u-animate-container>
    </div>
</template>

<script>
    import VAnimateCss from 'v-animate-css';
    import {UAnimateContainer, UAnimate} from 'vue-wow';
    export default {
        name: 'Home',

        data: function() {
            return {
                directives: { VAnimateCss },
            }
        },
        computed: {
            portfolio() {
                return this.$store.state.auth.portfolio
            },
        },
        created() {

        },
        components: {
            UAnimateContainer,
            UAnimate,
        }
    }


</script>

<style scoped>

</style>