<template>
    <div class="wrapper-item-history">
      <div class="item-history" v-on:click="$emit('update:modal_info', item)">

        <div v-if="item.opp_type == 1" class="item-history__icon" style="background-color: rgba(1, 170, 79, 0.15)">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.17 16.2502C9.84001 16.2502 8.75 15.1302 8.75 13.7502C8.75 13.3402 9.09 13.0002 9.5 13.0002C9.91 13.0002 10.25 13.3402 10.25 13.7502C10.25 14.3002 10.66 14.7502 11.17 14.7502H13.05C13.44 14.7502 13.75 14.4002 13.75 13.9702C13.75 13.4302 13.6 13.3502 13.26 13.2302L10.25 12.1802C9.61 11.9602 8.75 11.4902 8.75 10.0202C8.75 8.77023 9.74001 7.74023 10.95 7.74023H12.83C14.16 7.74023 15.25 8.86023 15.25 10.2402C15.25 10.6502 14.91 10.9902 14.5 10.9902C14.09 10.9902 13.75 10.6502 13.75 10.2402C13.75 9.69023 13.34 9.24023 12.83 9.24023H10.95C10.56 9.24023 10.25 9.59023 10.25 10.0202C10.25 10.5602 10.4 10.6402 10.74 10.7602L13.75 11.8102C14.39 12.0302 15.25 12.5002 15.25 13.9702C15.25 15.2302 14.26 16.2502 13.05 16.2502H11.17Z" fill="#01AA4F"/>
            <path d="M11.25 16.5V7.5C11.25 7.09 11.59 6.75 12 6.75C12.41 6.75 12.75 7.09 12.75 7.5V16.5C12.75 16.91 12.41 17.25 12 17.25C11.59 17.25 11.25 16.91 11.25 16.5Z" fill="#01AA4F"/>
            <path d="M1.25 12C1.25 6.07 6.07 1.25 12 1.25C12.41 1.25 12.75 1.59 12.75 2C12.75 2.41 12.41 2.75 12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 11.59 21.59 11.25 22 11.25C22.41 11.25 22.75 11.59 22.75 12C22.75 17.93 17.93 22.75 12 22.75C6.07 22.75 1.25 17.93 1.25 12Z" fill="#01AA4F"/>
            <path d="M17 7.75C16.59 7.75 16.25 7.41 16.25 7V3C16.25 2.59 16.59 2.25 17 2.25C17.41 2.25 17.75 2.59 17.75 3V6.25H21C21.41 6.25 21.75 6.59 21.75 7C21.75 7.41 21.41 7.75 21 7.75H17Z" fill="#01AA4F"/>
            <path d="M16.4695 7.52994C16.1795 7.23994 16.1795 6.75994 16.4695 6.46994L21.4695 1.46994C21.7595 1.17994 22.2395 1.17994 22.5295 1.46994C22.8195 1.75994 22.8195 2.23994 22.5295 2.52994L17.5295 7.52994C17.3795 7.67994 17.1895 7.74994 16.9995 7.74994C16.8095 7.74994 16.6195 7.67994 16.4695 7.52994Z" fill="#01AA4F"/>
          </svg>
        </div>

        <div v-else class="item-history__icon" style="background-color: rgba(223, 81, 80, 0.15)">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.17 16.2502C9.84001 16.2502 8.75 15.1302 8.75 13.7502C8.75 13.3402 9.09 13.0002 9.5 13.0002C9.91 13.0002 10.25 13.3402 10.25 13.7502C10.25 14.3002 10.66 14.7502 11.17 14.7502H13.05C13.44 14.7502 13.75 14.4002 13.75 13.9702C13.75 13.4302 13.6 13.3502 13.26 13.2302L10.25 12.1802C9.61 11.9502 8.75 11.4902 8.75 10.0202C8.75 8.77023 9.74001 7.74023 10.95 7.74023H12.83C14.16 7.74023 15.25 8.86023 15.25 10.2402C15.25 10.6502 14.91 10.9902 14.5 10.9902C14.09 10.9902 13.75 10.6502 13.75 10.2402C13.75 9.69023 13.34 9.24023 12.83 9.24023H10.95C10.56 9.24023 10.25 9.59023 10.25 10.0202C10.25 10.5602 10.4 10.6402 10.74 10.7602L13.75 11.8102C14.39 12.0402 15.25 12.5002 15.25 13.9702C15.25 15.2302 14.26 16.2502 13.05 16.2502H11.17Z" fill="#DF5150"/>
            <path d="M11.25 16.5V7.5C11.25 7.09 11.59 6.75 12 6.75C12.41 6.75 12.75 7.09 12.75 7.5V16.5C12.75 16.91 12.41 17.25 12 17.25C11.59 17.25 11.25 16.91 11.25 16.5Z" fill="#DF5150"/>
            <path d="M1.25 12C1.25 6.07 6.07 1.25 12 1.25C12.41 1.25 12.75 1.59 12.75 2C12.75 2.41 12.41 2.75 12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 11.59 21.59 11.25 22 11.25C22.41 11.25 22.75 11.59 22.75 12C22.75 17.93 17.93 22.75 12 22.75C6.07 22.75 1.25 17.93 1.25 12Z" fill="#DF5150"/>
            <path d="M21.25 6V2.75H18C17.59 2.75 17.25 2.41 17.25 2C17.25 1.59 17.59 1.25 18 1.25H22C22.41 1.25 22.75 1.59 22.75 2V6C22.75 6.41 22.41 6.75 22 6.75C21.59 6.75 21.25 6.41 21.25 6Z" fill="#DF5150"/>
            <path d="M16.4695 7.52994C16.1795 7.23994 16.1795 6.75994 16.4695 6.46994L21.4695 1.46994C21.7595 1.17994 22.2395 1.17994 22.5295 1.46994C22.8195 1.75994 22.8195 2.23994 22.5295 2.52994L17.5295 7.52994C17.3795 7.67994 17.1895 7.74994 16.9995 7.74994C16.8095 7.74994 16.6195 7.67994 16.4695 7.52994Z" fill="#DF5150"/>
          </svg>
        </div>


        <div class="item-history__info">


          <div class="info-left">
            <p v-if="item.opp_type == 1" class="info-left__title">{{__('web_history_Deposit')}}</p>

            <p v-else class="info-left__title">{{__('web_history_Withdraw')}}</p>

            <p class="info-left__date">{{item.created_at | moment('DD.MM.YYYY, HH:mm:ss')}}</p>
          </div>

          <div class="info-right">
            <p class="info-right__amount" :style="{'color': (item.opp_type == 1) ? '#01AA4F' : '#DF5150'}">{{item.opp_type == 1 ? '+' : '-'}}{{$primNum(item.amount_from, wallet.decimals)}}</p>
            <span class="info-right__status" v-if="item.status == 0">{{__('web_history_Expectation')}}</span>
            <span class="info-right__status" v-else-if="([1].includes(item.status))">{{__('web_history_Completed')}}</span>
            <span class="info-right__status" v-else>{{__('web_history_Error')}}</span>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import moment from 'moment';

export default {
  name: 'HistoryItem',
    data: function() {
      return {
          modal: false
      }
  },
  computed: {
      check_date() {
          if(this.history[this.index-1]) {
              let created_at= moment.utc(this.item.created_at).format('DD.MM.YYYY');
              let prev_at= moment.utc(this.history[this.index-1].created_at).format('DD.MM.YYYY');
              return created_at!= prev_at;
          } else {
              return true;
          }
      },
  },
  props: {
    history: {
      type: Array
    },
    index: {
        type: Number
    },
    item: {
      type: Object
    },
    wallet: {
      type: Object
    },
    modal_info: {
      type: Object
    }
  },
  created() {

  },
  methods: {
      openModal() {
          this.modal= true;
      }
  },
  components: {
  }
}
</script>