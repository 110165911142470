<template>
    <div class="wrap-verification">
        <div class="wrap-verification__head">
            <v-btn v-ripple="false" text icon class="icon" @click="modalCancel">
                <img src="/dist/images/close.svg" alt="/" />
            </v-btn>
            <h2 class="verification-title">{{__('web_ver_Verify Identity')}}</h2>

            <div class="grid-wrapper">
                <div class="block-step">
                    <div class="block-step__head">
                        <div class="block-num block-num--completed">1</div>
                        <div class="block-line block-line--completed"></div>
                    </div>

                    <div class="block-step__body">
                        <span class="block-headline">{{__('web_ver_Step 1')}}</span>
                        <p class="block-title">{{__('web_ver_Personal info')}}</p>
                        <span class="block-status block-status--completed">{{__('web_ver_Completed')}}</span>
                    </div>
                </div>

                <div class="block-step">
                    <div class="block-step__head">
                        <div class="block-num block-num--completed">2</div>
                        <div class="block-line block-line--completed"></div>
                    </div>

                    <div class="block-step__body">
                        <span class="block-headline">{{__('web_ver_Step 2')}}</span>
                        <p class="block-title">{{__('web_ver_Document')}}</p>
                        <span class="block-status block-status--completed">{{__('web_ver_Completed')}}</span>
                    </div>
                </div>

                <div class="block-step">
                    <div class="block-step__head">
                        <div class="block-num block-num--inProgress">3</div>
                        <div class="block-line block-line--inProgress"></div>
                    </div>

                    <div class="block-step__body">
                        <span class="block-headline">{{__('web_ver_Step 3')}}</span>
                        <p class="block-title">{{__('web_ver_Photo upload')}}</p>
                        <span class="block-status block-status--inProgress">{{__('web_ver_In Progress')}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="wrap-verification__body">

            <div class="block-upload">
                <div class="block-upload__item" :class="isErrorUpload ? 'block-upload__item--error' : ''">
                    <DragZone target="/ajax/setting/verification/upload" v-bind:file.sync="form" v-bind:isErrorUpload.sync="isErrorUpload">
                        <div class="block-upload__body" :style="((form.img) ? {background: 'url('+form.img+')'} : null)">

                            <div class="block-control" :style="((form.img) ? {backgroundColor: 'rgba(0, 0, 0, 0.65)'} : 'transparent')">
                                <div class="block-img-upload">
                                    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M118.199 99.4564H106.954V88.2114H99.4569V99.4564H88.2119V106.953H99.4569V118.198H106.954V106.953H118.199V99.4564Z" stroke="#01AA4F" stroke-opacity="0.4" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M106.953 74.8635V10.5479" stroke="#01AA4F" stroke-opacity="0.4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="47.81 59.76"/>
                                        <path d="M106.953 5.74833V2H103.205" stroke="#01AA4F" stroke-opacity="0.4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M93.4591 2H10.6211" stroke="#01AA4F" stroke-opacity="0.4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="48.53 60.67"/>
                                        <path d="M5.74833 2H2V5.74833" stroke="#01AA4F" stroke-opacity="0.4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M2 15.4939V98.3319" stroke="#01AA4F" stroke-opacity="0.4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="48.53 60.67"/>
                                        <path d="M2 103.205V106.953H5.74833" stroke="#01AA4F" stroke-opacity="0.4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15.3477 106.953H79.6633" stroke="#01AA4F" stroke-opacity="0.4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="47.81 59.76"/>
                                        <g clip-path="url(#clip0_1056_45804)">
                                            <path d="M78.7004 85.9636H25.3288C23.5096 85.9636 22.0364 84.4904 22.0364 82.6712V47.2358C22.0364 45.4166 23.5096 43.9434 25.3288 43.9434H78.7133C80.5324 43.9434 82.0057 45.4166 82.0057 47.2358V82.6841C81.9929 84.4905 80.5195 85.9637 78.7004 85.9637V85.9636ZM25.3288 44.968C24.0862 44.968 23.0613 45.9801 23.0613 47.2355V82.6839C23.0613 83.9265 24.0733 84.9514 25.3288 84.9514H78.7133C79.9559 84.9514 80.9808 83.9393 80.9808 82.6839V47.2355C80.9808 45.9929 79.9687 44.968 78.7133 44.968H25.3288Z" fill="#01AA4F"/>
                                            <path d="M76.3555 38.78C76.0737 38.78 75.843 38.5494 75.843 38.2675C75.843 37.0249 75.0488 36 74.0623 36H29.9663C28.9798 36 28.1855 37.0121 28.1855 38.2675C28.1855 38.5494 27.9549 38.78 27.6731 38.78C27.3913 38.78 27.1606 38.5494 27.1606 38.2675C27.1606 36.4484 28.4162 34.9751 29.9663 34.9751H74.0623C75.6124 34.9751 76.8679 36.4484 76.8679 38.2675C76.8679 38.5494 76.6373 38.78 76.3555 38.78Z" fill="#01AA4F"/>
                                            <path d="M73.7938 29.8122C73.5119 29.8122 73.2813 29.5816 73.2813 29.2997C73.2813 28.0699 72.5767 27.0322 71.744 27.0322H32.2851C31.4524 27.0322 30.7478 28.0699 30.7478 29.2997C30.7478 29.5816 30.5172 29.8122 30.2353 29.8122C29.9535 29.8122 29.7229 29.5816 29.7229 29.2997C29.7229 27.4806 30.8759 26.0073 32.2851 26.0073H71.744C73.1532 26.0073 74.3062 27.4806 74.3062 29.2997C74.3062 29.5816 74.0756 29.8122 73.7938 29.8122Z" fill="#01AA4F"/>
                                            <path d="M46.8902 74.4334H31.5168C31.235 74.4334 31.0044 74.2028 31.0044 73.921V68.7965C31.0044 64.2742 34.6812 60.5974 39.2035 60.5974C43.7258 60.5974 47.4026 64.2742 47.4026 68.7965V73.921C47.4026 74.2028 47.172 74.4334 46.8902 74.4334ZM32.0287 73.4085H46.3772V68.7965C46.3772 64.8379 43.1616 61.6223 39.2029 61.6223C35.2443 61.6223 32.0287 64.8379 32.0287 68.7965V73.4085Z" fill="#01AA4F"/>
                                            <path d="M39.2029 61.6217C36.0898 61.6217 33.566 59.0979 33.566 55.9848C33.566 52.8717 36.0898 50.3479 39.2029 50.3479C42.316 50.3479 44.8398 52.8717 44.8398 55.9848C44.8398 59.0979 42.316 61.6217 39.2029 61.6217ZM39.2029 51.3728C36.6535 51.3728 34.5909 53.4353 34.5909 55.9848C34.5909 58.5343 36.6535 60.5968 39.2029 60.5968C41.7524 60.5968 43.8149 58.5343 43.8149 55.9848C43.8149 53.4353 41.7524 51.3728 39.2029 51.3728Z" fill="#01AA4F"/>
                                            <path d="M73.7938 61.6216H53.296C53.0142 61.6216 52.7836 61.391 52.7836 61.1091C52.7836 60.8273 53.0142 60.5967 53.296 60.5967H73.7938C74.0757 60.5967 74.3063 60.8273 74.3063 61.1091C74.3063 61.391 74.0757 61.6216 73.7938 61.6216Z" fill="#01AA4F"/>
                                            <path d="M73.7938 69.3088H53.296C53.0142 69.3088 52.7836 69.0782 52.7836 68.7964C52.7836 68.5145 53.0142 68.2839 53.296 68.2839H73.7938C74.0757 68.2839 74.3063 68.5145 74.3063 68.7964C74.3063 69.0782 74.0757 69.3088 73.7938 69.3088Z" fill="#01AA4F"/>
                                            <path d="M73.7938 79.5578H30.2353C29.9535 79.5578 29.7229 79.3272 29.7229 79.0454C29.7229 78.7636 29.9535 78.533 30.2353 78.533H73.7938C74.0756 78.533 74.3062 78.7636 74.3062 79.0454C74.3062 79.3272 74.0756 79.5578 73.7938 79.5578Z" fill="#01AA4F"/>
                                            <path d="M54.5774 52.1423H57.1396V54.7046H54.5774V52.1423Z" fill="#01AA4F"/>
                                            <path d="M62.2635 52.1423H64.8258V54.7046H62.2635V52.1423Z" fill="#01AA4F"/>
                                            <path d="M69.9496 52.1423H72.5118V54.7046H69.9496V52.1423Z" fill="#01AA4F"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1056_45804">
                                                <rect width="60.0879" height="60" fill="white" transform="translate(22 26)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div class="block-upload-desc">
                                    <p :style="((form.img) ? {color: '#fff'} : '#242132')">{{__('web_ver_back_Unsload a photo of the reverse page of your document')}}</p>
                                </div>

                                <v-btn
                                    v-ripple="false"
                                    class="btn-site"
                                    :loading="submitDisabled"
                                >
                                <span>
                                  {{__('web_ver_back_Unload the photo')}}
                                </span>
                                </v-btn>
                            </div>
                        </div>
                    </DragZone>
<!--                    <div ref="document_img">-->
<!--                        <p v-if="formValidate.document_img" class="desc-error">{{formValidate.document_img[0]}}</p>-->
<!--                    </div>-->
                </div>
                <p v-if="isErrorUpload" class="text-error">Error upload photo (minimum resolution: 480px, maximum resolution: 7000px, maximum file size: 50 Mb)</p>
            </div>
        </div>

        <div class="wrap-verification__bottom">
            <v-btn
                class="btn-white"
                v-on:click="$emit('update:step', 3)"
            >
                <span>
                  {{__('web_ver_Back')}}
                </span>
            </v-btn>

            <v-btn
                class="btn-site"
                :loading="submitDisabled"
                v-on:click="onSubmit()"
            >
                <span>
                  {{__('web_ver_Send to verification')}}
                </span>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import DragZone from '../../modules/DragZone';

    export default {
        name: 'BlockVerificationBackDocument',
        data: function () {
            return {
                submitDisabled: false,

                form: {
                    file_id: this.formVerificationDocument.back_side_id,
                    img: (this.formVerificationDocument.back_side) ? this.formVerificationDocument.back_side : null,
                },


                formValidate: {
                    document_img: null,
                },

                isErrorUpload: false,

            }
        },

        validations: {
            form: {
                file_id: {
                    required,
                },
            }
        },


        props: {
            step: {
                type: Number
            },
            formVerificationDocument: {
                type: Object
            }
        },

        watch: {

        },

        computed: {
            user() {
                return this.$store.getters.user
            },
        },

        created() {

        },
        methods: {
            updateValidate (type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v.form[type], type);
            },

            modalCancel() {
                this.$emit('modalCancel')
            },

            onSubmit() {
                let valid = this.$v.form;

                if (valid.$invalid) {
                    let massages = {
                        front_side_id: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);

                    const keys = Object.keys(this.formValidate);
                    let itemError = null;
                    keys.forEach((key) => {
                        if (this.formValidate[key].length) {
                            itemError = key;
                        }
                    });
                    console.log('itemError', itemError);

                } else {
                    this.submitDisabled = true;
                    let form_document = this.$cleanObject(this.formVerificationDocument);

                    form_document.back_side = this.form.img;
                    form_document.back_side_id = this.form.file_id;

                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/setting/documents", form_document)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        if(response) {
                            // response = {
                            //     "data": {
                            //         "applicant_id": "d5b696d513199043f7083db818fd860b81cd1212",
                            //         "application": {
                            //             "applicant_id": "d5b696d513199043f7083db818fd860b81cd1212",
                            //             "external_applicant_id": null,
                            //             "type": "PERSON",
                            //             "created_at": "2022-10-08 22:36:49",
                            //             "profile_status": null,
                            //             "profile_comment": null,
                            //             "first_name": "Name test",
                            //             "origin_first_name": null,
                            //             "middle_name": "middle",
                            //             "origin_middle_name": null,
                            //             "last_name": "Last",
                            //             "origin_last_name": null,
                            //             "residence_country": "UA",
                            //             "dob": "2022-10-09",
                            //             "gender": "M",
                            //             "email": "ck@gmail.com",
                            //             "phone": null,
                            //             "wallet_address": null,
                            //             "telegram_username": null,
                            //             "pep": null,
                            //             "custom_field_1": null,
                            //             "custom_field_2": null,
                            //             "custom_field_3": null,
                            //             "custom_field_4": null,
                            //             "custom_field_5": null,
                            //             "decline_reasons": [],
                            //             "addresses": [],
                            //             "documents": [],
                            //             "verification_status": "new",
                            //             "verifications_count": "0"
                            //         },
                            //         "status": true
                            //     },
                            //     "status": 200
                            // };

                            this.submitDisabled = false;

                            let user_info = this.$cleanObject(this.user);
                            user_info.applicant_id = response.data.applicant_id;
                            user_info.verified = 1;
                            user_info.application = response.data.application;
                            this.$store.commit('updateUser', user_info);

                            this.$emit('modalCancel');
                        }
                    })
                    .catch((err) => {
                        this.submitDisabled = false;
                        let response = this.httpHandler(err);
                        if (response.error) {
                            this.formValidate = response.error.message;
                        }
                    });


                }
            }
        },
        components: {
            DragZone
        }
    }
</script>

<style>

</style>
