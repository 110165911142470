<template>
    <div class="page-deposit">
        <div class="block-head">
            <div class="block-head__left">
                <p class="head-title">{{__('web_page_deposit_title')}}</p>
            </div>

            <div class="block-head__right">

            </div>
        </div>


        <div class="block-body">
            <div class="block-body__left">
                <div class="block-coin">
                    <p class="block-coin__title">{{__('web_input_label_coin')}}</p>
                    <DropdownDefault
                            v-bind:items="dropdownWallets"
                            v-model="wallet"
                            dropSlot
                            :placeholder="__('web_input_placeholder_selected_coin')"
                    >
                        <template v-slot:selection="item">
                            <img :src=walletsGroup[item.name][0].img>
                            {{walletsGroup[item.name][0].name | clearStr}} ({{__(item.name)}})
                        </template>
<!--                        <template v-slot:prepend-item>-->
<!--                            <SearchDefault-->
<!--                                    color="dropdown_search"-->
<!--                                    :placeholder="__('Search by coin ticker')"-->
<!--                                    v-model="wallet_name"-->
<!--                            ></SearchDefault>-->
<!--                        </template>-->
                        <template v-slot:item="item">
                            <div class="block-coin">
                                <img :src=walletsGroup[item.name][0].img>
                                {{walletsGroup[item.name][0].name | clearStr}} ({{__(item.name)}})
                            </div>
                        </template>
                    </DropdownDefault>
                </div>

                <div v-if="networks.length" class="block-network">
                    <div class="block-network__item" v-for="(item, key) in networks" :key="key">
                        <v-btn v-ripple="false" text :disabled="item== network" :class="(item== network) ? 'active' : ''" v-on:click="changeWallet(item)">{{item.sub}}</v-btn>
                    </div>
                </div>

                <div v-if="address" class="block-address">

                    <div class="block-qr">
                        <VueQrcode :value="address" :options="{ width: 190, margin: 3 }"></VueQrcode>
                    </div>

                    <div class="block-text">
                        <p class="block-text__title">{{__('web_page_deposit_text_wallet_address')}}</p>

                        <div class="block-text__copy" v-if="isCopy">
                            <img src="/dist/images/img-copy.svg" alt="">
                            <p>{{__('web_text_copied')}}</p>
                        </div>

                        <div class="block-text__address" v-else>
                            <p>{{address}}</p>

                            <v-btn
                                    v-ripple="false"
                                    class="btn-copy"
                                    text
                                    v-on:click="copyMessage"
                            >
                                <svg data-v-18ed7092="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-18ed7092="" d="M21 9.625V18.375C21 20.125 20.125 21 18.375 21H9.625C7.875 21 7 20.125 7 18.375V9.625C7 7.875 7.875 7 9.625 7H18.375C20.125 7 21 7.875 21 9.625ZM15.75 3C15.75 2.586 15.414 2.25 15 2.25H5.625C3.448 2.25 2.25 3.448 2.25 5.625V15C2.25 15.414 2.586 15.75 3 15.75C3.414 15.75 3.75 15.414 3.75 15V5.625C3.75 4.293 4.293 3.75 5.625 3.75H15C15.414 3.75 15.75 3.414 15.75 3Z" fill="#344054"></path></svg>
                            </v-btn>
                        </div>
                    </div>
                </div>
                <div v-else class="block-loading">
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    <p class="block-loading__text">{{__('Loading')}}</p>
                </div>

                <div class="block-notifications">
                    <div class="block-notifications__item">
                        <div class="dot"></div>
                        <p>{{__('web_text_send_only')}} <span>{{wallet.name}}</span> {{__('web_text_to_this_deposit_address')}}</p>
                    </div>

                    <div class="block-notifications__item">
                        <div class="dot"></div>
                        <p>{{__('web_text_ensure_the_network_is')}} <span>{{network ? network.sub : ''}}</span>.</p>
                    </div>

                    <div class="block-notifications__item">
                        <div class="dot"></div>
                        <p>{{__('web_Do not send NFTs to this address')}}</p>
                    </div>
                </div>
            </div>

<!--            <div class="block-body__right">-->
<!--                <div class="block-info">-->
<!--                    <p class="block-info__title">{{__('web_page_deposit_block_info_title')}}</p>-->

<!--                    <p class="block-info__desc">{{__('web_page_deposit_block_info_desc')}}</p>-->

<!--                    <div class="block-notifications">-->
<!--                        <div class="block-notifications__item">-->
<!--                            <div class="dot"></div>-->
<!--                            <p>{{__('web_page_deposit_block_info_item_1')}}</p>-->
<!--                        </div>-->

<!--                        <div class="block-notifications__item">-->
<!--                            <div class="dot"></div>-->
<!--                            <p>{{__('web_page_deposit_block_info_item_2')}}</p>-->
<!--                        </div>-->

<!--                        <div class="block-notifications__item">-->
<!--                            <div class="dot"></div>-->
<!--                            <p>{{__('web_page_deposit_block_info_item_3')}}</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
    // import DotLoader from 'vue-spinner/src/DotLoader.vue';
    import DropdownDefault from "../modules/DropdownDefault";
    import VueQrcode from '@chenfengyuan/vue-qrcode';
    // import SearchDefault from "../modules/SearchDefault";
    export default {
        name: 'Deposit',

        data: function() {
            return {
                wallet: null,
                address: null,
                open: false,
                networks: [],
                network: null,
                isCopy: false,
                // wallet_name: null,
            }
        },

        watch:{
            wallet(val) {
                this.networks = this.walletsGroup[val.name];
                this.changeWallet(this.walletsGroup[val.name][0]);
            }
        },
        computed: {
            wallets() {
                return this.$store.getters.wallets
            },
            walletsGroup() {
                return this.$store.getters.walletsGroup
            },
            dropdownWallets() {
                let array = [];

                let walletsArray = Object.keys(this.walletsGroup);

                // let dropdownWalletsCopy = Object.keys(this.walletsGroup);

                // if (this.wallet_name) {
                //     walletsArray = dropdownWalletsCopy.filter((wallet) => {
                //         return wallet.toLowerCase().indexOf(this.wallet_name.toLowerCase()) > -1;
                //     });
                // }

                walletsArray.forEach((item, key) => {
                    let object = {
                        name: item,
                        id: key + 1,
                    };

                    array.push(object);
                });
                return array
            },
            wallet_list() {
                return this.$route.params.wallet_list;
            },
        },

        created() {
            if (this.wallet_list) {
                this.wallet = this.dropdownWallets.find(item => item.name == this.wallet_list[0].symbol);

            } else {
                this.wallet = this.dropdownWallets[0];
            }
        },

        methods: {
            changeWallet(wallet) {
                this.network = wallet;
                this.address= null;

                let response= {
                    wallet_id: this.network.id
                };

                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/address', response)
                    .then(response => {
                        return response.json()
                    })
                    .then((response) => {
                        this.address= response.data;
                    }).catch((err) => {
                    this.httpHandler(err);
                });
            },
            copyMessage() {
                this.isCopy = true;

                let body = document.getElementsByClassName('page-deposit')[0];
                let tempInput = document.createElement('INPUT');
                body.appendChild(tempInput);
                tempInput.setAttribute('value', this.address)
                tempInput.select();
                document.execCommand('copy');
                body.removeChild(tempInput);

                setTimeout(()=> {
                    this.isCopy = false;
                }, 2000);
            }
        },
        components: {
            DropdownDefault,
            VueQrcode,
            // DotLoader,
            // SearchDefault,
        }
    }
</script>

<style scoped>

</style>
