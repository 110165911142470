<template>
    <div class="page-dashboard">
        <div class="content">

            <div class="dashboard-top">
                <div class="top-all-p">
                    <div>
                        <p class="name">{{__('Welcome back,')}} {{user.name}}</p>
                        <span class="desc">{{__('Your overview')}}</span>
                    </div>
                    <div class="btn-block">
                        <router-link :to="{name: 'card_swap'}" class="btn-site mr-2">
                            <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M7.5 18.51C7.5 19.24 7.72001 19.9 8.13 20.47C5.45 20.3 3 19.32 3 17.51V16.63C4.05 17.45 5.6 18 7.5 18.18V18.51ZM7.53998 14.44C7.52998 14.45 7.53003 14.46 7.53003 14.47C7.51003 14.57 7.5 14.67 7.5 14.77V16.68C5.08 16.4 3 15.44 3 13.77V12.89C4.05 13.72 5.61003 14.27 7.53003 14.44H7.53998ZM11.44 10.78C9.92 11.25 8.73001 12.02 8.07001 12.98C5.41001 12.81 3 11.83 3 10.03V9.34998C4.31 10.38 6.41 10.98 9 10.98C9.87 10.98 10.69 10.91 11.44 10.78ZM15 9.34998V10.03C15 10.12 14.99 10.2 14.98 10.28C14.19 10.28 13.44 10.35 12.74 10.47C13.64 10.2 14.4 9.81998 15 9.34998ZM9 3.5C6 3.5 3 4.49999 3 6.48999C3 8.49999 6 9.47998 9 9.47998C12 9.47998 15 8.49999 15 6.48999C15 4.49999 12 3.5 9 3.5ZM15 19.26C12.49 19.26 10.35 18.6 9 17.53V18.51C9 20.5 12 21.5 15 21.5C18 21.5 21 20.5 21 18.51V17.53C19.65 18.6 17.51 19.26 15 19.26ZM15 11.78C11.69 11.78 9 13.12 9 14.77C9 16.42 11.69 17.76 15 17.76C18.31 17.76 21 16.42 21 14.77C21 13.12 18.31 11.78 15 11.78Z" fill="white"/>
                            </svg>
                            <p>{{__('Buy Crypto')}}</p>
                        </router-link>
                        <a class="btn-site" v-on:click="depositWindow = true">
                            <img src="/dist/images/icon-dep.svg" alt="">
                            <p>{{__('Deposit')}}</p>
                        </a>
                        <a class="btn-white" v-on:click="withdrawWindow = true">
                            <img src="/dist/images/icon-with.svg" alt="">
                            <p>{{__('Withdraw')}}</p>
                        </a>
                    </div>
                </div>
                <Chart></Chart>
            </div>

            <div class="block-markets">
                <div class="markets-head">
                    <div>
                        <p class="markets-head__title">{{__('Top market overview')}}</p>
<!--                        <span class="desc">{{__('Swiveling forbidden')}}</span>-->
                    </div>
                    <router-link
                        class="markets-head__link"
                        :to="{name: 'wallets'}"
                    >
                        {{__('Show All')}}

                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.8332 3.95835V11.875C15.8332 12.312 15.4794 12.6667 15.0416 12.6667C14.6038 12.6667 14.2499 12.312 14.2499 11.875V5.86949L5.30963 14.8098C5.15526 14.9641 4.95256 15.0417 4.7499 15.0417C4.54723 15.0417 4.34454 14.9641 4.19016 14.8098C3.88062 14.5002 3.88062 13.9998 4.19016 13.6903L13.1304 4.75002H7.1249C6.68711 4.75002 6.33323 4.39535 6.33323 3.95835C6.33323 3.52135 6.68711 3.16669 7.1249 3.16669H15.0416C15.1445 3.16669 15.2475 3.18799 15.344 3.22757C15.538 3.30753 15.6924 3.46192 15.7723 3.65587C15.8127 3.75246 15.8332 3.85544 15.8332 3.95835Z" fill="#6E56FA"/>
                        </svg>
                    </router-link>
                </div>

                <div class="markets-body">
                    <VueSlickCarousel v-bind="settings">

                        <div class="item-markets" v-for="(item, key) in walletsGroup" v-bind:key="key">

                            <div class="item-markets__left">
                                <div class="block-img">
                                    <img :src=item[0].img>
                                </div>

                                <div class="block-name">
                                    <p>{{item[0].symbol}}</p>
                                    <span>{{item[0].name | clearStr}}</span>
                                </div>
                            </div>

                            <div class="item-markets__right">
                                <div class="block-info">
                                    <p class="course">{{$primNum(courses[item[0].symbol] * user.course_current.course_current)}} {{user.course_current.course_ticker}}</p>
                                    <p class="percent" :class="(percentProfit(item[0].symbol) >= 0) ? 'pos' : 'neg'" :style="{color: (percentProfit(item[0].symbol) >= 0) ? '#01AA4F' : '#F51E38'}">





                                        {{percentProfit(item[0].symbol)}}%
                                        <svg v-if="percentProfit(item[0].symbol) >= 0" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.0496 3.85769C4.94405 3.96323 4.8794 4.1099 4.87961 4.27097L4.87961 4.34187C4.87961 4.66443 5.14074 4.92555 5.46288 4.92514L8.64993 4.92514L3.2587 10.3164C3.03101 10.5441 3.03101 10.9136 3.2587 11.1413C3.48639 11.369 3.85597 11.369 4.08366 11.1413L9.47488 5.7501L9.47488 8.93714C9.47488 9.2597 9.73601 9.52083 10.0582 9.52041L10.129 9.52041C10.4516 9.52041 10.7127 9.25929 10.7123 8.93714L10.7123 4.27097C10.7123 3.94842 10.4512 3.68729 10.129 3.6877L5.46288 3.6877C5.3016 3.6877 5.15514 3.75215 5.0496 3.85769Z" fill="#01AA4F"/>
                                        </svg>

                                        <svg v-else width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.9504 11.1423C9.05595 11.0368 9.12059 10.8901 9.12039 10.729L9.12039 10.6581C9.12039 10.3356 8.85926 10.0744 8.53712 10.0749L5.35007 10.0749L10.7413 4.68363C10.969 4.45594 10.969 4.08636 10.7413 3.85867C10.5136 3.63098 10.144 3.63098 9.91634 3.85867L4.52512 9.2499L4.52512 6.06286C4.52512 5.7403 4.26399 5.47917 3.94185 5.47959L3.87095 5.47959C3.54839 5.47959 3.28727 5.74071 3.28768 6.06286L3.28768 10.729C3.28768 11.0516 3.5488 11.3127 3.87095 11.3123L8.53712 11.3123C8.6984 11.3123 8.84486 11.2479 8.9504 11.1423Z" fill="#F51E38"/>
                                        </svg>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </VueSlickCarousel>
                </div>
            </div>

            <div class="block-body">
                <div class="balance">
                    <div class="top-all-p">
                        <div>
                            <p class="name">{{__('Your balances')}}</p>
<!--                            <span class="desc">{{__('Swiveling forbidden')}}</span>-->
                        </div>
                        <router-link
                                class="link"
                                :to="{name: 'wallets'}"
                        >
                            {{__('Show All')}}

                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.8332 3.95835V11.875C15.8332 12.312 15.4794 12.6667 15.0416 12.6667C14.6038 12.6667 14.2499 12.312 14.2499 11.875V5.86949L5.30963 14.8098C5.15526 14.9641 4.95256 15.0417 4.7499 15.0417C4.54723 15.0417 4.34454 14.9641 4.19016 14.8098C3.88062 14.5002 3.88062 13.9998 4.19016 13.6903L13.1304 4.75002H7.1249C6.68711 4.75002 6.33323 4.39535 6.33323 3.95835C6.33323 3.52135 6.68711 3.16669 7.1249 3.16669H15.0416C15.1445 3.16669 15.2475 3.18799 15.344 3.22757C15.538 3.30753 15.6924 3.46192 15.7723 3.65587C15.8127 3.75246 15.8332 3.85544 15.8332 3.95835Z" fill="#6E56FA"/>
                            </svg>
                        </router-link>
                    </div>
                    <ul v-if="Object.keys(wallets_list).length">
                      <DashboardWalletItem  v-bind:exchange.sync="exchange" v-for="(wallets,key) in wallets_list" v-bind:wallets="wallets" v-bind:wallet.sync="wallets" :key="key" v-bind:currency="key"></DashboardWalletItem>
                    </ul>
                </div>
                <div class="block-news">
                    <div class="news-head">
                        <div>
                            <p class="news-head__title">{{__('Crypto news')}}</p>
<!--                            <p class="desc">{{__('Swiveling forbidden')}}</p>-->
                        </div>
                    </div>

                    <div v-if="news && news.data.length" class="news-body">
                        <ScrollPagination v-bind:items.sync="news" type="get">
                            <NewsItem v-for="(item, key) in news.data" :item="item" :key="key"/>
                        </ScrollPagination>
                    </div>

                    <div v-else-if="news" class="news-body-empty">
                        <svg width="100" height="100" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M80 146.667H113.333C133.333 146.667 146.667 133.333 146.667 113.333V79.9998C146.667 61.9998 135.333 48.6665 118.667 46.6665C117.333 46.6665 115.333 46.6665 113.333 46.6665H46.6667C44.6667 46.6665 43.3334 46.6663 41.3334 47.333C24 49.333 13.3334 61.9998 13.3334 79.9998C13.3334 81.9998 13.3334 84.6665 13.3334 86.6665" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path opacity="0.4" d="M113.328 46.6681H46.6615C44.6615 46.6681 43.3281 46.668 41.3281 47.3346C41.9948 45.3346 43.3282 44.0014 45.3282 42.0014L66.6615 20.0015C75.9948 10.6681 90.6615 10.6681 99.9948 20.0015L111.995 32.0014C115.995 36.0014 117.995 41.3348 118.661 46.6681C117.328 46.6681 115.328 46.6681 113.328 46.6681Z" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path opacity="0.4" d="M146.667 83.3335H126.667C119.333 83.3335 113.333 89.3335 113.333 96.6668C113.333 104 119.333 110 126.667 110H146.667" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M59.9974 121.333C59.9974 109.533 50.4641 100 38.6641 100C26.8641 100 17.3307 109.533 17.3307 121.333C17.3307 133.133 26.8641 142.667 38.6641 142.667C50.4641 142.667 59.9974 133.133 59.9974 121.333Z" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.3334 146.667L20 140" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p class="news-body-empty__text">{{__('web_wallets_No result')}}</p>
                    </div>

                    <div v-else class="news-body-loading">
                        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        <p class="news-body-loading__text">{{__('Loading')}}</p>
                    </div>
                </div>
            </div>

        </div>

        <ModalDefault width="400px" v-if="depositWindow" v-bind:modal_cancel.sync="depositWindow">

            <div class="window-dep">
                <v-btn class="btn-close" v-ripple="false" text v-on:click="depositWindow = false">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.71209 3.71209C4.0782 3.34597 4.6718 3.34597 5.03791 3.71209L10 8.67418L14.9621 3.71209C15.3282 3.34597 15.9218 3.34597 16.2879 3.71209C16.654 4.0782 16.654 4.6718 16.2879 5.03791L11.3258 10L16.2879 14.9621C16.654 15.3282 16.654 15.9218 16.2879 16.2879C15.9218 16.654 15.3282 16.654 14.9621 16.2879L10 11.3258L5.03791 16.2879C4.6718 16.654 4.0782 16.654 3.71209 16.2879C3.34597 15.9218 3.34597 15.3282 3.71209 14.9621L8.67418 10L3.71209 5.03791C3.34597 4.6718 3.34597 4.0782 3.71209 3.71209Z" fill="#999999"/>
                    </svg>
                </v-btn>
                <Deposit></Deposit>
            </div>
        </ModalDefault>

        <ModalDefault width="400px" v-if="withdrawWindow" v-bind:modal_cancel.sync="withdrawWindow">
            <div class="window-dep">
                <v-btn class="btn-close" v-ripple="false" text v-on:click="withdrawWindow = false">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.71209 3.71209C4.0782 3.34597 4.6718 3.34597 5.03791 3.71209L10 8.67418L14.9621 3.71209C15.3282 3.34597 15.9218 3.34597 16.2879 3.71209C16.654 4.0782 16.654 4.6718 16.2879 5.03791L11.3258 10L16.2879 14.9621C16.654 15.3282 16.654 15.9218 16.2879 16.2879C15.9218 16.654 15.3282 16.654 14.9621 16.2879L10 11.3258L5.03791 16.2879C4.6718 16.654 4.0782 16.654 3.71209 16.2879C3.34597 15.9218 3.34597 15.3282 3.71209 14.9621L8.67418 10L3.71209 5.03791C3.34597 4.6718 3.34597 4.0782 3.71209 3.71209Z" fill="#999999"/>
                    </svg>
                </v-btn>
                <Withdraw v-bind:withdrawWindow.sync="withdrawWindow"></Withdraw>
            </div>
        </ModalDefault>

    </div>
</template>

<script>
    // import DotLoader from 'vue-spinner/src/DotLoader.vue';
    import VueSlickCarousel from "vue-slick-carousel";
    import "vue-slick-carousel/dist/vue-slick-carousel.css";
    import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
    import NewsItem from "./Dashboard/NewsItem";
    import DashboardWalletItem from "./Dashboard/DashboardWalletItem";
    import ScrollPagination from "../modules/ScrollPagination";
    import Chart from "../modules/Chart";
    import ModalDefault from "../modules/ModalDefault";
    import Deposit from "./Deposit";
    import Withdraw from "./Withdraw";
    import _ from 'lodash';

    export default {
        name: 'Dashboard',
        data () {
            return {
                hideZero: false,
                hide: 0,
                wallets_list: null,
                exchange: null,
                withdrawWindow: false,
                depositWindow: false,
                settings: {
                    "arrows":false,
                    "dots": false,
                    "infinite": true,
                    "autoplay": true,
                    "speed": 2000,
                    "autoplaySpeed": 2000,
                    "slidesToShow": 4,
                    "slidesToScroll": 1,
                    "initialSlide": 0,
                    "responsive": [
                        {
                            "breakpoint": 1030,
                            "settings": {
                                "slidesToShow": 2,
                                "slidesToScroll": 1
                            }
                        },
                        {
                            "breakpoint": 900,
                            "settings": {
                                "slidesToShow": 3,
                                "slidesToScroll": 1
                            }
                        },
                        {
                            "breakpoint": 770,
                            "settings": {
                                "slidesToShow": 2,
                                "slidesToScroll": 1
                            }
                        },
                        {
                            "breakpoint": 500,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1
                            }
                        }
                    ]
                },

                news: null,
                loadingPage: false,
                next_page_url: null,
                isLoading: false,
            }
        },

        computed: {
            user() {
                return this.$store.getters.user
            },
            wallets() {
                return this.$store.getters.wallets
            },
            walletsGroup() {
                return this.$store.getters.walletsGroup
            },
            courses() {
                return this.$store.getters.courses
            },
            charts() {
                return this.$store.getters.charts
            }
        },


        created() {
            this.$http.get('ajax/news')
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    response = response.data;
                    this.news = response;
                    this.next_page_url = response.next_page_url;
                }).catch((err) => {
                this.httpHandler(err);
            });

            this.$addListener(window,'scroll', () => {
                this.onScrollList();
            });


            this.walletsSend();
        },

        watch:{
            wallets() {
                this.walletsSend();
            }
        },
        methods: {
            walletsSend() {

                let wallets = this.$cleanObject(this.wallets);

                if(this.hideZero) {
                    _.remove(wallets, {'balance': 0});
                }


                this.hide = this.wallets.length - wallets.length;
                wallets = _.orderBy(wallets, item => item.balance * this.courses[item.symbol], ['desc']);
                let groupSymbol = _.groupBy(wallets, 'symbol');
                this.wallets_list= groupSymbol;
            },
            percentProfit(currency) {
                let chart_data = this.charts;
                if (chart_data) {
                    return ((chart_data.day.first[currency] - chart_data.day.last[currency])/(chart_data.day.first[currency] / 100)).toFixed(2);
                }
            },

            onScrollList() {
                const progress = document.documentElement.scrollTop / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
                if (!this.loadingPage && this.next_page_url) {
                    let bottomOfWindow = 0.7 <= progress;
                    if (bottomOfWindow) {
                        this.loadingPage = true;
                        this.$http[(process.env.NODE_ENV === 'production') ? 'get' : 'get'](this.next_page_url)
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                                response = response.data;
                                let news = this.$cleanObject(this.news);
                                news.data = news.data.concat(response.data);
                                this.next_page_url = response.next_page_url;
                                this.news = news;
                            })
                            .catch((err) => {
                                this.httpHandler(err);
                                this.loadingPage = false;
                            });
                    }
                }
            }
        },

        components: {
            Withdraw,
            Deposit,
            ModalDefault,
            Chart,
            // DotLoader,
            VueSlickCarousel,
            NewsItem,
            DashboardWalletItem,
            ScrollPagination
        }
    }
</script>

<style lang="scss">

</style>