import { render, staticRenderFns } from "./BusinessSignup.vue?vue&type=template&id=8cf44756&scoped=true&"
import script from "./BusinessSignup.vue?vue&type=script&lang=js&"
export * from "./BusinessSignup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8cf44756",
  null
  
)

export default component.exports