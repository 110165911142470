<template>
    <div class="item-employees">
        <div class="block-employee">
            <img class="block-employee__img" :src="(user.photo) ? user.photo : '/dist/assets/uploads/user/default.svg'"/>

            <div class="block-employee__info">
                <p>{{user.name}} {{user.last_name ? user.last_name : ''}}</p>

                <span>{{user.email}}</span>
            </div>
        </div>

        <div class="block-control">
            <v-switch
                v-model="isActive"
                color="success"
            ></v-switch>

            <v-btn
                v-ripple="false"
                text
                v-on:click="modalDeleteEmployee = true"
            >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 8C11.1046 8 12 8.89543 12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8Z" fill="currentColor"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 1C7.44772 1 7 1.44772 7 2V4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6H4.07397L5.00295 18.0767C5.04302 18.5977 5.47746 19 6 19H14C14.5225 19 14.957 18.5977 14.9971 18.0767L15.926 6H16C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H13V2C13 1.44772 12.5523 1 12 1H8ZM11 4V3H9V4H11ZM13.9201 6H6.07988L6.92603 17H13.074L13.9201 6Z" fill="currentColor"/>
                </svg>
            </v-btn>

            <v-btn
                v-ripple="false"
                text
            >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2929 1.29289C14.6834 0.902369 15.3166 0.902369 15.7071 1.29289L18.7071 4.29289C19.0977 4.68342 19.0977 5.31658 18.7071 5.70711L6.70713 17.7071C6.57897 17.8353 6.41839 17.9262 6.24256 17.9701L2.24256 18.9701C1.90178 19.0553 1.54129 18.9555 1.29291 18.7071C1.04453 18.4587 0.944685 18.0982 1.02988 17.7575L2.02988 13.7575C2.07384 13.5816 2.16476 13.4211 2.29291 13.2929L11.2926 4.29317L14.2929 1.29289ZM12 6.41421L5.41423 13L7.00002 14.5858L13.5858 8L12 6.41421ZM15 6.58579L13.4142 5L15 3.41421L16.5858 5L15 6.58579ZM5.58581 16L4.00002 14.4142L3.90299 14.5112L3.37439 16.6256L5.48877 16.097L5.58581 16Z" fill="currentColor"/>
                </svg>
            </v-btn>
        </div>

        <ModalDefault v-if="modalDeleteEmployee" width="470" v-bind:modal_cancel.sync="modalDeleteEmployee">
            <div class="modal-delete-employee">
                <div class="modal-delete-employee__head">
                    <p>{{__('web_modal_delete_employee_title')}}</p>
                </div>

                <div class="modal-delete-employee__body">
                    <p class="block-text">{{__('web_modal_delete_employee_text')}}</p>

                    <div class="block-employee">
                        <img class="block-employee__img" :src="(user.photo) ? user.photo : '/dist/assets/uploads/user/default.svg'"/>

                        <div class="block-employee__info">
                            <p>{{user.name}} {{user.last_name ? user.last_name : ''}}</p>

                            <span>{{user.email}}</span>
                        </div>
                    </div>
                </div>

                <div class="block-buttons">
                    <v-btn
                        style="margin-right: 16px"
                        class="btn-white"
                        v-on:click="modalDeleteEmployee = false"
                    >
                        {{__('web_btn_cancel')}}
                    </v-btn>

                    <v-btn
                        class="btn-white"
                        v-on:click="removeEmployee"
                        :loading="submitDisabled"
                    >
                        {{__('web_btn_remove')}}
                    </v-btn>

                </div>
            </div>
        </ModalDefault>
    </div>
</template>

<script>
    import ModalDefault from "../../modules/ModalDefault";
    export default {
        name: 'ItemEmployees',

        data: function() {
            return {
                modal: false,
                isActive: false,
                modalDeleteEmployee: false,
                submitDisabled: false,
            }
        },
        props: {
            // item: {
            //     type: Object
            // }
        },
        created() {
        },
        watch:{

        },
        computed: {
            user() {
                return this.$store.getters.user
            },
        },
        methods: {
            removeEmployee() {
                console.log('removeEmployee');
            }
        },
        components: {
            ModalDefault
        },
    }
</script>

<style>

</style>
