<template>
    <div class="personal-settings">
<!--        <div class="block-profile">-->
<!--            <div class="block-profile__img">-->
<!--                <UploadAvatar-->
<!--                        v-bind:post_params="{'type': 1}"-->
<!--                        v-bind:response.sync="response_upload"-->
<!--                        :url="'/ajax/setting/avatar'"-->
<!--                        style="background-size: cover"-->
<!--                >-->
<!--                    <img class="avatar" height="40" :src="(auth.photo) ? auth.photo : '/dist/assets/uploads/user/default.svg'"/>-->
<!--                </UploadAvatar>-->
<!--            </div>-->

<!--            <div class="block-profile__info">-->
<!--                <p class="block-name">{{auth.name}} {{auth.last_name ? auth.last_name : ''}}</p>-->

<!--                <span v-if="auth.verified == 2" class="status-verification" style="color: #01AA4F">-->
<!--                        {{__('web_dash_menu_Verificate')}}-->
<!--                    </span>-->

<!--                <span v-else-if="auth.verified == 1" class="status-verification" style="color: #FF9900">-->
<!--                        {{__('web_dash_menu_On verification')}}-->
<!--                    </span>-->

<!--                <span v-else class="status-verification">-->
<!--                        {{__('web_dash_menu_Not verified')}}-->
<!--                    </span>-->


<!--                <div v-if="isEdit" class="block-btns">-->
<!--                    <v-btn-->
<!--                        v-ripple="false"-->
<!--                        class="btn-site"-->
<!--                        style="height: 36px"-->
<!--                        v-on:click="onSubmit"-->
<!--                        :loading="submitDisabled"-->
<!--                    >-->
<!--                        {{__('web_btn_save_changes')}}-->
<!--                    </v-btn>-->

<!--                    <v-btn-->
<!--                        v-ripple="false"-->
<!--                        v-on:click="isEdit = !isEdit"-->
<!--                        class="btn-white"-->
<!--                    >-->
<!--                        {{__('web_btn_cancel')}}-->
<!--                    </v-btn>-->
<!--                </div>-->

<!--                <div v-else>-->
<!--                    <v-btn-->
<!--                        class="btn-edit"-->
<!--                        v-ripple="false"-->
<!--                        text-->
<!--                        v-on:click="isEdit = !isEdit"-->
<!--                    >-->
<!--                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                            <path d="M14.3817 1.61837C13.9851 1.2224 13.4476 1 12.8872 1C12.3268 1 11.7892 1.2224 11.3927 1.61837L1 12.011V15H3.989L14.3817 4.60737C14.7775 4.21072 14.9998 3.67324 14.9998 3.11287C14.9998 2.5525 14.7775 2.01502 14.3817 1.61837ZM3.50833 13.8334H2.16667V12.4917L9.93083 4.73337L11.2725 6.07504L3.50833 13.8334ZM13.5568 3.78254L12.0944 5.24495L10.7557 3.90329L12.2175 2.4432C12.3954 2.26529 12.6367 2.16534 12.8883 2.16534C13.1399 2.16534 13.3813 2.26529 13.5592 2.4432C13.7371 2.62112 13.837 2.86243 13.837 3.11404C13.837 3.36565 13.7371 3.60695 13.5592 3.78487L13.5568 3.78254Z" fill="currentColor"/>-->
<!--                        </svg>-->

<!--                        {{__('web_btn_edit_profile')}}-->
<!--                    </v-btn>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

        <div  class="list-edit">
            <div class="list-edit__item">
                <p class="name">{{__('Name')}}</p>
                <span class="desc">{{__('Full name')}}</span>
               <div class="block-input">
                   <InputLabelTextDefault
                           :placeholder="__('web_input_placeholder_enter_first_name')"
                           v-model="form.name"
                           v-bind:errors="formValidate.name"
                           @change="updateValidate('name')"
                   >
                   </InputLabelTextDefault>
               </div>
            </div>

<!--            <div class="list-edit__item">-->
<!--                <span>{{__('web_input_label_last_name')}}</span>-->
<!--                <div class="block-input">-->
<!--                    <InputLabelTextDefault-->
<!--                            color="small"-->
<!--                            :placeholder="__('web_input_placeholder_enter_last_name')"-->
<!--                            v-model="form.last_name"-->
<!--                            v-bind:errors="formValidate.last_name"-->
<!--                            @change="updateValidate('last_name')"-->
<!--                    >-->
<!--                    </InputLabelTextDefault>-->
<!--                </div>-->
<!--            </div>-->

            <div class="list-edit__item">
                <p class="name">{{__('Email')}}</p>
                <span class="desc">{{__('Your Email')}}</span>
                <div class="block-input">
                    <InputLabelTextDefault
                            disabled
                            v-model="auth.email"
                    >
                    </InputLabelTextDefault>
                </div>
            </div>

<!--            <div class="list-edit__item">-->
<!--                <span>{{__('web_input_label_default_currency')}}</span>-->
<!--                <div class="block-radio">-->
<!--                    <div class="block-radio__item">-->
<!--                        <input type="radio" id="eur" v-model="form.currency" value="EUR">-->
<!--                        <label for="eur">EUR</label>-->
<!--                    </div>-->

<!--                    <div class="block-radio__item">-->
<!--                        <input type="radio" id="usd" v-model="form.currency" value="USD">-->
<!--                        <label for="usd">USD</label>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

                            <div  class="block-btns">
                                <v-btn
                                    v-ripple="false"
                                    class="btn-site"
                                    v-on:click="onSubmit"
                                    :loading="submitDisabled"
                                >
                                    {{__('web_btn_save_changes')}}
                                </v-btn>

                                <v-btn
                                    v-ripple="false"
                                    v-on:click="isEdit = !isEdit"
                                    class="btn-white"
                                >
                                    {{__('web_btn_cancel')}}
                                </v-btn>
                            </div>
        </div>

<!--        <div v-else class="list-info">-->

<!--            <div class="list-info__item">-->
<!--                <span>{{__('web_input_label_first_name')}}</span>-->
<!--                <p>{{auth.name}}</p>-->
<!--            </div>-->

<!--            <div class="list-info__item">-->
<!--                <span>{{__('web_input_label_last_name')}}</span>-->
<!--                <p>{{auth.last_name ? auth.last_name : '-'}}</p>-->
<!--            </div>-->

<!--            <div class="list-info__item">-->
<!--                <span>{{__('web_input_label_email_address')}}</span>-->
<!--                <p>{{auth.email}}</p>-->
<!--            </div>-->

<!--            <div class="list-info__item">-->
<!--                <span>{{__('web_input_label_default_currency')}}</span>-->
<!--                <p>{{form.currency}}</p>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import {maxLength, minLength, required} from "vuelidate/lib/validators";
    // import UploadAvatar from "../../modules/UploadAvatar";
    export default {
        name: 'PersonalSetting',
        data: function() {
            return {
                isEdit: false,
                submitDisabled: false,
                response_upload: null,

                form: {
                    name: null,
                    last_name: null,
                    currency: null,

                },
                formValidate: {
                    name: null,
                    last_name: null,
                },
            }
        },
        validations: {
            form: {
                name: {
                    required,
                    maxLength: maxLength(50),
                    minLength: minLength(2),
                },
                last_name: {
                    // required,
                    maxLength: maxLength(50),
                    minLength: minLength(2),
                },
            },
        },
        watch: {
            response_upload: function (payload) {
                let auth = this.$cleanObject(this.auth);
                auth.photo = payload.logo;
                this.$store.dispatch("asyncUser", auth);
            },

        },
        computed: {
            auth() {
                return this.$store.getters.user
            },
        },
        created() {
            this.form.name = this.auth && this.auth.name ? this.auth.name : null;
            this.form.last_name = this.auth && this.auth.last_name ? this.auth.last_name : null;
            this.form.currency = this.auth && this.auth.course_current ? this.auth.course_current.course_ticker : 'EUR';
        },
        methods: {
            updateValidate: function(type, validate= false,  key= 'form') {
                if (validate) {
                    let formValidate = this.formValidate;
                    formValidate[type] = [];

                    if (!this.$v.form[type][validate]) {
                        let msg = this.__(this.$validateMessage[validate]);
                        formValidate[type].push(msg);

                    }

                    this.formValidate = formValidate;
                } else {
                    this.formValidate = this.$changeValidate(this.formValidate, this.$v[key][type], type);
                }
            },

            onSubmit() {
                let valid = this.$v.form;

                if (valid.$invalid) {
                    let massages = {
                        name: [],
                        last_name:[],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;
                    const response = this.form;

                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/setting/personal", response)

                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        if(response) {
                            this.submitDisabled = false;
                            this.isEdit = false;
                            // let auth = this.$cleanObject(this.auth);
                            // auth.name = this.form.name;
                            // auth.last_name = this.form.last_name;
                            // auth.currency = this.form.currency;
                            // this.$store.dispatch("asyncUser", auth);
                            this.$store.dispatch('asyncUser', response.data);
                        }
                    })
                    .catch((err) => {
                        this.submitDisabled = false;
                        let response = this.httpHandler(err);
                        if (response.error) {
                            this.formValidate = response.error.message;
                        }
                    });
                }
            },
        },
        components: {
            InputLabelTextDefault,
            // UploadAvatar
        }
    }
</script>


<style scoped>

</style>