<template>
  <div class="wrapper-office">
    <div class="office-content" v-if="wallets && user">
      <DashboardMenu></DashboardMenu>
      <div class="office-body">
        <HeaderDashboard></HeaderDashboard>
        <DashboardMenuMobile></DashboardMenuMobile>

        <router-view/>

        <div v-if="!user.google_2fa && this.$route.name !== 'ticket' && this.$route.name !== 'setting'" class="notice-2fa">
<!--          <div class="notice-2fa__icon">-->
<!--            <img src="/dist/images/notice-2fa-icon.svg" alt="">-->
<!--          </div>-->

          <div class="notice-2fa__content">
            <div class="notice-text">
              <p class="notice-text__title">{{__('web_security_settings_two_fa_title')}}</p>

              <p class="notice-text__desc">{{__('web_security_settings_two_fa_desc')}}</p>
            </div>

            <div class="block-button">
              <router-link
                      class="btn-site"
                      :to="{name: 'setting', params: {'isTwoFa': true}}"
              >
                {{__('web_btn_enable_2fa')}}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      {{__('Loading')}}
    </div>
  </div>
</template>

<script>
  // import _ from 'lodash';
  // import DotLoader from 'vue-spinner/src/DotLoader.vue'
  import DashboardMenu from './DashboardMenu'
  import HeaderDashboard from './HeaderDashboard';
  import DashboardMenuMobile from "./modules/DashboardMenuMobile";

  export default {
        data: function() {
            return {
            }
        },
        name: 'Office',
        computed: {
            wallets() {
                return this.$store.getters.wallets
            },
            user() {
                return this.$store.getters.user
            },
        },
        watch: {
        },
        methods: {
            // updateUser(transaction = false) {
            //     this.$http.get('ajax/wallets')
            //         .then(response => {
            //             return response.json()
            //         })
            //         .then(response => {
            //             response= response.data;
            //
            //             this.$store.dispatch('asyncWallets', response.wallets);
            //             this.$store.dispatch('asyncWalletsGroup', _.groupBy(response.wallets, 'symbol'));
            //             this.$store.dispatch('asyncCourses', response.courses);
            //
            //         }).catch((err) => {
            //         this.httpHandler(err);
            //     });
            //
            //     this.$http.get('ajax/user_info')
            //         .then(response => {
            //             return response.json()
            //         })
            //         .then(response => {
            //             this.$store.dispatch('asyncUser', response.data);
            //
            //             this.$store.dispatch('asyncResp', response.resp_tickets);
            //
            //             if(transaction) {
            //                 this.$store.dispatch('asyncTransaction', transaction);
            //             }
            //             this.$socket.emit('connect_socket', {
            //                 parent_id: this.user.parent_id
            //             });
            //
            //         }).catch((err) => {
            //         this.httpHandler(err);
            //     });
            // }
        },
        created() {
            // this.updateUser();
            //
            // this.$http.get('ajax/wallet/request/chart')
            //     .then(response => {
            //         return response.json()
            //     })
            //     .then(response => {
            //         this.$store.dispatch('asyncCharts', response.data);
            //
            //     }).catch((err) => {
            //     this.httpHandler(err);
            // });
            //
            // this.sockets.subscribe('updateTransaction', (data)=> {
            //     this.updateUser(data);
            // });
            //
            // this.sockets.subscribe('connect', ()=> {
            //     this.$socket.emit('connect_socket', {
            //         parent_id: (this.user) ? this.user.parent_id : null
            //     });
            // });
            //
            // this.sockets.subscribe('updateCourses', (data)=> {
            //     this.$store.dispatch('asyncCourses', data.courses);
            // });
            // this.sockets.subscribe('updateCharts', (data)=> {
            //
            //     this.$store.dispatch('asyncCharts', null);
            //     setTimeout(()=> {
            //         this.$store.dispatch('asyncCharts', data.charts)
            //     }, 2);
            // });
        },
        components: {
            // DotLoader,
            DashboardMenu,
            HeaderDashboard,
            DashboardMenuMobile,
        }
    }


</script>

<style scoped>

</style>
