<template>
  <div class="input-country">
    <v-autocomplete
      color="light-blue"
      dense
      :label="label"
      :placeholder="placeholder"
      :items="$countries"
      item-value="key"
      :filter="customFilter"
      @change="countryChange"
      @focusout="countryFocus"
      v-model="country"
    >
      <template v-slot:selection="data">
        <img class="mr-1 flag" width="14" :src="'/dist/images/flags/'+data.item.key.toLowerCase()+'.png'"/>
        {{data.item.value}}
      </template>

      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title>
              <img class="mr-1 flag" width="14" :src="'/dist/images/flags/'+data.item.key.toLowerCase()+'.png'"/>
              {{data.item.value}}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    <div>
      <p class="desc-error" v-for="(value, key) in errors" :key="key">{{value}}</p>
    </div>
    <img class="chevron-icon" src="/dist/images/chevron-down.svg" alt />
  </div>
</template>

<script>


export default {
  name: "DropdownCountry",
  data() {
    return {
        country: this.value
      // dropdown_font: ['English', 'Russian'],
    };
  },
  props: {
      value: {
          type: [String, Object]
      },
      errors: {
          type: Array
      },
      label: {
        type: String,
      },
      placeholder: {
        type: String,
      },
  },
  computed: {

  },
  watch: {
      country(val) {
          this.$emit('updateCountry', val);
      },
  },
  methods: {
    customFilter (item, queryText) {
        const textOne = item.value.toLowerCase();
        const searchText = queryText.toLowerCase();

        return textOne.indexOf(searchText) > -1
    },
    countryChange(change) {
        if(change) {
            this.country_select= change;
        }
    },
    countryFocus() {
        this.country= this.country_select;
    },
  },
};
</script>

<style lang="scss">

  .input-country .v-select--is-menu-active ~ .chevron-icon {
    transform: rotate(180deg);
    transition-duration: .3s;
  }

  .input-country {
    position: relative;
    background: #F8F8F8;
    border: 1px solid #F8F8F8;
    border-radius: 7px;
    height: 44px;

    .desc-error {
      position: absolute;
      bottom: -18px;
      left: 4px;
      margin: 0;
      font-size: 12px;
      line-height: 18px;
      color: #FF0000;
    }


    .chevron-icon {
      position: absolute;
      right: 12px;
      top: 14px;
      transition-duration: .3s;
    }
    .flag{
      display: none;
    }
    .v-input {

      input {
        max-height: 100%;
        border: none;
        padding: 0;
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000;
        background: #F8F8F8;

        &::placeholder {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: #999999;
          opacity: 1;
        }
      }

      &__control {
        border: none;


        .v-text-field__details {
          min-height: 0;
          border: none;
        }
      }

      &__slot {
        height: 44px;
        display: flex;
        align-items: center;
        padding: 0 12px 0 16px;


        &:before,
        &:after {
          border: none!important;
        }
      }


    }

    .v-text-field {
      margin: 0;
    }
  }
</style>