export default {
    state: {
        user: null,
    },
    getters: {
        user(state){
            return state.user;
        },
    },
    actions: {
        asyncUser(context, payload) {
            context.commit('updateUser', payload);
        }
    },
    mutations: {
        updateUser(state, data) {
            this.state.auth.user= data;
        }
    }
}