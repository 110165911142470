<template>
    <div class="wrapper-office">
        <div class="office-content" v-if="store && user">
            <DashboardMenu :isBusiness="true"></DashboardMenu>
            <div class="office-body">
                <HeaderDashboard></HeaderDashboard>
                <DashboardMenuMobile></DashboardMenuMobile>
                <router-view/>

                <div v-if="!user.google_2fa && this.$route.name !== 'business_ticket'" class="notice-2fa">
                    <div class="notice-2fa__icon">
<!--                        <img src="/dist/images/notice-2fa-icon.svg" alt="">-->
                    </div>

                    <div class="notice-2fa__content">
                        <div class="notice-text">
                            <p class="notice-text__title">{{__('web_security_settings_two_fa_title')}}</p>

                            <p class="notice-text__desc">{{__('web_security_settings_two_fa_desc')}}</p>
                        </div>

                        <div class="block-button">
                            <router-link
                                    class="btn-site"
                                    :to="{name: 'setting', params: {'isTwoFa': true}}"
                            >
                                {{__('web_btn_enable_2fa')}}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="loading">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
<!--            <DotLoader></DotLoader>-->
            {{__('Loading')}}
        </div>
    </div>
</template>

<script>
    // import DotLoader from 'vue-spinner/src/DotLoader.vue'
    import DashboardMenu from './DashboardMenu'
    import HeaderDashboard from './HeaderDashboard';
    import DashboardMenuMobile from "./modules/DashboardMenuMobile";

    export default {
        data: function() {
            return {
            }
        },
        name: 'Business',
        computed: {
            store() {
                return this.$store.getters.store
            },
            user() {
                return this.$store.getters.user
            },
        },
        watch: {},

        methods: {

        },
        created() {
            this.$http.get('ajax/store')
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    this.$store.dispatch('asyncStore', response.data);
                    if (!response.data.store) {
                        // this.$router.push({name: 'business_create'});
                    }
                }).catch((err) => {
                this.httpHandler(err);
            });
        },
        components: {
            // DotLoader,
            DashboardMenu,
            HeaderDashboard,
            DashboardMenuMobile,
        }
    }


</script>

<style scoped>

</style>
