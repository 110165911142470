<template>
    <div class="security-settings">

        <div class="block-password">
            <div class="modal-change-password">
                <div class="content" v-if="!modal_success">
                    <h2 class="content__title">{{__('web_security_settings_modal_password_title')}}</h2>
                    <div class="wrap-input">
                        <p class="wrap-input__label">{{__('web_input_label_old_password')}}</p>
                        <InputLabelTextDefault
                                type="password"
                                :placeholder="__('web_input_placeholder_current_password')"
                                class=""
                                v-model="form.current_password"
                                v-bind:errors="formValidate.current_password"
                                @change="updateValidate('current_password')"
                        ></InputLabelTextDefault>
                    </div>
                    <div class="block-pass">
                        <div class="wrap-input">
                            <p class="wrap-input__label">{{__('web_New password')}}</p>
                            <InputLabelTextDefault
                                    type="password"
                                    :placeholder="__('web_Enter new password')"
                                    class=""
                                    v-model="form.password"
                                    v-bind:errors="formValidate.password"
                                    @change="updateValidate('password'), updateValidate('new_password', 'sameAsPassword')"
                            ></InputLabelTextDefault>
                        </div>

                        <div class="wrap-input">
                            <p class="wrap-input__label">{{__('web_Confirm password')}}</p>
                            <InputLabelTextDefault
                                    type="password"
                                    :placeholder="__('web_Confirmation Password')"
                                    class=""
                                    v-model="form.new_password"
                                    v-bind:errors="formValidate.new_password"
                                    @change="updateValidate('new_password')"
                            ></InputLabelTextDefault>
                        </div>
                    </div>

                    <div class="block-btns">
                        <v-btn class="btn-site"  v-on:click="onSubmit" :loading="submitDisabled">{{__('web_btn_save_changes')}}</v-btn>
                        <v-btn class="btn-white"  v-on:click="isChangePassword = false">{{__('web_btn_cancel')}}</v-btn>
                    </div>
                </div>

                <div class="block-success" v-else>
                    <div class="block-success__icon">
                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M51.3334 28C51.3334 15.1667 40.8334 4.66666 28.0001 4.66666C15.1667 4.66666 4.66675 15.1667 4.66675 28C4.66675 40.8333 15.1667 51.3333 28.0001 51.3333C40.8334 51.3333 51.3334 40.8333 51.3334 28Z" stroke="#01AA4F" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M18.0833 28.0001L24.6866 34.6034L37.9166 21.3968" stroke="#01AA4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>

                    <h2 class="block-success__text">{{__('web_security_settings_modal_password_change_success')}}</h2>

                    <div class="btn-window">
                        <v-btn
                                class="btn-site"
                                style="min-width: 120px"
                                v-on:click="
                                isChangePassword = false,
                                modal_success = false
                            "
                        >
                            {{__('web_btn_ok')}}
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>

        <div class="block-2fa">

            <div class="block-2fa__content">
                <p class="name">2-Factor Authentication</p>
                <div class="block-enable">
<!--                    <p v-if="twoFaInfo.enable" class="block-enable__title">{{__('web_security_settings_two_fa_disable_title')}}</p>-->

<!--                    <p v-else class="block-enable__title">{{__('web_security_settings_two_fa_title')}}</p>-->

                    <p v-if="twoFaInfo.enable" class="block-enable__desc">{{__('web_security_settings_two_fa_disable_desc')}}</p>
                    <p v-else class="block-enable__desc">{{__('web_security_settings_two_fa_desc')}}</p>

                    <v-btn
                        v-if="twoFaInfo.enable"
                        v-ripple="false"
                        class="btn-red"
                        v-on:click="modal_2fa = true"
                    >
                        {{__('web_btn_disable_2fa')}}
                    </v-btn>
                    <v-btn
                        v-else
                        v-ripple="false"
                        class="btn-site"
                        v-on:click="modal_2fa = true"
                    >
                        {{__('web_btn_enable_2fa')}}
                    </v-btn>
                </div>
            </div>
        </div>




<!--        <ModalDefault v-if="isChangePassword" width="470" v-bind:modal_cancel.sync="isChangePassword">-->

<!--        </ModalDefault>-->


        <ModalDefault v-if="modal_2fa" :width="(twoFaInfo.enable) ? '470' : '540'" v-bind:modal_cancel.sync="modal_2fa">
            <div class="modal-2fa">
                <h2 class="modal-2fa__title">{{__((twoFaInfo.enable) ? 'web_security_settings_modal_2fa_disable_title' : 'web_security_settings_modal_2fa_enable_title')}}</h2>

                <div class="content-disable" v-if="twoFaInfo.enable">

                    <div class="wrap-input">
                        <p class="wrap-input__label">{{__('web_Enter the 6-digit key for disable 2FA')}}</p>

                        <InputLabelTextDefault
                            type="number"
                            :placeholder="__('web_Enter 6-digit key')"
                            class="mt-3" value="form.email"
                            v-model="secret.two_fa"
                            v-bind:errors="secretValidate.two_fa"
                            @change="updateValidate('two_fa', false,'secret')"
                        ></InputLabelTextDefault>

                    </div>

                    <div class="block-btns">
                        <v-btn class="btn-white" style="height: 44px" v-on:click="modal_2fa = false">{{__('web_btn_cancel')}}</v-btn>
                        <v-btn class="btn-red" style="height: 44px" v-on:click="onSecurity" :loading="submitDisabled">{{__('web_btn_disable_2fa')}}</v-btn>
                    </div>
                </div>
                <div class="content-enable" v-else>

                    <p class="content-enable__text">{{__('web_security_settings_modal_2fa_enable_text')}}</p>

                    <div class="content-enable__qr">
                        <div class="block-qr">
                            <VueQrcode :value="'otpauth://totp/'+$APP_NAME+':'+auth.email+'?secret='+twoFaInfo.secret+'&issuer='+$APP_NAME+'&algorithm=SHA1&digits=6&period=30'" :options="{ width: 180, margin: 1 }"/>
                        </div>

                        <p>{{__('web_security_settings_modal_2fa_enable_block_qr_text')}}</p>



                        <div class="block-secret">
                            <div class="block-copy" v-if="isCopy">
                                <img src="/dist/images/img-copy.svg" alt="">
                                <p>{{__('web_text_copied')}}</p>
                            </div>
<!--                            <p class="block-code" v-else>{{twoFaInfo.secret}}</p>-->
                            <input v-else type="text" class="block-code" :value="twoFaInfo.secret" ref="myInput" readonly>

                            <v-btn
                                v-ripple="false"
                                class="btn-copy"
                                text
                                v-on:click="copyMessage"
                            >
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.5 14.9998C7.04167 14.9998 6.64944 14.8368 6.32333 14.5107C5.99667 14.184 5.83333 13.7915 5.83333 13.3332V3.33317C5.83333 2.87484 5.99667 2.48234 6.32333 2.15567C6.64944 1.82956 7.04167 1.6665 7.5 1.6665H15C15.4583 1.6665 15.8508 1.82956 16.1775 2.15567C16.5036 2.48234 16.6667 2.87484 16.6667 3.33317V13.3332C16.6667 13.7915 16.5036 14.184 16.1775 14.5107C15.8508 14.8368 15.4583 14.9998 15 14.9998H7.5ZM7.5 13.3332H15V3.33317H7.5V13.3332ZM2.5 12.4998H4.16667V10.8332H2.5V12.4998ZM2.5 9.58317H4.16667V7.9165H2.5V9.58317ZM8.33333 18.3332H10V16.6665H8.33333V18.3332ZM2.5 15.4165H4.16667V13.7498H2.5V15.4165ZM4.16667 18.3332V16.6665H2.5C2.5 17.1248 2.66306 17.5173 2.98917 17.844C3.31583 18.1701 3.70833 18.3332 4.16667 18.3332ZM5.41667 18.3332H7.08333V16.6665H5.41667V18.3332ZM11.25 18.3332C11.7083 18.3332 12.1008 18.1701 12.4275 17.844C12.7536 17.5173 12.9167 17.1248 12.9167 16.6665H11.25V18.3332ZM2.5 6.6665H4.16667V4.99984C3.70833 4.99984 3.31583 5.16317 2.98917 5.48984C2.66306 5.81595 2.5 6.20817 2.5 6.6665Z" fill="currentColor"/>
                                </svg>
                                {{__('web_btn_copy_code')}}
                            </v-btn>
                        </div>
                    </div>

                    <div class="wrap-input">
                        <InputLabelTextDefault
                                type="number"
                                :placeholder="__('web_Enter 6-digit code provided by app')"
                                value="form.email"
                                v-model="secret.two_fa"
                                v-bind:errors="secretValidate.two_fa"
                                @change="updateValidate('two_fa', false,'secret')"
                        ></InputLabelTextDefault>
                    </div>
                    <div class="block-btns">
                        <v-btn class="btn-white"  v-on:click="modal_2fa = false">{{__('web_btn_cancel')}}</v-btn>
                        <v-btn class="btn-site" v-on:click="onSecurity" :loading="submitDisabled">{{__('web_btn_enable_2fa')}}</v-btn>
                    </div>
                </div>
            </div>
        </ModalDefault>
    </div>
</template>

<script>
    import {sameAs, required, numeric, maxLength, minLength} from "vuelidate/lib/validators";
    import ModalDefault from "../../modules/ModalDefault";
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import VueQrcode from '@chenfengyuan/vue-qrcode';
    export default {
        name: 'SecuritySetting',
        data: function() {
            return {
                form: {
                    password: null,
                    new_password: null,
                    current_password: null,
                },
                formValidate: {
                    password: null,
                    new_password: null,
                    current_password: null,
                },
                submitDisabled: false,
                modal_success: false,
                isChangePassword: false,

                modal_2fa: false,
                secret: {
                    two_fa: null,
                },
                secretValidate: {
                    two_fa: null
                },
                isCopy: false
            }
        },
        props: {
            twoFaInfo: {
                type: Object,
            },
            isTwoFa: {
                type: Boolean,
            }
        },
        validations: {
            form: {
                current_password: {
                    required
                },
                password: {
                    required
                },
                new_password: {
                    required,
                    sameAsPassword: sameAs(function() {
                        if(this.form.new_password && this.form.new_password.length) {
                            return (this.form.new_password== this.form.password) ? this.form.new_password : false;
                        } else {
                            return this.form.new_password;
                        }
                    }),
                },
            },

            secret: {
                two_fa: {
                    required,
                    numeric,
                    maxLength: maxLength(6),
                    minLength: minLength(6),
                }
            }

        },

        watch: {
            isChangePassword(val) {
                if (!val) {
                    this.form = {
                        password: null,
                        new_password: null,
                        current_password: null,
                    };

                    this.formValidate = {
                        password: null,
                        new_password: null,
                        current_password: null,
                        code: null
                    }
                }
            }
        },
        computed: {
            auth() {
                return this.$store.getters.user
            },
        },
        created() {
            if (this.isTwoFa) {
                this.modal_2fa = true;
            }
        },
        methods: {
            updateValidate: function(type, validate = false,  key = 'form') {
                if (validate) {

                    let formValidate = ((key == 'form') ?  this.formValidate : this.secretValidate);
                    formValidate[type] = [];

                    if (!this.$v[key][type][validate]) {
                        let msg = this.__(this.$validateMessage[validate]);
                        formValidate[type].push(msg);
                    }
                    if (key == 'form') {
                        this.formValidate = formValidate;
                    } else {
                        this.secretValidate = formValidate;
                    }
                } else {
                    if (key == 'form') {
                        this.formValidate = this.$changeValidate(this.formValidate, this.$v[key][type], type);
                    } else {
                        this.secretValidate = this.$changeValidate(this.secretValidate, this.$v[key][type], type);
                    }
                }
            },

            onSubmit: function () {
                let valid= this.$v.form;

                if(valid.$invalid) {
                    let massages= {
                        'password': [],
                        'current_password': [],
                        'new_password': [],
                    };
                    this.formValidate= this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled= true;
                    let response= {
                        'password': this.form.password,
                        'password_confirmation': this.form.new_password,
                        'current_password': this.form.current_password,
                    };

                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/setting/password', response)
                        .then(response=> {
                            return response.json()
                        })
                        .then((response) => {
                            this.submitDisabled= false;

                            response = response.data;

                            if (response) {
                                this.modal_success = true;
                            }
                            this.form = {
                                password: null,
                                new_password: null,
                                current_password: null,
                            };
                        }).catch(err => {
                            this.submitDisabled= false;
                            let response= this.httpHandler(err);
                            if(response.error) {
                                this.formValidate = response.error.message;
                            }
                    });
                }
            },

            onSecurity: function () {
                let valid= this.$v.secret;

                if(valid.$invalid) {
                    let massages = {
                        'two_fa': [],
                    };
                    this.formValidate= this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled= true;
                    let response= {
                        'code': this.secret.two_fa
                    };

                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/setting/security/2fa', response)
                        .then(response=> {
                            return response.json()
                        })
                        .then((response) => {
                            this.modal_2fa = false;
                            let twoFa_info = this.$cleanObject(this.twoFaInfo);
                            twoFa_info.enable= response.data.enable;
                            this.$emit('update:twoFaInfo', twoFa_info);
                            let auth = this.$cleanObject(this.auth);
                            auth.google_2fa = response.data.enable;
                            this.$store.dispatch("asyncUser", auth);
                            this.secret.two_fa = null;
                            this.submitDisabled= false;
                        }).catch(err => {
                            this.submitDisabled= false;
                            let response = this.httpHandler(err);
                            if(response.error) {
                                this.secretValidate.two_fa = response.error.message.code;

                            }
                    });
                }
            },

            copyMessage() {
                let copyText = this.$refs.myInput;
                copyText.select();
                document.execCommand("copy");
                this.isCopy = true;
                setTimeout(()=> {
                    this.isCopy = false;
                }, 2000);
            }
        },
        components: {
            ModalDefault,
            InputLabelTextDefault,
            VueQrcode
        }
    }
</script>


<style scoped>

</style>