<template>
    <div class="wrap-verification">
        <div class="wrap-verification__head">
            <v-btn v-ripple="false" text icon class="icon" @click="modalCancel">
                <img src="/dist/images/close.svg" alt="/" />
            </v-btn>
            <h2 class="verification-title">Verify your identity
<!--                {{this.form.type}}-->
            </h2>
            <p class="desc">Transfiguration lion prince minister werewolf banquet nearly-headless hippogriff. You-know-who nagini.</p>

            <div class="grid-wrapper">
                <div class="block-step">
                    <div class="block-step__head">
                        <div class="block-num block-num--completed">1</div>
<!--                        <div class="block-line block-line&#45;&#45;completed"></div>-->
                    </div>

                    <div class="block-step__body">
<!--                        <span class="block-headline">{{__('web_ver_Step 1')}}</span>-->
                        <p class="block-title">{{__('web_ver_Personal info')}}</p>
<!--                        <span class="block-status block-status&#45;&#45;completed">{{__('web_ver_Completed')}}</span>-->
                    </div>
                </div>

                <div class="block-step block-step-inProgress">
                    <div class="block-step__head">
                        <div class="block-num block-num--inProgress">2</div>
<!--                        <div class="block-line block-line&#45;&#45;inProgress"></div>-->
                    </div>

                    <div class="block-step__body">
<!--                        <span class="block-headline">{{__('web_ver_Step 2')}}</span>-->
                        <p class="block-title">{{__('web_ver_Document')}}</p>
<!--                        <span class="block-status block-status&#45;&#45;inProgress">{{__('web_ver_In Progress')}}</span>-->
                    </div>
                </div>

                <div class="block-step">
                    <div class="block-step__head">
                        <div class="block-num block-num--pending">3</div>
                    </div>

                    <div class="block-step__body">
<!--                        <span class="block-headline">{{__('web_ver_Step 3')}}</span>-->
                        <p class="block-title">{{__('web_ver_Photo upload')}}</p>
<!--                        <span class="block-status block-status&#45;&#45;pending">{{__('web_ver_Pending')}}</span>-->
                    </div>
                </div>

            </div>

        </div>

        <div class="wrap-verification__body">
            <p class="caption">{{__('web_ver_pers_Fill your personal info to continue verification')}}</p>
            <div class="block-change-document">
                <p class="caption">{{__('web_ver_doc_Verificate with')}}</p>

                <div class="block-radio">
                    <div class="block-radio__item">
                        <input type="radio" id="card" v-model="form.type" value="GOVERNMENT_ID">
                        <label for="card">{{__('web_ver_doc_ID card')}}</label>
                    </div>

                    <div class="block-radio__item">
                        <input type="radio" id="passport" v-model="form.type" value="PASSPORT">
                        <label for="passport">{{__('web_ver_doc_Passport')}}</label>
                    </div>

                    <div class="block-radio__item">
                        <input type="radio" id="drive" v-model="form.type" value="DRIVERS_LICENSE">
                        <label for="drive">{{__('web_ver_doc_Driver license')}}</label>
                    </div>

                    <div class="block-radio__item">
                        <input type="radio" id="government" v-model="form.type" value="DOMESTIC_PASSPORT">
                        <label for="government">{{__('web_ver_doc_Domestic passport')}}</label>
                    </div>
                </div>

            </div>

            <p class="caption">{{__('web_ver_doc_Fill next data')}}</p>

            <div class="wrapper-input">
                <span class="wrapper-input__title">{{__('web_ver_doc_Document number')}}</span>

                <InputLabelTextDefault
                        :placeholder="__('web_ver_doc_Enter document number')"
                        color="main"
                        v-model="form.document_number"
                        v-bind:errors="formValidate.document_number"
                        @change="updateValidate('document_number')"
                ></InputLabelTextDefault>
            </div>

            <div class="wrapper-input">
                <span class="wrapper-input__title">{{__('web_ver_doc_Issuing authority')}}</span>

                <InputLabelTextDefault
                        :placeholder="__('web_ver_doc_Enter issuing authority')"
                        color="main"
                        v-model="form.issuing_authority"
                        v-bind:errors="formValidate.issuing_authority"
                        @change="updateValidate('issuing_authority')"
                ></InputLabelTextDefault>
            </div>


            <div class="wrapper-input">
                <span class="wrapper-input__title">{{__('web_ver_doc_Issue date document')}}</span>

                <date-picker
                    v-model="form.issue_date"
                    :placeholder="__('web_placeholder_DD.MM.YYYY')"
                    :append-to-body="false"
                    value-type="YYYY-MM-DD"
                    format="DD.MM.YYYY"
                    :editable="true"
                    :clearable="true"
                    prefix-class="mx"
                    input-class="datepicker"
                    @open="formValidate.issue_date = null"
                    @close="updateValidate('issue_date')"
                    @change="updateValidate('issue_date')"
                    @focus="formValidate.issue_date = null"
                    v-mask="'##.##.####'"
                >
                    <template #icon-calendar>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.75 7.53754V13.5C15.75 15 15 15.75 13.5 15.75H4.5C3 15.75 2.25 15 2.25 13.5V7.53754C2.25 7.41304 2.35054 7.3125 2.47504 7.3125H15.525C15.6495 7.3125 15.75 7.41304 15.75 7.53754ZM13.5 3.375H12.5625V2.25C12.5625 1.9425 12.3075 1.6875 12 1.6875C11.6925 1.6875 11.4375 1.9425 11.4375 2.25V3.375H6.5625V2.25C6.5625 1.9425 6.3075 1.6875 6 1.6875C5.6925 1.6875 5.4375 1.9425 5.4375 2.25V3.375H4.5C3 3.375 2.25 4.125 2.25 5.625V5.96246C2.25 6.08696 2.35054 6.1875 2.47504 6.1875H15.525C15.6495 6.1875 15.75 6.08696 15.75 5.96246V5.625C15.75 4.125 15 3.375 13.5 3.375Z" fill="black"/>
                        </svg>

                    </template>
                </date-picker>

                <p class="wrapper-input__error" v-if="formValidate.issue_date && formValidate.issue_date.length">{{formValidate.issue_date[0]}}</p>
            </div>

            <div class="wrapper-input">
                <span class="wrapper-input__title">{{__('web_ver_doc_Expiry date document')}}</span>

                <date-picker
                    v-model="form.expiry_date"
                    :placeholder="__('web_placeholder_DD.MM.YYYY')"
                    :append-to-body="false"
                    value-type="YYYY-MM-DD"
                    format="DD.MM.YYYY"
                    :editable="true"
                    :clearable="true"
                    prefix-class="mx"
                    input-class="datepicker"
                    @open="formValidate.expiry_date = null"
                    @close="updateValidate('expiry_date')"
                    @change="updateValidate('expiry_date')"
                    @focus="formValidate.expiry_date = null"
                    v-mask="'##.##.####'"
                >
                    <template #icon-calendar>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.75 7.53754V13.5C15.75 15 15 15.75 13.5 15.75H4.5C3 15.75 2.25 15 2.25 13.5V7.53754C2.25 7.41304 2.35054 7.3125 2.47504 7.3125H15.525C15.6495 7.3125 15.75 7.41304 15.75 7.53754ZM13.5 3.375H12.5625V2.25C12.5625 1.9425 12.3075 1.6875 12 1.6875C11.6925 1.6875 11.4375 1.9425 11.4375 2.25V3.375H6.5625V2.25C6.5625 1.9425 6.3075 1.6875 6 1.6875C5.6925 1.6875 5.4375 1.9425 5.4375 2.25V3.375H4.5C3 3.375 2.25 4.125 2.25 5.625V5.96246C2.25 6.08696 2.35054 6.1875 2.47504 6.1875H15.525C15.6495 6.1875 15.75 6.08696 15.75 5.96246V5.625C15.75 4.125 15 3.375 13.5 3.375Z" fill="black"/>
                        </svg>

                    </template>
                </date-picker>

                <p class="wrapper-input__error" v-if="formValidate.expiry_date && formValidate.expiry_date.length">{{formValidate.expiry_date[0]}}</p>
            </div>
        </div>

        <div class="wrap-verification__bottom">
            <v-btn
                class="btn-white"
                v-on:click="$emit('update:step', 1)"
            >
                <span>
                  {{__('web_ver_Back')}}
                </span>
            </v-btn>

            <v-btn
                class="btn-site"
                :loading="submitDisabled"
                v-on:click="onSubmit"
            >
                <span>
                  {{__('web_ver_Continue')}}
                </span>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    // import moment from "moment";
    import {maxLength, minLength, required} from "vuelidate/lib/validators";
    // import {mask} from 'vue-the-mask';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import(`vue2-datepicker/locale/${localStorage.language ? localStorage.language : 'en'}`);
    import {mask} from 'vue-the-mask';

    export default {
        name: 'BlockVerificationDocumentInfo',
        data: function () {
            return {
                // locale: { lang: this.user.lang },
                submitDisabled: false,
                form: {
                    type: this.formVerificationDocument.type ? this.formVerificationDocument.type : 'PASSPORT',
                    document_number: this.formVerificationDocument.document_number ? this.formVerificationDocument.document_number : null,
                    issuing_authority: this.formVerificationDocument.issuing_authority ? this.formVerificationDocument.issuing_authority : null,
                    issue_date: this.formVerificationDocument.issue_date ? this.formVerificationDocument.issue_date : null,
                    expiry_date: this.formVerificationDocument.expiry_date ? this.formVerificationDocument.expiry_date : null,
                },

                formValidate: {
                    document_number: null,
                    issuing_authority: null,
                    issue_date: null,
                    expiry_date: null,
                },
            }
        },

        validations: {
            form: {
                document_number: {
                    required,
                    maxLength: maxLength(50),
                    minLength: minLength(2),
                },
                issuing_authority: {
                    required,
                    maxLength: maxLength(255),
                    minLength: minLength(2),
                },
                issue_date: {
                    required,
                },
                expiry_date: {
                    required
                }
            }
        },
        props: {
            step: {
                type: Number
            },
            formVerificationDocument: {
                type: Object
            }
        },

        watch: {

        },

        computed: {
            user() {
                return this.$store.getters.user
            },
            lang() {
                return this.$store.state.language.lang;
            },
        },

        created() {

        },
        methods: {
            updateValidate (type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v.form[type], type);
            },

            modalCancel() {
                this.$emit('modalCancel')
            },

            onSubmit() {
                let valid = this.$v.form;

                if (valid.$invalid) {
                    let massages = {
                        document_number: [],
                        issuing_authority: [],
                        issue_date: [],
                        expiry_date: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);

                    const keys = Object.keys(this.formValidate);
                    let itemError = null;
                    keys.forEach((key) => {
                        if (this.formValidate[key].length) {
                            itemError = key;
                        }
                    });
                    console.log('itemError', itemError);
                    // if (itemError) {
                    //     this.$refs[itemError].scrollIntoView({block: "center", behavior: "smooth"});
                    // }

                } else {
                    this.$emit('update:step', 3);
                    let form_document = this.$cleanObject(this.formVerificationDocument);
                    form_document.type = this.form.type;
                    form_document.document_number = this.form.document_number;
                    form_document.issuing_authority = this.form.issuing_authority;
                    form_document.issue_date = this.form.issue_date;
                    form_document.expiry_date = this.form.expiry_date;

                    this.$emit('update:formVerificationDocument', form_document);
                }
            },



        },
        components: {
            InputLabelTextDefault,
            DatePicker
        },
        directives: {mask}
    }
</script>

<style>

</style>
