<template>
  <div v-if="loading">
    loading
  </div>
  <div style="background:transparent;" class="wrap-languages" v-else>
    <v-menu offset-y left top v-if="languages && active_language" v-model="languages_menu">
      <template v-slot:activator="{ on }">
        <div class="block-lang" v-on="on">
<!--            <img class="block-lang__img" :src="`/dist/images/flags/${active_language.lang}.png`" />-->
            <p class="block-lang__text">{{active_language.lang}}</p>
            <img
                    :class="((languages_menu) ? 'active' : '')"
                    class="chevron"
                    src="/dist/images/down-leng.svg"
                    alt
            />
        </div>
      </template>
      <div class="block-all-languages">
        <div
                class="block-all-languages__item"
                v-for="(language, key) in languages"
                :key="key"
                v-on:click="selectLanguages(language.lang)"
                :class="(language.lang == active_language.lang) ? 'd-none' : ''"
        >
          <img :src="'/dist/images/flags/'+language.lang+'.png'"/>

          <p>{{language.full_lang}}</p>
        </div>
      </div>
    </v-menu>
    <div v-else class="loading"></div>
  </div>
  <!-- <v-app  id="inspire">-->
  <!--      <v-overflow-btn-->
  <!--              class="dropdown"-->
  <!--              :items="languagesDropdown"-->
  <!--              target="#dropdown-example"-->
  <!--              v-model="active_languages"-->
  <!--              label="English"-->
  <!--              input-value="en"-->
  <!--      >-->
  <!--      </v-overflow-btn>-->
  <!--    </v-app> -->
</template>

<script>
    export default {
        name: "DropdownLanguages",
        data() {
            return {
                languages_menu: false,
                active_languages: null,
                loading: false,
                // dropdown_font: ["English", "Russian"],
            };
        },
        computed: {
            languages() {
                return this.$store.state.language.list;
            },
        },
        asyncComputed: {
            active_language() {
                let language = this.$store.state.language.lang,
                    languages = this.languages;

                if (language && languages) {
                    return this.getLanguage(language);
                } else {
                    return null;
                }
            },
        },
      components:{
      },
        methods: {
            selectLanguages: function (event) {
                let response= {
                    lang: event
                };
                // this.loading= true;
                localStorage.language= event;
                this.$store.commit("updateLanguage", event);
                this.languages_menu = false;
                this.$http
                    .post("ajax/set_locale", response)
                    .then((response) => {
                        return response.json();
                    })
                    .then(() => {
                        // this.loading= false;
                    })
                    .catch((err) => {
                        // this.loading= false;
                        this.httpHandler(err);
                    });




            },
            getLanguage: function (event) {
                let languages = this.languages;

                let language = languages.filter((item) => {
                    return item.lang == event;
                });
                return language.length ? language[0] : languages[0];
            },
        },
    };
</script>