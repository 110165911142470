<template>
  <div>
    <div :class="`${(color) ? 'input_'+color : ''} ${(errors && errors.length) ? 'errors' : ''}`" class="input-group d-flex">
      <div class="label-site label-left gray" ref="label" :class="(value) ? 'label_top' : ''" v-if="icon || label">
        <img v-if="icon && posIcon=='left'"  :src="icon"/>
        <span v-if="posLabel=='left' && label">{{__(label)}}</span>
      </div>
        <slot name="left"></slot>
      <span v-if="posLabel=='left' && label">{{__(label)}}</span>
      <textarea
        v-if="type=='textarea'"
        :placeholder="placeholder"
        class="textarea"
        :disabled="disabled"
        :readonly="readonly"
        :type="input_type"
        ref="input"
        v-bind="$attrs"
        v-on="inputListeners"
        style="width: 100%"
        v-bind:value="value"
        :maxlength="maxlength"
      ></textarea>
      <input
        v-else
        :label="label"
        :placeholder="placeholder"
        class="pt-0 input "
        :disabled="disabled"
        :readonly="readonly"
        :type="input_type"
        ref="input"
        v-bind:value="value"
        v-bind="$attrs"
        v-on="inputListeners"
        style="width: 100%"
        :maxlength="maxlength"
      />
      <span
        class="btn-white"
        v-ripple="{ class:'secondary--text'}"
        small
        v-if="copy"
        @click="copyText"
      >
        <img src="/dist/images/copy.svg" alt="">
        <span>{{__((typeof copy === 'boolean') ? ('') : (copy))}}</span>
      </span>

      <slot name="label">
        <div class="label" v-if="icon || label">
          <img v-if="icon && posIcon=='right'" :src="icon"/>
          <span v-if="posLabel=='right' && label">{{__(label)}}</span>
        </div>
      </slot>
      <slot name="right"></slot>
      <button
              class="btn-password"
              ref="eye"
              v-ripple="{ class:'secondary--text'}"
              text
              small
              v-if="switchType"
              @click="openPassword"

      ></button>
      <div class="block-error">
        <p class="block-error__text" v-if="errors">{{__(errors[0])}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputLabelTextDefault",
  data() {
    return {
      input_type: this.type,
    };
  },
  computed: {
    inputListeners: function () {
      var vm = this;
      let valueLength= this.valueLength;

      // `Object.assign` объединяет объекты вместе, чтобы получить новый объект
      return Object.assign(
        {},
        // Мы добавляем все слушатели из родителя
        this.$listeners,
        // Затем мы можем добавить собственные слушатели или
        // перезаписать поведение некоторых существующих.
        {
          // Это обеспечит, что будет работать v-model на компоненте
          input: function (event) {
            let value= String(event.target.value);
            if(valueLength) {
                value= value.replace(",",".");
                value= ((value.indexOf(".") >= 0) ? (value.substr(0, value.indexOf(".")) + value.substr(value.indexOf("."), Number(valueLength)+1)) : value);
            }
            vm.$emit("input", value);
          },
        }
      );
    },
  },
    watch: {
    focus: function (val) {
      if (val) {
        this.$emit("update:focus", false);
        this.$refs.input.focus();
      }
    },
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    copy: {
      type: [Boolean, String],
      default: false,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    color: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
    errors: {
      type: Array,
    },
    posIcon: {
        type: String,
        default: "left",
    },
    switchType: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    posLabel: {
      type: String,
      default: "right",
    },
    focus: {
      type: Boolean,
    },
    valueLength: {
      type: [String, Number],
    },
    maxlength: {
        type: [Number, String],
        default: null
    },
  },
  methods: {
    copyText: function () {
      // /* Get the text field */
      var copyText = this.$refs.input;
      /* Select the text field */
      copyText.select();

      /* Copy the text inside the text field */
      document.execCommand("copy");
    },
      openPassword: function () {
          this.$refs.eye.classList.toggle('active');
          this.input_type= (this.input_type== 'password') ? 'text' : 'password';
      }
  },
};
</script>

<style lang="scss" scoped>
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .input-group {
    position: relative;

    .textarea {
      background: #fff;
      border: 1px solid #EEEFF1;
      box-sizing: border-box;
      border-radius: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      width: 100%;
      padding: 12px 16px 0;
      height: 120px;

      &:focus-visible {
        border: 1px solid #01AA4F;
      }

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #999999;
      }

      &:disabled {
        background: #F5F5F7;
        color: #999BA9;
      }
    }

    .input {
      background: #FFFFFF;
      border: 1px solid #EEEFF1;
      border-radius: 7px;
      height: 44px;
      padding: 0 14px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #242132;

      &:focus-visible {
        border: 1px solid #6E56FA;
      }

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #667085;
        font-family: AeonikMedium;
      }

      &:disabled {
        background: #F5F5F7;
        color: #999BA9;
      }
    }

    .block-error {
      position: absolute;
      bottom: -18px;
      left: 4px;

      &__text {
        margin: 0;
        font-size: 12px;
        line-height: 18px;
        color: #FF0000;
      }
    }

    .right {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      top: 0;
      right: 12px;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      text-align: right;
      color: #999999;
    }
  }

  /*.input-group.errors {*/
  /*  .input {*/
  /*    border: 1px solid #FF0000;*/
  /*  }*/
  /*}*/

  .input-group.input_small {
    position: relative;

    input {
      height: 36px;
    }

    .block-error {
      position: absolute;
      bottom: -18px;
      left: 4px;

      &__text {
        margin: 0;
        font-size: 12px;
        line-height: 18px;
        color: #FF0000;
      }
    }
  }

  .input-group.input_transparent {
    position: relative;

    input {
      border: none;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      text-align: right;
      color: #141725;
      padding: 0;

      &::placeholder {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-align: right;
        color: #999999;
      }

      &:focus-visible {
        border: none;
      }
    }

    .block-error {
      position: absolute;
      bottom: -8px;
      right: 0;

      &__text {
        margin: 0;
        font-size: 10px;
        line-height: 14px;
        color: #FF0000;
        text-align: right;
      }
    }
  }
</style>