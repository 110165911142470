<template>
  <div
      class="resumable-drop"
      v-show="resumable_drop"
      :class="[{
          'resumable-dragover': resumableDrag,
      }, classRandom+'-drop']"
      @dragover="resumableDrag=true"
      @drop="resumableDrag=false"
      @dragleave="resumableDrag=false"
      v-ripple="false"
  >
    <button class="resumable-browse" :class="classRandom+'-browse'" >
      <slot></slot>
    </button>
  </div>
</template>

<script>
    import Resumable from "../../assets/js/resumable";

    export default {
        data() {
            return {
                classRandom: String(this.$random(100000000)),
                resumable_error: false,
                resumable_drop: false,
                resumable_list: false,
                resumableDrag: false,
            }
        },
        props: {
            fileType: {
                type: Array,
                default: () => ["png", "jpeg", "jpg"],
            },
            target: {
                type: String,
                required: true
            },
            file: {
                type: String
            },
            isErrorUpload: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
            this.$nextTick(() => {
                let r = new Resumable({
                    target: this.target,
                    chunkSize: 2048 * 2048,
                    simultaneousUploads: 1,
                    testChunks: false,
                    maxFiles: 1,
                    maxChunkRetries: 1,
                    query: { _token: this.$token },
                    fileType: this.fileType,
                });
                this.r = r;
                if (!r.support) {
                    this.resumable_error = true;
                } else {
                    this.resumable_drop = true;
                    r.assignDrop(document.getElementsByClassName(this.classRandom+'-drop'));
                    r.assignBrowse(document.getElementsByClassName(this.classRandom+'-browse'));
                    this.resumable_list = true;
                    r.on("fileAdded", (file) => {
                        this.$emit('update:isErrorUpload', false);
                        console.log('fileAdded');
                        console.log(file);
                        // this.loadingList.push({
                        //     uniqueIdentifier: file.uniqueIdentifier,
                        //     file: file.fileName,
                        //     progress: "0%",
                        //     cancel: () => file.cancel(),
                        // });
                        r.upload();
                    });
                    r.on("fileSuccess", (file, message) => {
                        console.log('fileSuccess');
                        console.log(file);
                        let res = JSON.parse(message);
                        // let file_id = this.$filterIndexObject(
                        //     this.loadingList,
                        //     file.fileName,
                        //     "file"
                        // );
                        // this.loadingList.splice(file_id, 1);
                        this.file= res;
                        console.log('res');
                        console.log(res);
                        this.$emit('update:file', res.data);
                        this.$emit('update:isErrorUpload', false);
                        // this.fileList.push(res);
                        // this.uploadList.push({ url: res.message, name: file.fileName, uid: file.uniqueIdentifier, size: file.size });
                        // this.progress_text = 'success'
                    });
                    r.on("fileError", (file) => {
                        console.log('fileError');
                        console.log(file);
                        this.$emit('update:isErrorUpload', true);
                        // let file_id = this.$filterIndexObject(
                        //     this.loadingList,
                        //     file.fileName,
                        //     "file"
                        // );
                        // this.loadingList[file_id].progress = "Error";
                    });
                    r.on("fileProgress", (file) => {
                        console.log('fileProgress');
                        console.log(file);
                        // let file_id = this.$filterIndexObject(
                        //     this.loadingList,
                        //     file.fileName,
                        //     "file"
                        // );
                        // this.loadingList[file_id].progress =
                        //     Math.floor(file.progress() * 100) + "%";
                    });
                }
            });
        }
    }
</script>

<style>

  .resumable-drop {
    height: 100%;
    width: 100%;
  }

  .resumable-browse {
    height: 100%;
    width: 100%;
    border: none;
    background: transparent;
  }
  .resumable-dragover {
    border: 1px dashed #6E56FA;
    border-radius: 8px;
  }
</style>