<template>
    <div>
      <div v-if="market">
       {{market}}
      </div>
      <div v-else class="loading">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        {{__('Loading')}}
      </div>
    </div>
</template>

<script>


// import DotLoader from 'vue-spinner/src/DotLoader.vue';
export default {
  name: 'WalletsMarket',
  data: function() {
      return {
          market: null,
      }
  },
  computed: {

  },
  created() {
      this.$http.get('ajax/wallets/market/'+this.$route.params.currency)
      .then(response=> {
          return response.json()
      })
      .then((response)=> {
          this.market= response.data;
      }).catch((err) => {
          this.httpHandler(err);
      });
  },
  methods: {

  },
  components: {
      // DotLoader,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
