<template>
    <div class="sign-in">
        <div v-if="user" class="content">
            <div class="right">
                <div class="wrap-head">
                    <img src="/dist/images/logo_business.svg" class="logo" alt="">
                    <h2>{{__('web_signup_Sign up to continue')}}</h2>
                </div>
                <div class="text-left">
                    <div class="wrap-input form-row">
                        <p class="text semi">{{__('web_input_label_business_name')}}</p>
                        <InputLabelTextDefault
                                :placeholder="__('web_input_placeholder_enter_business_name')"
                                color="main"
                                v-model="name"
                                v-bind:errors="formValidate.name"
                                @change="updateValidate('name')"
                        ></InputLabelTextDefault>
                    </div>
                    <div class="wrap-input">
                        <p class="text semi">{{__('web_signup_Email')}}</p>
                        <InputLabelTextDefault
                                :placeholder="__('web_signup_Enter email')"
                                color="main"
                                v-model="user.email"
                                disabled
                        ></InputLabelTextDefault>
                    </div>

                    <div class="block-upload">
                        <div class="block-upload__img">
                            <UploadAvatar
                                    v-bind:post_params="{'type': 1}"
                                    v-bind:response.sync="response_upload"
                                    :url="'/ajax/store/avatar'"
                                    style="background-size: cover"
                            >
<!--                                <img class="avatar" :src="(store.store.photo) ? store.store.photo : '/dist/assets/uploads/store/default.svg'"/>-->
                                <div
                                    v-if="photo"
                                    class="avatar"
                                    :style="{'backgroundImage': 'url(' + photo + ')'}"
                                ></div>

                                <div v-else class="img">
                                    <img src="/dist/assets/uploads/store/default.svg"/>
                                </div>

                                <v-btn
                                    class="btn-site"
                                    style="height: 36px"
                                >
                                    <span>
                                      {{__('web_btn_upload_logo')}}
                                    </span>
                                </v-btn>
                            </UploadAvatar>
                        </div>
                    </div>

                    <div class="sign-text">
                        <div class="checkbox-site">
                            <v-checkbox v-model="sign" disabled></v-checkbox>
                        </div>
                        <p>{{__('web_signup_I read')}} <router-link :to="{name: 'privacy', params:{lang: $store.getters.getLang}}">{{__('web_signup_Privacy Police')}}</router-link> {{__('web_signup_and accept it lorem ipsum dolor sit amet')}}</p>
                    </div>

                    <div class="input-sing">
                        <div class="wrap-sing">
                            <v-btn
                                class="btn-store"
                                style="width: 100%; margin-bottom: 12px"
                                v-on:click="onSubmit"
                                :loading="submitDisabled"
                            >
                                    <span>
                                      {{__('web_btn_create_business_account')}}
                                    </span>
                            </v-btn>

                            <v-btn
                                class="btn-white"
                                style="width: 100%; height: 44px; text-decoration: none"
                                :to="{name: 'home'}"
                            >
                                <span>
                                  {{__('web_btn_back_to_wallet')}}
                                </span>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import InputLabelTextDefault from "../modules/InputLabelTextDefault";
    import UploadAvatar from "../modules/UploadAvatar";
    import {
        required,
        maxLength,
        minLength,
    } from "vuelidate/lib/validators";

    export default {
        name: "BusinessSignup",
        data() {
            return {
                sign: true,
                name: null,
                photo: null,
                submitDisabled: false,
                response_upload: null,
                formValidate: {
                    name: null,
                },
            };
        },
        watch: {
            response_upload: function (payload) {
                // let store = this.$cleanObject(this.store);
                // store.store.photo = payload.logo;
                // this.$store.dispatch("asyncStore", store);
                this.photo = payload.logo;
            },
        },
        props: {
            label: {
                type: String,
            },
        },
        validations: {
            name: {
                required,
                maxLength: maxLength(50),
                minLength: minLength(3),
            },
        },
        computed: {
            page: function () {
                return this.$route.name;
            },
            user() {
                return this.$store.getters.user
            },
            store() {
                return this.$store.getters.store
            },
        },
        methods: {
            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v[type],
                    type
                );
            },

            onSubmit: function () {
                let valid = this.$v;

                if (valid.$invalid) {
                    let massages = {
                        name: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;
                    const response = {
                        api_name: this.name,
                        photo: this.photo,
                    };

                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/store/create", response)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            this.submitDisabled = false;
                            let store = this.$cleanObject(this.store);
                            store.store = response.data.store;
                            this.$store.dispatch("asyncStore", store);
                            this.$router.push({name: 'business'});
                        })
                        .catch((err) => {
                            this.submitDisabled = false;
                            let response = this.httpHandler(err);
                            if (response.error) {
                                this.formValidate = response.error.message;
                            }
                        });
                }
            },
        },
        components: {
            InputLabelTextDefault,
            UploadAvatar
        },
    };
</script>

<style lang="scss" scoped>

</style>