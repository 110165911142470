<template>
  <div class="item-messages">

      <div v-if="message.opp_type == 1" class="message-left">
        <div class="message-left__img">
          <img src="/dist/images/img-support.svg" alt="">
        </div>

        <div class="message-left__content">
          <div class="content-top">
            <p class="block-name">Support</p>

<!--            <p class="block-date">{{((message.message_at) ? message.message_at : message.created_at) | formatDate}}</p>-->
          </div>

          <div class="content-body">
            {{__(message.message, {'username': user.name})}}
          </div>
        </div>
      </div>

      <div class="message-left" v-else>
        <div class="message-left__img">
          <img src="/dist/images/img-you.svg" alt="">
        </div>
        <div class="message-left__content">
          <div class="content-top">
            <p class="block-name">You</p>
            <!--            <p class="block-date">{{((message.message_at) ? message.message_at : message.created_at) | formatDate}}</p>-->
          </div>
          <div class="message-left__content">
            <div class="content-body">
              {{message.message}}
            </div>

            <!--          <div class="content-bottom">-->
            <!--            <p class="block-date">{{((message.message_at) ? message.message_at : message.created_at) | formatDate}}</p>-->
            <!--          </div>-->
          </div>
        </div>

      </div>
  </div>
</template>

<script>
    export default {
        name: 'MessengerMessagesListItem',
        data () {
            return {

            }
        },
        props: {
            user_id: {
                type: [String, Number]
            },
            message: {
                type: Object
            },
            index: {
                type: Number
            },

        },
        watch: {

        },
        computed: {
          user() {
            return this.$store.getters.user
          }
        },
        asyncComputed: {

        },
        created() {

        },
        methods: {

        },
        components: {
        },

    }
</script>