<template>
    <router-link :to="((module == 'support') ? {name: 'ticket', params: {id: item.public_id}} : {name: 'business_ticket', params: {id: item.public_id}})" class="item-ticket">
        <div class="head-ticket">
            <div class="body-ticket">
                <p class="body-ticket__title">{{item.title}}</p>
                <p class="body-ticket__desc desc">{{item.description | truncate(150)}}</p>
            </div>
            <div class="block-id">
              <p class="name">{{item.public_id}}</p>
              <p class="desc">{{__('Ticket ID')}}</p>
            </div>
            <div class="block-status" >
                <p v-if="item.status" class="name" style="color: #37C770">{{__('Closed')}}</p>
                <p v-else class="name" style="color: #6E56FA">{{__('Processing')}}</p>
                <p class="desc">{{__('Status')}}</p>
            </div>
            <div class="block-date">
                <p class="name">{{item.created_at | moment('MMM DD, YYYY')}}</p>
                <p class="desc">{{__('Open ticket')}}</p>
            </div>
        </div>


    </router-link>
</template>

<script>

    export default {
        name: 'ItemTicket',
        data: function() {
            return {
            }
        },

        props: {
            item: {
                type: Object
            },
            module: {
                type: String
            }
        },
        created() {

        },
        methods: {

        },
        components: {
        }
    }
</script>