<template>
    <div class="page-cards">
<!--        <div v-if="cards && cards.data.length" class="block-cards">-->
        <div v-if="isProduction" class="block-cards">
            <div class="block-head">
                <h2 class="block-head__title">{{__('web_page_card_list_cards_title')}}</h2>

                <v-btn
                    class="btn-site"
                    v-ripple="false"
                    :to="{name: 'card_order'}"
                >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.79961 2.40001C8.79961 1.95818 8.44144 1.60001 7.99961 1.60001C7.55778 1.60001 7.19961 1.95818 7.19961 2.40001V7.20001H2.39961C1.95778 7.20001 1.59961 7.55818 1.59961 8.00001C1.59961 8.44183 1.95778 8.80001 2.39961 8.80001H7.19961V13.6C7.19961 14.0418 7.55778 14.4 7.99961 14.4C8.44144 14.4 8.79961 14.0418 8.79961 13.6V8.80001H13.5996C14.0414 8.80001 14.3996 8.44183 14.3996 8.00001C14.3996 7.55818 14.0414 7.20001 13.5996 7.20001H8.79961V2.40001Z" fill="white"/>
                    </svg>

                    {{__('web_btn_add_card')}}
                </v-btn>
            </div>

            <div class="list-cards">
                <ItemCard v-for="(item, key) in cards.data" :item="item" :key="key"></ItemCard>
            </div>
        </div>

<!--        <div v-else-if="cards && !cards.data.length" class="block-stub">-->
        <div v-else-if="!isProduction" class="block-stub">
            <div class="block-top">
                <div class="left">
                    <div>
                        <p class="name">{{__('Get your')}}
                            <span>{{__('Neiva')}}</span> {{__('Card')}}
                        </p>
                        <p class="desc">{{__('Card page text')}}</p>
<!--                        <v-btn v-if="user.verified == 3 || user.verified == 0 || user.verified == 1"  height="40" class="btn-site" v-on:click="isVisible = true">-->
<!--                            <img src="/dist/images/user-circle.svg" alt="">-->
<!--                            {{__('Verify your account')}}</v-btn>-->
<!--                        <v-btn-->
<!--                                v-else-->
<!--                                disabled-->
<!--                                class="btn-site"-->
<!--                                v-ripple="false"-->
<!--                                :to="{name: 'card_order'}"-->
<!--                        >-->
<!--                            {{__('web_btn_order_card')}}-->
<!--                        </v-btn>-->
                    </div>

                </div>
                <div class="right">
<!--                    <img src="/dist/images/p-card.png" alt="">-->
                </div>
            </div>



<!--            <h2 class="block-stub__title">{{__('web_page_card_title')}}</h2>-->

<!--            <img class="block-stub__img" src="/dist/images/card.png" alt="">-->

<!--            <div class="list-options">-->
<!--                <div class="list-options__item">-->
<!--                    <div class="block-icon">-->
<!--                        <img src="/dist/images/card_option-1.svg" alt="">-->
<!--                    </div>-->

<!--                    <p>{{__('web_page_card_option_exchange')}}</p>-->
<!--                </div>-->

<!--                <div class="list-options__item">-->
<!--                    <div class="block-icon">-->
<!--                        <img src="/dist/images/card_option-2.svg" alt="">-->
<!--                    </div>-->

<!--                    <p>{{__('web_page_card_option_withdraw')}}</p>-->
<!--                </div>-->

<!--                <div class="list-options__item">-->
<!--                    <div class="block-icon">-->
<!--                        <img src="/dist/images/card_option-3.svg" alt="">-->
<!--                    </div>-->

<!--                    <p>{{__('web_page_card_option_available')}}</p>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div v-if="user.verified == 3 || user.verified == 0 || user.verified == 1" class="block-verification">-->
<!--                <div class="block-verification__text">-->
<!--                    <p class="block-verification__title">{{__('web_dash_menu_Verify Identity')}}</p>-->
<!--                    <p class="block-verification__subtitle">{{__('web_page_cards_block_verification_desc')}}</p>-->
<!--                </div>-->
<!--                <v-btn height="42" class="btn-site" v-on:click="isVisible = true">{{__('web_dash_menu_Verificate account')}}</v-btn>-->
<!--            </div>-->

<!--            <v-btn-->
<!--                v-else-->
<!--                disabled-->
<!--                class="btn-site"-->
<!--                v-ripple="false"-->
<!--                :to="{name: 'card_order'}"-->
<!--            >-->
<!--                {{__('web_btn_order_card')}}-->
<!--            </v-btn>-->
        </div>



        <div v-else class="block-loading">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            <p class="block-loading__text">{{__('Loading')}}</p>
        </div>

        <div class="top-all-p">
            <div>
                <p class="name">{{__('What youll get')}}</p>
                <p class="desc">{{__('Advantages')}}</p>
            </div>
        </div>
        <ul class="block-ul">
            <li>
                <img src="/dist/images/icon-cart-1.svg" alt="">
                <p>{{__('Exchange crypto to USD in a click')}}</p>
            </li>
            <li>
                <img src="/dist/images/icon-cart-2.svg" alt="">
                <p>{{__('Withdraw fiat from ATM worldwide')}}</p>
            </li>
            <li>
                <img src="/dist/images/icon-cart-3.svg" alt="">
                <p>{{__('Available in 60 countries')}} </p>
            </li>
            <li>
                <img src="/dist/images/icon-cart-4.svg" alt="">
                <p>{{__('Exchange crypto to USD in a click')}}</p>
            </li>
        </ul>

        <ModalVerification v-if="isVisible" :isVisible.sync="isVisible"/>
    </div>
</template>

<script>
    // import DotLoader from 'vue-spinner/src/DotLoader.vue';
    import ItemCard from "./Cards/ItemCard";
    import ModalVerification from "./Verification/ModalVerification";

    export default {
        name: 'Cards',

        data: function() {
            return {
                cards: {
                    data : [
                        {
                            number: '2345678945671234',
                            id: 1,
                        },
                        {
                            number: '2345678945671234',
                            id: 2,
                        },
                        {
                            number: '2345678945671234',
                            id: 3,
                        },
                        {
                            number: '2345678945671234',
                            id: 4,
                        },
                        {
                            number: '2345678945671234',
                            id: 5,
                        }
                    ]
                },
                isVisible: false,
                isProduction: false
            }
        },

        watch: {

        },
        computed: {
            user() {
                return this.$store.getters.user;
            },
        },

        created() {

        },

        methods: {


        },
        components: {
            // DotLoader,
            ItemCard,
            ModalVerification
        }
    }
</script>

<style scoped>

</style>
