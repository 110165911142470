<template>
    <div>
        <apexchart type="area" height="100%" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts'
    export default {
        name: 'Charts',

        data: function (){
            return{

                series: [{
                    name: 'series1',
                    data: [0, 0, 0, 0, 0, 0, 0]
                }, ],
                chartOptions: {
                    zoom: false,
                    type: 'area',
                    stacked: false,
                    toolbar: {
                        show: false
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 0.6,
                            inverseColors: true,
                            opacityFrom: 0.6,
                            opacityTo: 0,
                            gradientToColors: ['#6E56FA','#6E56FA','#6E56FA'],
                            stops: [0, 90, 100]
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },

                    legend: {
                        show: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 3,
                        colors: ['#6E56FA']
                    },
                    xaxis: {
                        labels: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        }
                    },
                    tooltip: {
                        shared: false,
                        x: {
                            show: false,
                            format: 'dd/MM/yy HH:mm'
                        },
                    },
                    yaxis: {
                        show: false,
                    },
                },

            }

        },
        created() {
        },
        computed: {

        },
        props: {

        },
        watch: {

        },
        components: {
            apexchart: VueApexCharts,
        },

    }
</script>
<style>
    .apexcharts-toolbar{
        display: none!important;
    }
    .chart{
        height: 100%;
    }
    .apexcharts-gridlines-horizontal{
        display: none!important;
    }
    .apexcharts-tooltip{
        display: none!important;
    }
    .apexcharts-xaxistooltip{
        display: none!important;
    }
    .apexcharts-xcrosshairs{
        display: none!important;
    }
    .apexcharts-series-markers-wrap{
        display: none!important;
    }
</style>
