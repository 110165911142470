<template>
    <div class="page-order-card">
        <div class="block-head">
            <router-link class="block-head__btn" :to="{name: 'cards'}">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.4693 20.45L7.9493 13.93C6.8893 12.87 6.8893 11.13 7.9493 10.07L14.4693 3.55002C14.7593 3.26002 15.2393 3.26002 15.5293 3.55002C15.8193 3.84002 15.8193 4.32002 15.5293 4.61002L9.0093 11.13C8.5293 11.61 8.5293 12.39 9.0093 12.87L15.5293 19.39C15.8193 19.68 15.8193 20.16 15.5293 20.45C15.3793 20.59 15.1893 20.67 14.9993 20.67C14.8093 20.67 14.6193 20.6 14.4693 20.45Z" fill="currentColor"/>
                </svg>
            </router-link>

            <h2 class="block-head__title">{{__('web_page_order_card_title')}}</h2>
        </div>

        <div class="block-body">
            <div class="block-control">
                <div class="item-control">
                    <p class="item-control__title">{{__('web_page_order_card_block_payment_system_title')}}</p>

                    <div class="tabs-system">
                        <div class="tabs-system__item" :class="(system == 1) ? 'active' : ''" v-on:click="system = 1">
                            <svg width="86" height="32" viewBox="0 0 86 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M43.8588 13.115C43.8303 15.3439 45.8633 16.5876 47.3947 17.3271C48.9682 18.0858 49.4967 18.5724 49.4904 19.251C49.4787 20.2893 48.2354 20.7477 47.0719 20.7655C45.042 20.7966 43.8617 20.2224 42.9234 19.7881L42.1922 23.1788C43.1336 23.6087 44.8768 23.9835 46.6844 24C50.9275 24 53.7035 21.9244 53.7186 18.7063C53.7352 14.6222 48.0178 14.3961 48.0568 12.5706C48.0703 12.017 48.6033 11.4263 49.7713 11.2761C50.3494 11.2003 51.9453 11.1422 53.7547 11.9679L54.4648 8.68728C53.4918 8.33619 52.2412 8 50.6842 8C46.6904 8 43.8814 10.1038 43.8588 13.115ZM61.2887 8.28258C60.5139 8.28258 59.8609 8.73044 59.5695 9.41772L53.5084 23.759H57.7484L58.5922 21.4483H63.7734L64.2629 23.759H68L64.7389 8.28258H61.2887ZM61.8818 12.4633L63.1055 18.2749H59.7543L61.8818 12.4633ZM38.718 8.28277L35.3758 23.7588H39.4162L42.7568 8.28238H38.718M32.7408 8.28238L28.5354 18.8165L26.8342 9.85978C26.6346 8.85992 25.8463 8.28258 24.9709 8.28258H18.0963L18 8.73199C19.4113 9.03547 21.0148 9.52494 21.9863 10.0487C22.5809 10.3686 22.7504 10.6483 22.9457 11.4085L26.1678 23.759H30.4375L36.9836 8.28258L32.7408 8.28238Z" fill="url(#paint0_linear_1329_74314)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_1329_74314" x1="40.987" y1="24.321" x2="41.4459" y2="7.88849" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#222357"/>
                                        <stop offset="1" stop-color="#254AA5"/>
                                    </linearGradient>
                                </defs>
                            </svg>

                            <p>Visa</p>
                        </div>

                        <div class="tabs-system__item" :class="(system == 2) ? 'active' : ''" v-on:click="system = 2">
                            <svg width="86" height="32" viewBox="0 0 86 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1329_74331)">
                                    <path d="M38.083 6.55139H48.5207V25.3063H38.083V6.55139Z" fill="#FF5F00"/>
                                    <path d="M38.7455 15.9289C38.7455 12.1183 40.5348 8.73842 43.285 6.5514C41.2638 4.96091 38.7125 4 35.9291 4C29.3348 4 24 9.33479 24 15.9289C24 22.5229 29.3348 27.8578 35.9289 27.8578C38.7123 27.8578 41.2637 26.8969 43.285 25.3063C40.5348 23.1525 38.7455 19.7395 38.7455 15.9289Z" fill="#EB001B"/>
                                    <path d="M62.6033 15.9289C62.6033 22.5229 57.2685 27.8578 50.6744 27.8578C47.891 27.8578 45.3396 26.8969 43.3184 25.3063C46.1018 23.1194 47.858 19.7395 47.858 15.9289C47.858 12.1183 46.0685 8.73842 43.3184 6.5514C45.3395 4.96091 47.891 4 50.6744 4C57.2685 4 62.6033 9.368 62.6033 15.9289Z" fill="#F79E1B"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1329_74331">
                                        <rect width="38.6415" height="24" fill="white" transform="translate(24 4)"/>
                                    </clipPath>
                                </defs>
                            </svg>

                            <p>Matercard</p>
                        </div>
                    </div>
                </div>

                <div class="item-control">
                    <p class="item-control__title">{{__('web_page_order_card_block_currency_title')}}</p>

                    <div class="tabs-currency">
                        <div class="tabs-currency__item" :class="(currency == 'EUR') ? 'active' : ''" v-on:click="currency = 'EUR'">
                            <p>EUR</p>
                        </div>

                        <div class="tabs-currency__item" :class="(currency == 'USD') ? 'active' : ''" v-on:click="currency = 'USD'">
                            <p>USD</p>
                        </div>
                    </div>
                </div>

                <div class="item-control">
                    <p class="item-control__title">{{__('web_page_order_card_block_color_title')}}</p>

                    <div class="list-colors">
                        <div v-for="(item, key) in card_colors" class="colors-item" :class="color == item.id ? 'active' : ''" :key="key" :style="{'background': item.color}" v-on:click="color = item.id">
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.34524 1.63832C9.54499 1.82899 9.55235 2.14549 9.36168 2.34524L4.11168 7.84524C4.01783 7.94356 3.888 7.99943 3.75208 8C3.61616 8.00056 3.48587 7.94577 3.39121 7.84824L0.64121 5.01491C0.448884 4.81675 0.453608 4.5002 0.651763 4.30788C0.849917 4.11555 1.16646 4.12028 1.35879 4.31843L3.747 6.77901L8.63832 1.65476C8.82899 1.45501 9.14549 1.44765 9.34524 1.63832Z" fill="white"/>
                            </svg>
                        </div>
                    </div>
                </div>

                <div class="item-control">
                    <p class="item-control__title">{{__('web_page_order_card_block_pincode_title')}}</p>

                    <InputLabelTextDefault
                        type="number"
                        @input="formValidate.pin = null"
                        v-model="form.pin"
                        v-bind:errors="formValidate.pin"
                        @change="updateValidate('pin')"
                        :placeholder="__('Enter PIN code for card...')"
                    ></InputLabelTextDefault>
                </div>

                <div class="item-control">
                    <p class="item-control__title">{{__('web_page_order_card_block_pincode_confirmation_title')}}</p>

                    <InputLabelTextDefault
                        type="number"
                        @input="formValidate.pin_confirmation = null"
                        v-model="form.pin_confirmation"
                        v-bind:errors="formValidate.pin_confirmation"
                        @change="updateValidate('pin_confirmation')"
                        :placeholder="__('Repeat PIN code for card...')"
                    ></InputLabelTextDefault>
                </div>

                <v-btn
                    class="btn-site"
                    v-ripple="false"
                >
                    {{__('web_btn_order_card')}}
                </v-btn>
            </div>

            <div class="card-info">
                <img class="card-info__img" src="/dist/images/card.png" alt="">

                <div class="card-info__item">
                    <span>{{__('web_page_order_card_block_info_name_title')}}</span>
                    <p>{{formVerificationPersonal.first_name}} {{formVerificationPersonal.last_name}}</p>
                </div>

                <div class="card-info__item">
                    <span>{{__('web_page_order_card_block_info_email_title')}}</span>
                    <p>{{user.email}}</p>
                </div>

                <div class="card-info__item">
                    <span>{{__('web_page_order_card_block_info_birth_title')}}</span>
                    <p>{{formVerificationPersonal.dob | moment(format = 'll')}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
import {required, sameAs, minLength, maxLength} from "vuelidate/lib/validators";

    export default {
        name: 'PageOrderCard',

        data: function() {
            let user = this.$store.getters.user;
            let application = user.application;

            let pesonalVer= (application) ?  {
                first_name: application.first_name,
                last_name: application.last_name,
                dob: application.dob,
            } :  {
                first_name: null,
                last_name: null,
                dob: null,
            };

            return {
                formVerificationPersonal: pesonalVer,
                system: 1,
                currency: 'EUR',
                form: {
                    pin: null,
                    pin_confirmation: null,
                },
                formValidate: {
                    pin: null,
                    pin_confirmation: null,
                },

                color: 1,
                card_colors: [
                    {
                        id: 1,
                        color: '#141725'
                    },
                    {
                        id: 2,
                        color: '#00925C'
                    },
                    {
                        id: 3,
                        color: '#6092C0'
                    },
                    {
                        id: 4,
                        color: '#D36086'
                    },
                    {
                        id: 5,
                        color: '#9170B8'
                    },
                    {
                        id: 6,
                        color: '#D39060'
                    },
                    {
                        id: 7,
                        color: '#8E5266'
                    },
                    {
                        id: 8,
                        color: '#6064C0'
                    },
                    {
                        id: 9,
                        color: '#4CAF50'
                    },
                    {
                        id: 10,
                        color: '#233DFF'
                    }
                ]
            }
        },

        validations: {
            form: {
                pin: {
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(4),
                },
                pin_confirmation: {
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(4),
                    sameAsPassword: sameAs(function() {
                        if(this.form.new_password && this.form.new_password.length) {
                            return (this.form.new_password== this.form.password) ? this.form.new_password : false;
                        } else {
                            return this.form.new_password;
                        }
                    }),
                },
            }
        },

        watch: {

        },

        computed: {
            user() {
                return this.$store.getters.user
            },
        },

        created() {

        },

        methods: {
            updateValidate: function(type, validate = false) {
                if(validate) {
                    let formValidate= this.formValidate;
                    formValidate[type]= [];

                    if(!this.$v.form[type][validate]) {
                        let msg= this.__(this.$validateMessage[validate]);
                        formValidate[type].push(msg);

                    }

                    this.formValidate= formValidate;
                } else {
                    this.formValidate= this.$changeValidate(this.formValidate, this.$v.form[type], type);
                }
            },
        },

        components: {
            InputLabelTextDefault
        }
    }
</script>

<style scoped>

</style>
