<template>
  <div class="main-search" v-on:click="focusInput" :class="{ 'main-search-focused': isFocused }">
    <div class="input-group d-flex">
      <slot name="left">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.58329 17.5C5.21104 17.5 1.66663 13.9555 1.66663 9.58329C1.66663 5.21104 5.21104 1.66663 9.58329 1.66663C13.9555 1.66663 17.5 5.21104 17.5 9.58329C17.5 13.9555 13.9555 17.5 9.58329 17.5Z" stroke="#979797" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path opacity="0.4" d="M18.3333 18.3333L16.6666 16.6666" stroke="#979797" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </slot>
      <input
        :placeholder="placeholder"
        class="pt-0 input"
        :disabled="disabled"
        :class="class_search"
        :readonly="readonly"
        ref="input"
        v-bind:value="value"
        v-bind="$attrs"
        v-on="inputListeners"
        style="width: 100%"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
      <slot name="right"></slot>
    </div>
    <div>
      <p class="red--text mt-2" v-for="(value, key) in errors" :key="key">{{value}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchDefault",
  data() {
    return {
      isFocused: false,
      input_type: this.type,
    };
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    color: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
    },
    errors: {
      type: Array,
    },
    class_search: {
      type: String,
    },
  },
  mounted() {
      let  timer = null;
      let friends_search= document.getElementsByClassName(this.class_search)[0];
      if(friends_search) {
          this.$addListener(friends_search, 'keyup', ()=> {
              clearTimeout(timer);
              timer = setTimeout(()=> {
                  this.$emit('searching');
              }, 600);
          });
      }
  },
  methods: {
    focusInput() {
      this.$refs.input.focus();
    }
  },
  computed: {
      inputListeners: function () {
          var vm = this;
          // `Object.assign` объединяет объекты вместе, чтобы получить новый объект
          return Object.assign(
              {},
              // Мы добавляем все слушатели из родителя
              this.$listeners,
              // Затем мы можем добавить собственные слушатели или
              // перезаписать поведение некоторых существующих.
              {
                  // Это обеспечит, что будет работать v-model на компоненте
                  input: function (event) {
                      vm.$emit("input", event.target.value);
                  },
              }
          );
      },
  },
};
</script>

<style lang="scss">
.main-search {
  border-radius: 8px;
  background: rgba(11, 19, 38, 0.06);
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  overflow: hidden;

  .input-group {
    display: flex;
    align-items: center;
    width: 100%;


    svg {
      margin-right: 12px;
    }

    .input {
      border: none;
      height: 40px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #242132;
      background-color: transparent;

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #98A2B3;
      }
    }
  }
}

.main-search.dropdown_search {
  background: #FFFFFF;
  box-shadow: 0 2px 8px rgba(20, 23, 37, 0.08);
  border: none;
  border-radius: 0;
  height: 44px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  overflow: hidden;

  .input-group {
    display: flex;
    align-items: center;

    svg {
      margin-right: 12px;
    }

    .input {
      border: none;
      height: 44px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #242132;

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #999999;
      }
    }
  }
}

</style>