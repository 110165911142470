<template>
    <div class="page-wallet">
        <div class="top-all-p">
            <div>
                <p class="name">{{__('Your Finances')}} </p>
                <span class="desc">{{__('Analytics of all your data')}}</span>
            </div>
            <div class="btn-block">
<!--                <router-link :to="{name: 'card_swap'}" class="btn-site mr-2">-->
<!--                    <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">-->
<!--                        <path d="M7.5 18.51C7.5 19.24 7.72001 19.9 8.13 20.47C5.45 20.3 3 19.32 3 17.51V16.63C4.05 17.45 5.6 18 7.5 18.18V18.51ZM7.53998 14.44C7.52998 14.45 7.53003 14.46 7.53003 14.47C7.51003 14.57 7.5 14.67 7.5 14.77V16.68C5.08 16.4 3 15.44 3 13.77V12.89C4.05 13.72 5.61003 14.27 7.53003 14.44H7.53998ZM11.44 10.78C9.92 11.25 8.73001 12.02 8.07001 12.98C5.41001 12.81 3 11.83 3 10.03V9.34998C4.31 10.38 6.41 10.98 9 10.98C9.87 10.98 10.69 10.91 11.44 10.78ZM15 9.34998V10.03C15 10.12 14.99 10.2 14.98 10.28C14.19 10.28 13.44 10.35 12.74 10.47C13.64 10.2 14.4 9.81998 15 9.34998ZM9 3.5C6 3.5 3 4.49999 3 6.48999C3 8.49999 6 9.47998 9 9.47998C12 9.47998 15 8.49999 15 6.48999C15 4.49999 12 3.5 9 3.5ZM15 19.26C12.49 19.26 10.35 18.6 9 17.53V18.51C9 20.5 12 21.5 15 21.5C18 21.5 21 20.5 21 18.51V17.53C19.65 18.6 17.51 19.26 15 19.26ZM15 11.78C11.69 11.78 9 13.12 9 14.77C9 16.42 11.69 17.76 15 17.76C18.31 17.76 21 16.42 21 14.77C21 13.12 18.31 11.78 15 11.78Z" fill="white"/>-->
<!--                    </svg>-->
<!--                    <p>{{__('Buy Crypto')}}</p>-->
<!--                </router-link>-->
                <a class="btn-site" v-on:click="depositWindow = true">
                    <img src="/dist/images/icon-dep.svg" alt="">
                    <p>{{__('web_btn_top_up')}}</p>
                </a>
                <a class="btn-white" v-on:click="withdrawWindow = true">
                    <img src="/dist/images/icon-with.svg" alt="">
                    <p>{{__('web_btn_withdraw')}}</p>
                </a>
            </div>
        </div>
        <Chart></Chart>
<!--        <ExchangeMerchant v-if="exchange" v-bind:exchange.sync="exchange"/>-->

        <div class="top-tab">
            <div
                    v-for="(item, key) in list_tab"
                    :key="key"
                    @click="active_tab = item.id"
                    class="block-tabs__item"
                    :class="item.id == active_tab ? 'active' : ''"
            >
                {{__(item.name)}}
            </div>
        </div>


        <div class="content" v-if="active_tab == 1">
            <div class="block-head">
                <div class="block-head__left">
                    <p class="head-title">{{__('Wallets')}}</p>
                    <p class="desc">{{__('Your plans')}}</p>
                </div>
                <div class="block-head__right">
                    <div class="head-switch">
                        <v-switch
                                v-model="hideZero"
                                color="success"
                                :label="`${__('web_wallets_Hide')} ${hide} ${__('web_wallets_hide_balances')}`"
                        ></v-switch>
                    </div>
                    <div class="block-tabs">
                        <div
                                v-for="(item, key) in list_currency"
                                :key="key"
                                @click="active_currency = item.id"
                                class="block-tabs__item"
                                :class="item.id == active_currency ? 'active' : ''"
                        >
                            {{__(item.name)}}
                        </div>
                    </div>
                    <SearchDefault
                            class_search="wallet_search"
                            :placeholder="__('web_wallets_Search by keywords...')"
                            v-on:keyup.enter="searchWallet()"
                            @searching="searchWallet()"
                            v-model="wallet_name"
                    ></SearchDefault>
                </div>
            </div>

            <div class="block-aside">
                <p class="block-aside__asset">{{__('web_wallets_Asset')}}</p>
                <p class="block-aside__balance">{{__('web_wallets_Price')}}</p>
                <p class="block-aside__price">{{__('web_text_amount')}}
                </p>
                <p class="block-aside__change">{{__('Value')}}</p>
            </div>

            <div v-if="active_currency == 2">
                <div class="list-wrapper">
                    <FiatItem
                        v-for="(item, key) in list_fiat"
                        :key="key"
                        :item="item"
                    >
                    </FiatItem>
                </div>
            </div>

            <div v-else>
                <div class="list-wrapper" v-if="Object.keys(wallets_list).length">
                    <WalletsItem v-bind:exchange.sync="exchange" v-for="(wallets,key) in wallets_list" v-bind:wallets="wallets" v-bind:wallet.sync="wallet" :key="key" v-bind:currency="key"></WalletsItem>

                    <WalletsTransactions v-if="wallet" v-bind:wallets_list.sync="wallet"/>
                </div>
                <div class="block-no-result" v-else>
                    <svg width="100" height="100" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M80 146.667H113.333C133.333 146.667 146.667 133.333 146.667 113.333V79.9998C146.667 61.9998 135.333 48.6665 118.667 46.6665C117.333 46.6665 115.333 46.6665 113.333 46.6665H46.6667C44.6667 46.6665 43.3334 46.6663 41.3334 47.333C24 49.333 13.3334 61.9998 13.3334 79.9998C13.3334 81.9998 13.3334 84.6665 13.3334 86.6665" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        <path opacity="0.4" d="M113.328 46.6681H46.6615C44.6615 46.6681 43.3281 46.668 41.3281 47.3346C41.9948 45.3346 43.3282 44.0014 45.3282 42.0014L66.6615 20.0015C75.9948 10.6681 90.6615 10.6681 99.9948 20.0015L111.995 32.0014C115.995 36.0014 117.995 41.3348 118.661 46.6681C117.328 46.6681 115.328 46.6681 113.328 46.6681Z" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        <path opacity="0.4" d="M146.667 83.3335H126.667C119.333 83.3335 113.333 89.3335 113.333 96.6668C113.333 104 119.333 110 126.667 110H146.667" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M59.9974 121.333C59.9974 109.533 50.4641 100 38.6641 100C26.8641 100 17.3307 109.533 17.3307 121.333C17.3307 133.133 26.8641 142.667 38.6641 142.667C50.4641 142.667 59.9974 133.133 59.9974 121.333Z" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.3334 146.667L20 140" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <p class="block-no-result__text">{{__('web_wallets_No result')}}</p>
                </div>
            </div>
        </div>

        <div v-else>
            <TransactionHistory></TransactionHistory>
        </div>

        <ModalDefault width="400px" v-if="depositWindow" v-bind:modal_cancel.sync="depositWindow">

            <div class="window-dep">
                <v-btn class="btn-close" v-ripple="false" text v-on:click="depositWindow = false">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.71209 3.71209C4.0782 3.34597 4.6718 3.34597 5.03791 3.71209L10 8.67418L14.9621 3.71209C15.3282 3.34597 15.9218 3.34597 16.2879 3.71209C16.654 4.0782 16.654 4.6718 16.2879 5.03791L11.3258 10L16.2879 14.9621C16.654 15.3282 16.654 15.9218 16.2879 16.2879C15.9218 16.654 15.3282 16.654 14.9621 16.2879L10 11.3258L5.03791 16.2879C4.6718 16.654 4.0782 16.654 3.71209 16.2879C3.34597 15.9218 3.34597 15.3282 3.71209 14.9621L8.67418 10L3.71209 5.03791C3.34597 4.6718 3.34597 4.0782 3.71209 3.71209Z" fill="#999999"/>
                    </svg>
                </v-btn>
                <Deposit></Deposit>
            </div>
        </ModalDefault>

        <ModalDefault width="400px" v-if="withdrawWindow" v-bind:modal_cancel.sync="withdrawWindow">
            <div class="window-dep">
                <v-btn class="btn-close" v-ripple="false" text v-on:click="withdrawWindow = false">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.71209 3.71209C4.0782 3.34597 4.6718 3.34597 5.03791 3.71209L10 8.67418L14.9621 3.71209C15.3282 3.34597 15.9218 3.34597 16.2879 3.71209C16.654 4.0782 16.654 4.6718 16.2879 5.03791L11.3258 10L16.2879 14.9621C16.654 15.3282 16.654 15.9218 16.2879 16.2879C15.9218 16.654 15.3282 16.654 14.9621 16.2879L10 11.3258L5.03791 16.2879C4.6718 16.654 4.0782 16.654 3.71209 16.2879C3.34597 15.9218 3.34597 15.3282 3.71209 14.9621L8.67418 10L3.71209 5.03791C3.34597 4.6718 3.34597 4.0782 3.71209 3.71209Z" fill="#999999"/>
                    </svg>
                </v-btn>
                <Withdraw></Withdraw>
            </div>
        </ModalDefault>
    </div>
</template>

<script>

// import ExchangeMerchant from './Home/ExchangeMerchant';
import _ from 'lodash';
import WalletsItem from './Home/WalletsItem';
import SearchDefault from "../modules/SearchDefault";
import WalletsTransactions from "./Home/WalletsTransactions";
import FiatItem from "./Home/FiatItem";
import ModalDefault from "../modules/ModalDefault";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw";
import Chart from "../modules/Chart";
import TransactionHistory from "./TransactionHistory";

export default {
  name: 'Wallets',
  data: function() {
    return {
        withdrawWindow: false,
        depositWindow: false,
      wallet: null,
      search: null,
      wallet_name: null,
      info: null,
      hideZero: false,
      hide: 0,
      wallets_list: null,
      exchange: null,
      list_currency: [
          {
              id: 1,
              name: 'Crypto'
          },
          // {
          //     id: 2,
          //     name: 'Fiat'
          // }
      ],
        list_tab: [
            {
                id: 1,
                name: 'Wallets'
            },
            {
                id: 2,
                name: 'Transaction history'
            }
        ],
        active_tab: 1,
      active_currency: 1,

      list_fiat: [
          {
              "id": 1,
              "symbol": "PISO",
          },
          {
              "id": 2,
              "symbol": "KZT",
          },
          {
              "id": 3,
              "symbol": "CNY",
          },
          {
              "id": 4,
              "symbol": "UAH",
          },
          {
              "id": 5,
              "symbol": "RUB",
          },
          {
              "id": 6,
              "symbol": "PKR",
          },
          {
              "id": 7,
              "symbol": "USD",
          },
          {
              "id": 8,
              "symbol": "EUR",
          },
          {
              "id": 9,
              "symbol": "INR",
          },
          {
              "id": 10,
              "symbol": "VND",
          },
      ]
    }
  },
  watch:{
      hideZero() {
          this.walletsSend();
      },
      wallets() {
          this.walletsSend();
      }
  },
  computed: {
      wallets() {
          return this.$store.getters.wallets
      },
      courses() {
          return this.$store.getters.courses
      },
      user() {
          return this.$store.getters.user
      },
      // exchange() {
      //     return this.$store.getters.exchange
      // },
  },
  created() {
      this.getMerchant(this.$route.params.id);
      this.walletsSend();
  },
  beforeRouteLeave (to, from, next) {
      // обрабатываем изменение параметров маршрута...

      this.getMerchant(to.params.id);
      return next();
  },
  methods: {
      searchWallet() {
          this.search = this.$cleanObject(this.wallet_name);
          this.walletsSend();
      },
      walletsSend() {

          let wallets = this.$cleanObject(this.wallets);

          if(this.hideZero) {
              _.remove(wallets, {'balance': 0});
          }

          if(this.search) {
              let search = this.search,
                  list= [];

              for (let i = 0; i < wallets.length; i++) {
                  let wallet= wallets[i],
                      reg = new RegExp(search, 'gi');

                  if(wallet.name.search(reg) >= 0 || wallet.currency.search(reg) >= 0) {
                      list.push(wallet)
                  }
              }

              wallets = list;
          }


          this.hide = this.wallets.length - wallets.length;
          wallets = _.orderBy(wallets, item => item.balance * this.courses[item.symbol], ['desc']);
          let groupSymbol = _.groupBy(wallets, 'symbol');
          this.wallets_list= groupSymbol;
      },
      getMerchant(id) {
          if(id) {
              this.$http.get('ajax/exchange'+'/'+id)
                  .then(response=> {
                      return response.json()
                  })
                  .then((response)=> {
                      this.exchange= response.data;
                      // this.$store.dispatch('asyncExchange', response.data);
                  }).catch((err) => {
                  this.httpHandler(err);
              });
          } else {
              this.exchange= null;
          }
      }
  },
  components: {
      TransactionHistory,
      Chart,
      SearchDefault,
      ModalDefault,
      Deposit,
      Withdraw,
      // ExchangeMerchant,
      WalletsItem,
      FiatItem,
      WalletsTransactions
      // MyOffice,
      // DotLoader,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
