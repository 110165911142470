<template>
  <div class="item-wallet"

  >
<!--    v-on:click="$emit('update:wallet', wallets)"-->
    <div class="item-wallet__asset">
      <div class="block-img">

        <img :src=wallets[0].img>
      </div>

      <div class="block-name">
          <p>{{wallets[0].symbol}}</p>
          <span>{{wallets[0].name | clearStr}}</span>
      </div>
    </div>

    <div class="item-wallet__balance">
      <p class="mob">Value:</p>
      <p >{{user.course_current.course_ticker}} {{$primNum(courses[wallets[0].symbol] * user.course_current.course_current)}} </p>
    </div>

    <div class="item-wallet__price">
        <p class="mob">Amount:</p>
      <p>{{sumBalance['coin']}}
<!--        {{wallets[0].symbol}}-->
      </p>

    </div>

    <div class="item-wallet__change">
      <p class="mob">Price:</p>
      <p>{{user.course_current.course_ticker}} {{$primNum(sumBalance['usd'] * user.course_current.course_current)}} </p>
<!--      <p :style="{color: (percentProfit(wallets[0].symbol) >= 0) ? '#01AA4F' : '#F51E38'}">-->

<!--        <svg v-if="percentProfit(wallets[0].symbol) >= 0" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M5.0496 3.85769C4.94405 3.96323 4.8794 4.1099 4.87961 4.27097L4.87961 4.34187C4.87961 4.66443 5.14074 4.92555 5.46288 4.92514L8.64993 4.92514L3.2587 10.3164C3.03101 10.5441 3.03101 10.9136 3.2587 11.1413C3.48639 11.369 3.85597 11.369 4.08366 11.1413L9.47488 5.7501L9.47488 8.93714C9.47488 9.2597 9.73601 9.52083 10.0582 9.52041L10.129 9.52041C10.4516 9.52041 10.7127 9.25929 10.7123 8.93714L10.7123 4.27097C10.7123 3.94842 10.4512 3.68729 10.129 3.6877L5.46288 3.6877C5.3016 3.6877 5.15514 3.75215 5.0496 3.85769Z" fill="#01AA4F"/>-->
<!--        </svg>-->

<!--        <svg v-else width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M8.9504 11.1423C9.05595 11.0368 9.12059 10.8901 9.12039 10.729L9.12039 10.6581C9.12039 10.3356 8.85926 10.0744 8.53712 10.0749L5.35007 10.0749L10.7413 4.68363C10.969 4.45594 10.969 4.08636 10.7413 3.85867C10.5136 3.63098 10.144 3.63098 9.91634 3.85867L4.52512 9.2499L4.52512 6.06286C4.52512 5.7403 4.26399 5.47917 3.94185 5.47959L3.87095 5.47959C3.54839 5.47959 3.28727 5.74071 3.28768 6.06286L3.28768 10.729C3.28768 11.0516 3.5488 11.3127 3.87095 11.3123L8.53712 11.3123C8.6984 11.3123 8.84486 11.2479 8.9504 11.1423Z" fill="#F51E38"/>-->
<!--        </svg>-->

<!--        {{percentProfit(wallets[0].symbol)}}%-->
<!--      </p>-->
    </div>
  </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: 'WalletsItem',

        data: function() {
            return {
                modal: false,
            }
        },
        props: {
            wallets: {
                type: Array
            },
            currency: {
                type: String
            },
        },
        created() {
        },
        watch:{

        },
        computed: {
            sumBalance() {
                let wallets = this.$cleanObject(this.wallets);
                let course_usd = this.courses[wallets[0].symbol];
                let coin = _.sumBy(wallets, 'balance');

                return {
                    coin: this.$primNum(coin, wallets[0].decimals),
                    usd: this.$primNum(coin*course_usd)
                }
            },
            courses() {
                return this.$store.getters.courses
            },
            charts() {
                return this.$store.getters.charts
            },
            user() {
              return this.$store.getters.user
            },
        },
        methods: {
            updateExchange(response) {
                this.$emit('update:exchange', response);
            },
            percentProfit(currency) {
                let chart_data = this.charts;
                if (chart_data) {
                  return ((chart_data.day.first[currency] - chart_data.day.last[currency])/(chart_data.day.first[currency] / 100)).toFixed(2);
                }
            },
        },
        components: {

        },
    }
</script>

<style>

</style>
