<template>
    <div class="page-referral">
        <div v-if="affiliate_info && referrals">
            <div class="block-info">
                <div class="content-info">
                    <h2 class="content-info__title">{{__('Referral Program')}}</h2>

                    <p class="content-info__desc">{{__('Referral Program text')}}</p>

                    <div class="block-link">
                        <p class="block-link__title">{{__('Your referral link')}}</p>

                        <div class="content-link">
                            <div class="content-link__copy" v-if="isCopy">
                                <img src="/dist/images/img-copy.svg" alt="">
                                <p>{{__('web_text_copied')}}</p>
                            </div>

                            <p v-else class="content-link__text">{{affiliate_info.ref_link}}</p>

                            <v-btn
                                    class="btn-copy"
                                    v-ripple="false"
                                    text
                                    v-on:click="copyMessage"
                            >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 9.625V18.375C21 20.125 20.125 21 18.375 21H9.625C7.875 21 7 20.125 7 18.375V9.625C7 7.875 7.875 7 9.625 7H18.375C20.125 7 21 7.875 21 9.625ZM15.75 3C15.75 2.586 15.414 2.25 15 2.25H5.625C3.448 2.25 2.25 3.448 2.25 5.625V15C2.25 15.414 2.586 15.75 3 15.75C3.414 15.75 3.75 15.414 3.75 15V5.625C3.75 4.293 4.293 3.75 5.625 3.75H15C15.414 3.75 15.75 3.414 15.75 3Z" fill="#344054"/>
                                </svg>
                            </v-btn>
                        </div>
                    </div>

<!--                    <div class="block-levels">-->
<!--                        <div class="block-levels__item" v-for="(item, key) in affiliate_info.referral_levels" :key="key">-->
<!--                            <p>{{item.percent}}%</p>-->
<!--                            <span>{{__(`web_page_referral_block_levels_${item.line}_subtitle`)}}</span>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>

<!--                <img class="block-info__img" src="/dist/images/referral-info_img.png" alt="">-->
            </div>

            <div class="stat">
                <h2>{{__('Statistics')}}</h2>
<!--                <ul class="ul-top">-->
<!--                    <li>-->
<!--                        <div class="block">-->
<!--                            <p>Заработано</p>-->
<!--                            <span>по реферальной программе</span>-->
<!--                        </div>-->
<!--                        <div class="price">-->
<!--                            <span>+$200</span>-->
<!--                            <p>$1.000,00</p>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <div class="block">-->
<!--                            <p>Оборот</p>-->
<!--                            <span>по реферальной программе</span>-->
<!--                        </div>-->
<!--                        <div class="price">-->
<!--                            <span>+$200</span>-->
<!--                            <p>$1.000,00</p>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <div class="block">-->
<!--                            <p>Matching Bonus</p>-->
<!--                        </div>-->
<!--                        <div class="price">-->
<!--                            <span>+$200</span>-->
<!--                            <p>$1.000,00</p>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                </ul>-->
<!--                {{affiliate_info.referral_levels}}-->
                <ul class="ul-body">
                    <li>
                        <div class="block">
                            <p>{{__('Total earned')}}</p>
                        </div>
                        <div class="price">
                            <p>{{user.course_current.course_marker}}

                                {{
                                $primNum(
                                (((affiliate_info.profit_lines[1]) ? +affiliate_info.profit_lines[1].profit : 0.00) +
                                ((affiliate_info.profit_lines[2]) ? +affiliate_info.profit_lines[2].profit : 0.00) +
                                ((affiliate_info.profit_lines[3]) ? +affiliate_info.profit_lines[3].profit : 0.00)) * user.course_current.course_current
                                )
                                }}</p>
                        </div>
                    </li>
                    <li>
                        <div class="block">
                            <p>{{__('1st line')}}</p>
                            <span>10%</span>
                        </div>
                        <div class="price">
                            <p>{{user.course_current.course_marker}} {{affiliate_info.profit_lines[1] ? $primNum(affiliate_info.profit_lines[1].profit * user.course_current.course_current) : '0.00'}}</p>
                        </div>
                    </li>
                    <li>
                        <div class="block">
                            <p>{{__('2nd line')}}</p>
                            <span>7%</span>
                        </div>
                        <div class="price">
                            <p>{{user.course_current.course_marker}} {{affiliate_info.profit_lines[2] ? $primNum(affiliate_info.profit_lines[2].profit * user.course_current.course_current) : '0.00'}}</p>
                        </div>
                    </li>
                    <li>
                        <div class="block">
                            <p>{{__('3rd line')}}</p>
                            <span>3%</span>
<!--                            <span>по реферальной программе</span>-->
                        </div>
                        <div class="price">
                            <p>{{user.course_current.course_marker}} {{affiliate_info.profit_lines[3] ? $primNum(affiliate_info.profit_lines[3].profit * user.course_current.course_current) : '0.00'}}</p>
                        </div>
                    </li>
                </ul>
            </div>


<!--            <div class="block-income">-->
<!--                <div class="block-income__item">-->
<!--                    <p>{{user.course_current.course_marker}} {{affiliate_info.profit_lines[1] ? $primNum(affiliate_info.profit_lines[1].profit * user.course_current.course_current) : '0.00'}}</p>-->
<!--                    <span>{{__('web_page_referral_block_levels_1_subtitle')}}</span>-->
<!--                </div>-->

<!--                <div class="block-income__item">-->
<!--                    <p>{{user.course_current.course_marker}} {{affiliate_info.profit_lines[2] ? $primNum(affiliate_info.profit_lines[2].profit * user.course_current.course_current) : '0.00'}}</p>-->
<!--                    <span>{{__('web_page_referral_block_levels_2_subtitle')}}</span>-->
<!--                </div>-->

<!--                <div class="block-income__item">-->
<!--                    <p>{{user.course_current.course_marker}} {{affiliate_info.profit_lines[3] ? $primNum(affiliate_info.profit_lines[3].profit * user.course_current.course_current) : '0.00'}}</p>-->
<!--                    <span>{{__('web_page_referral_block_levels_3_subtitle')}}</span>-->
<!--                </div>-->

<!--                <div class="block-income__item">-->
<!--                    <p>-->
<!--                        {{user.course_current.course_marker}}-->

<!--                        {{-->
<!--                            $primNum(-->
<!--                                (((affiliate_info.profit_lines[1]) ? +affiliate_info.profit_lines[1].profit : 0.00) +-->
<!--                                ((affiliate_info.profit_lines[2]) ? +affiliate_info.profit_lines[2].profit : 0.00) +-->
<!--                                ((affiliate_info.profit_lines[3]) ? +affiliate_info.profit_lines[3].profit : 0.00)) * user.course_current.course_current-->
<!--                            )-->
<!--                        }}-->
<!--                    </p>-->
<!--                    <span>{{__('web_page_referral_block_income_total_income')}}</span>-->
<!--                </div>-->
<!--            </div>-->

            <div class="block-referrals">
                <div class="referrals-head">
                    <p class="referrals-head__title">{{__('Your referral tree')}}</p>

<!--                    <div class="referrals-head__search">-->
<!--                        <SearchDefault-->
<!--                                class_search="wallet_search"-->
<!--                                :placeholder="__('web_wallets_Search by keywords...')"-->
<!--                                v-on:keyup.enter="searchReferrals()"-->
<!--                                @searching="searchReferrals()"-->
<!--                                v-model="search"-->
<!--                        ></SearchDefault>-->
<!--                    </div>-->
                </div>

                <div v-if="isLoading" class="block-loading-search">
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    <p class="block-loading-search__text">{{__('Loading')}}</p>
                </div>

                <div v-else-if="referrals && referrals.length" class="list-referrals">
                    <ReferralItem v-for="(item, key) in referrals" :item="item" :key="key"></ReferralItem>
                </div>

                <div v-else-if="search && referrals && !referrals.length" class="block-no-result">
                    <svg width="50" height="50" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M31.6336 0.365896C31.1455 -0.122291 30.354 -0.122291 29.8659 0.365896L26.3949 3.8369C23.4987 1.3554 19.8473 -0.000228509 15.9998 -0.000228509C11.726 -0.000228509 7.70808 1.66402 4.68601 4.68602C1.66395 7.70802 -0.000236139 11.726 -0.000236139 15.9998C-0.000236139 19.851 1.3542 23.4998 3.83626 26.3955L0.365889 29.8659C-0.122299 30.3541 -0.122299 31.1455 0.365889 31.6336C0.609951 31.8777 0.929826 31.9998 1.24976 31.9998C1.5697 31.9998 1.88958 31.8777 2.13364 31.6336L31.6336 2.13365C32.1218 1.64552 32.1218 0.854021 31.6336 0.365896ZM2.49976 15.9998C2.49976 8.55583 8.55583 2.49977 15.9998 2.49977C19.1803 2.49977 22.2017 3.59771 24.6208 5.61096L5.61089 24.6209C3.59726 22.2021 2.49976 19.1831 2.49976 15.9998ZM30.464 9.15165C31.4831 11.3006 31.9998 13.6046 31.9998 15.9998C31.9998 19.158 31.0623 22.2294 29.2889 24.882C29.0479 25.2425 28.652 25.4374 28.2486 25.4374C28.0098 25.4374 27.7684 25.3691 27.555 25.2265C26.981 24.8428 26.8268 24.0665 27.2105 23.4926C28.7082 21.2525 29.4998 18.6616 29.4998 15.9998C29.4998 13.978 29.0642 12.0344 28.2051 10.2229C27.9093 9.59915 28.1752 8.85365 28.799 8.55783C29.4228 8.2619 30.1682 8.5279 30.464 9.15165ZM24.7001 27.9772C25.0578 28.5676 24.8691 29.3363 24.2786 29.694C21.7883 31.2025 18.9256 31.9998 15.9998 31.9998C13.6567 31.9998 11.3983 31.5045 9.28733 30.5276C8.66083 30.2376 8.38795 29.4947 8.67789 28.8682C8.96783 28.2417 9.71076 27.969 10.3373 28.2588C12.1168 29.0822 14.022 29.4998 15.9998 29.4998C18.4688 29.4998 20.8837 28.8275 22.9834 27.5556C23.5738 27.1979 24.3425 27.3866 24.7001 27.9772Z" fill="#DADADA"/>
                    </svg>

                    <p class="block-no-result__text">
                        {{__('The search has not given any results…')}} <br>
                        {{__('Try to change your request')}}
                    </p>
                </div>

                <div class="block-no-result" v-else-if="referrals && !referrals.length" >
                    <svg width="100" height="100" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M80 146.667H113.333C133.333 146.667 146.667 133.333 146.667 113.333V79.9998C146.667 61.9998 135.333 48.6665 118.667 46.6665C117.333 46.6665 115.333 46.6665 113.333 46.6665H46.6667C44.6667 46.6665 43.3334 46.6663 41.3334 47.333C24 49.333 13.3334 61.9998 13.3334 79.9998C13.3334 81.9998 13.3334 84.6665 13.3334 86.6665" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        <path opacity="0.4" d="M113.328 46.6681H46.6615C44.6615 46.6681 43.3281 46.668 41.3281 47.3346C41.9948 45.3346 43.3282 44.0014 45.3282 42.0014L66.6615 20.0015C75.9948 10.6681 90.6615 10.6681 99.9948 20.0015L111.995 32.0014C115.995 36.0014 117.995 41.3348 118.661 46.6681C117.328 46.6681 115.328 46.6681 113.328 46.6681Z" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        <path opacity="0.4" d="M146.667 83.3335H126.667C119.333 83.3335 113.333 89.3335 113.333 96.6668C113.333 104 119.333 110 126.667 110H146.667" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M59.9974 121.333C59.9974 109.533 50.4641 100 38.6641 100C26.8641 100 17.3307 109.533 17.3307 121.333C17.3307 133.133 26.8641 142.667 38.6641 142.667C50.4641 142.667 59.9974 133.133 59.9974 121.333Z" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.3334 146.667L20 140" stroke="#DADADA" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <p class="block-no-result__text">{{__('web_wallets_No result')}}</p>
                </div>
            </div>
        </div>
        <div v-else class="block-loading">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            <p class="block-loading__text">{{__('Loading')}}</p>
        </div>
    </div>
</template>

<script>
    // import SearchDefault from "../modules/SearchDefault";
    import ReferralItem from "./AffiliateProgram/ReferralItem";
    // import DotLoader from 'vue-spinner/src/DotLoader.vue';

    export default {
        name: 'AffiliateProgram',

        data: function() {
            return {
                isCopy: false,
                affiliate_info: null,
                referrals: null,
                next_page_url: null,
                search: null,
                isLoading: false,
                loadingPage: false,
            }
        },

        watch:{

        },
        computed: {
            user() {
                return this.$store.getters.user
            },
        },

        created() {
            this.$http.get('ajax/affiliate')
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    this.affiliate_info = response.data;
                    this.referrals = response.data.referrals.data;
                    this.next_page_url = response.data.referrals.next_page_url;
                }).catch((err) => {
                this.httpHandler(err);
            });

            this.$addListener(window,'scroll', () => {
                this.onScrollList();
            });
        },

        methods: {
            copyMessage() {
                this.isCopy = true;
                this.$copyMessage(this.affiliate_info.ref_link);
                setTimeout(()=> {
                    this.isCopy = false;
                }, 2000);
            },

            searchReferrals() {
                this.isLoading = true;
                const request = {
                    search: this.search ? this.search : null
                };
                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/referrals', request)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        response = response.data;
                        this.referrals = response.data;
                        this.next_page_url = response.next_page_url;
                        this.isLoading = false;
                    })
                    .catch((err) => {
                        this.httpHandler(err);
                        this.isLoading = false;
                    });
            },

            onScrollList() {
                // const progress = this.$refs.list_referrals.scrollTop / (this.$refs.list_referrals.scrollHeight - this.$refs.list_referrals.clientHeight);
                const progress = document.documentElement.scrollTop / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
                if (!this.loadingPage && this.next_page_url) {
                    let bottomOfWindow = 0.7 <= progress;
                    if (bottomOfWindow) {
                        this.loadingPage = true;
                        const request = {
                            search: this.search ? this.search : null
                        };
                        this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get'](this.next_page_url, request)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            response = response.data;
                            let referrals = this.referrals;
                            this.referrals = referrals.concat(response.data);
                            this.next_page_url = response.next_page_url;
                            this.loadingPage = false;
                        })
                        .catch((err) => {
                            this.httpHandler(err);
                            this.loadingPage = false;
                        });
                    }
                }
            }
        },
        components: {
            // SearchDefault,
            ReferralItem,
            // DotLoader
        }
    }
</script>

<style scoped>

</style>
