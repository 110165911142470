<template>
    <div class="sign-in">
        <div class="content">
            <div class="right">
                <div class="right-block">
                    <div class="wrap-head">
                        <router-link :to="{name: 'home'}"><img src="/dist/images/logo-f.svg" class="logo" alt=""></router-link>
                        <h2>{{__('Sign in to your account')}}</h2>
                        <p class="desc">{{__('Not a member?')}} <router-link :to="{name: 'register'}">{{__('Sign Up')}}</router-link> </p>
                    </div>
                    <div class="text-left">
                        <div class="wrap-input">
                            <p class="text semi">{{__('Email')}}</p>
                            <InputLabelTextDefault
                                    :placeholder="__('Your Email')"
                                    color="main"
                                    v-model="email"
                                    v-bind:errors="formValidate.email"
                                    type="email"
                                    name="email"
                                    id="email"
                                    @change="updateValidate('email')"
                            ></InputLabelTextDefault>
                        </div>
                        <div class="wrap-input">
                            <p class="text semi for">{{__('Password')}} <router-link :to="{name: 'forgot'}">{{__('Forgot Password?')}}
                            </router-link></p>

                            <InputLabelTextDefault
                                    :placeholder="__('Type your password here')"
                                    color="main"
                                    switch-type
                                    type="password"
                                    v-model="password"
                                    v-bind:errors="formValidate.password"
                                    @change="updateValidate('password')"

                            ></InputLabelTextDefault>

                        </div>
                        <div class="wrap-sing">
                            <v-btn
                                    style="width: 100%"
                                    height="54px"
                                    class="btn-site"
                                    v-on:click="openCaptcha"
                                    :loading="submitDisabled"
                            >
                          <span>
                            {{__('Sign In')}}
                          </span>
                            </v-btn>
                        </div>

                    </div>
                </div>
            </div>
            <div class="left"></div>
        </div>

        <Captcha v-if="captcha" @onSubmit="onSubmit"></Captcha>
    </div>
</template>


<script>
    import InputLabelTextDefault from "../modules/InputLabelTextDefault";
    import Captcha from "../modules/Captcha";
    import {
        required,
        email,
        maxLength,
        minLength,
    } from "vuelidate/lib/validators";

    export default {
        name: "AuthLogin",
        data() {
            return {
                captcha: false,
                email: null,
                password: null,
                submitDisabled: false,
                formValidate: {
                    email: null,
                    password: null,
                },
                rules: this.validateMessage,
            };
        },
        props: {
            socialAuth: {
                type: Array,
            },
        },
        validations: {
            email: {
                required,
                email,
            },
            password: {
                required,
                maxLength: maxLength(50),
                minLength: minLength(6),
            },
        },
        mounted () {

        },
        methods: {
            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v[type],
                    type
                );
            },
            openCaptcha() {
                let valid = this.$v;
                if (valid.$invalid) {
                    let massages = {
                        email: [],
                        password: [],
                    };

                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.captcha= true;
                }
            },
            onSubmit: function (session, arrayData) {
                this.captcha= false;
                let valid = this.$v;

                if (valid.$invalid) {
                    let massages = {
                        email: [],
                        password: [],
                    };

                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;
                    const response = {
                        email: this.email,
                        password: this.password,
                        challenge: session,
                    };
                    response[session]= arrayData;

                    this.$http
                        .post("ajax/login", response)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {

                            this.submitDisabled = false;
                            if(response.data.security) {
                                this.$store.dispatch('asyncUser', response.data);
                                if(this.$route.params.page != 'security') {
                                  this.$router.push({name: 'security'});
                                }
                            } else {
                                // this.$store.commit('updateAuth', false);
                                // this.$router.push({name: 'dashboard'});
                                window.loginUser = true;
                                location.reload();
                            }
                        })
                        .catch((err) => {
                            this.captcha= false;
                            this.submitDisabled = false;
                            let response = this.httpHandler(err);
                            if (response.error) {
                                this.formValidate = response.error.message;
                            }
                        });
                }
            },
        },
        components: {
            Captcha,
            InputLabelTextDefault,
        },
    };
</script>

<style lang="css" scoped>


</style>
