<template>
    <div class="sign-in">
            <div class="content">
                <div class="right">
                    <div class="right-block">
                        <div class="wrap-head">
                            <router-link :to="{name: 'home'}"><img src="/dist/images/logo-f.svg" class="logo" alt=""></router-link>
                            <h2>{{__('Create your Neiva account')}}</h2>
                            <p class="desc">{{__('Create an account to start investing')}}</p>
                        </div>
                        <div class="text-left">
                            <div class="wrap-input">
                                <p class="text semi">{{__('Email')}}</p>
                                <InputLabelTextDefault
                                        :placeholder="__('Your Email')"
                                        color="main"
                                        v-model="email"
                                        v-bind:errors="formValidate.email"
                                        @change="updateValidate('email')"
                                        type="email"
                                        name="email"
                                ></InputLabelTextDefault>
                            </div>
                            <div class="wrap-input form-row">
                                <p class="text semi">{{__('Full Name')}}</p>
                                <InputLabelTextDefault
                                        :placeholder="__('John Doe')"
                                        color="main"
                                        v-model="name"
                                        v-bind:errors="formValidate.name"
                                        @change="updateValidate('name')"
                                ></InputLabelTextDefault>
                                <!--<label for="email">Email address</label>-->
                                <!--<v-text-field label="Your email"></v-text-field>-->
                            </div>
                            <div class="wrap-input password">
                                <p class="text semi">{{__('Password')}}</p>
                                <InputLabelTextDefault
                                        :placeholder="__('web_signup_Enter password')"
                                        color="main"
                                        type="password"
                                        switch-type
                                        v-model="password"
                                        v-bind:errors="formValidate.password"
                                        @change="updateValidate('password')"
                                ></InputLabelTextDefault>
                            </div>
                            <div class="wrap-input password">
                                <p class="text semi">{{__('Repeat Password')}}</p>
                                <InputLabelTextDefault
                                        :placeholder="__('Repeat your password here')"
                                        color="main"
                                        type="password"
                                        switch-type
                                        v-model="password_confirm"
                                        v-bind:errors="formValidate.password_confirm"
                                        @change="updateValidate('password_confirm')"
                                ></InputLabelTextDefault>
                            </div>

<!--                            <div class="sign-text">-->
<!--                                <div class="checkbox-site">-->
<!--                                    <v-checkbox v-model="sign" disabled></v-checkbox>-->
<!--                                </div>-->
<!--                                <p>{{__('web_signup_I read')}} <router-link :to="{name: 'privacy', params:{lang: $store.getters.getLang}}">{{__('web_signup_Privacy Police')}}</router-link> {{__('web_signup_and accept it lorem ipsum dolor sit amet')}}</p>-->
<!--                            </div>-->

                            <div class="input-sing">
                                <div class="wrap-sing">
                                    <v-btn
                                            class="btn-site"
                                            height="54px"
                                            style="width: 100%"
                                            v-on:click="openCaptcha"
                                            :loading="submitDisabled"
                                    >
                                        <span>
                                          {{__('Continue')}}
                                        </span>
                                    </v-btn>
                                </div>
                            </div>
                            <div class="block-bottom">
                                <p>{{__('Already a member?')}} </p>
                                <router-link :to="{name: 'login'}" class="text-primary bold">Sign in</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="left"></div>
        </div>
        <Captcha v-if="captcha" @onSubmit="onSubmit"></Captcha>
    </div>
</template>


<script>

    // import VuePhoneNumberInput from 'vue-phone-number-input';
    // import 'vue-phone-number-input/dist/vue-phone-number-input.css';

    import Captcha from "../modules/Captcha";
    import InputLabelTextDefault from "../modules/InputLabelTextDefault";
    import {
        required,
        email,
        maxLength,
        minLength, sameAs,
    } from "vuelidate/lib/validators";

    export default {
        name: "AuthSignup",
        data() {
            return {
                sign: true,
                captcha: false,
                name: null,
                email: null,
                password: null,
                password_confirm: null,
                submitDisabled: false,
                formValidate: {
                    email: null,
                    name: null,
                    password: null,
                    password_confirm: null,
                },
            };
        },
        props: {
            label: {
                type: String,
            },
        },
        validations: {
            name: {
                required,
                maxLength: maxLength(50),
                minLength: minLength(3),
            },
            email: {
                required,
                email,
            },
            password: {
                required,
                maxLength: maxLength(50),
                minLength: minLength(6),
            },
            password_confirm: {
                required,
                sameAsPassword: sameAs(function() {
                    if(this.password_confirm && this.password_confirm.length) {
                        return (this.password_confirm== this.password) ? this.password_confirm : false;
                    } else {
                        return this.password_confirm;
                    }
                }),
            },
        },
        methods: {
            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v[type],
                    type
                );
                // this.$nextTick(() => {
                // });
            },
            openCaptcha() {
                let valid = this.$v;

                if (valid.$invalid) {
                    let massages = {
                        name: [],
                        email: [],
                        password: []
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.captcha= true;
                }
            },
            onSubmit: function (session, arrayData) {
                let valid = this.$v;

                if (valid.$invalid) {
                    let massages = {
                        name: [],
                        email: [],
                        password_confirm: [],
                        password: []
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;
                    const response = {
                        name: this.name,
                        email: this.email,
                        password: this.password,
                        password_confirmation: this.password_confirm,
                        challenge: session,
                    };

                    response[session]= arrayData;

                    this.$http
                        .post("ajax/register", response)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            this.submitDisabled = false;
                            if(response.data.security) {
                                this.$store.dispatch('asyncUser', response.data);
                                if(this.$route.params.page != 'security') {
                                    this.$router.push({name: 'security'});
                                }
                            } else {
                                window.loginUser = true;
                                location.reload();
                            }
                        })
                        .catch((err) => {
                            this.submitDisabled = false;
                            let response = this.httpHandler(err);
                            this.captcha= false;
                            if (response.error) {
                                this.formValidate = response.error.message;
                            }
                        });
                }
            },
        },
        computed: {
            page: function () {
                return this.$route.name;
            },
        },
        components: {
            // VuePhoneNumberInput,
            InputLabelTextDefault,
            Captcha
        },
    };
</script>

<style lang="scss" scoped>

</style>