import Vue from "vue";
import routes from './routes/routes';
import store from './store';
import countries from "./countries";
import moment from 'moment';


Vue.prototype.$countries = countries;


Vue.prototype.$path = (process.env.NODE_ENV === 'production') ? Vue.prototype.$APP_URL + '/' : 'http://localhost:3000/';
Vue.prototype.$token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

Vue.prototype.$payment = (payment_id)=> {
    let payment= store.getters.wallets;
    return Vue.prototype.$filterArray(payment, payment_id, 'id');
};

Vue.prototype.$paymentLink = {
    "BTC": 'https://www.blockchain.com/btc/address/',
    "LTC": 'https://live.blockcypher.com/ltc/address/',
    "ERC20": 'https://etherscan.io/address/',
    "BEP20": 'https://bscscan.com/address/',
    "TRC20": 'https://tronscan.org/#/address/'
};

Vue.prototype.$txLink = {
    "BTC": 'https://www.blockchain.com/btc/tx/',
    "LTC": 'https://live.blockcypher.com/ltc/tx/',
    "ERC20": 'https://etherscan.io/tx/',
    "BEP20": 'https://bscscan.com/tx/',
    "TRC20": 'https://tronscan.org/#/transaction/'
};

Vue.prototype.$ellipsisText = (str, length = 30,  trimAmount = 10) => {
    if (str.length > length) {
        return str.substr(0, trimAmount) + '...' + str.substr(str.length - trimAmount, str.length);
    }
    return str;
};


Vue.prototype.$primNum = (string, args= 2) => {
    let value;
    if(args !== null) {

        let primSplit = Number(string).toFixed(args + 1).replace(',','.').split('.');

        if(primSplit[1] && primSplit[1].length > args) {
            primSplit[1] = primSplit[1].slice( 0, args);
            value = Number(primSplit[0]+'.'+primSplit[1]);
        } else {
            value = Number(string);
        }
        let val = value.toFixed(args);
        if (val.replace(',','.').split('.')[1] > 0) {
            value = val.replace(/0*$/,"");
        } else if (args > 2 && val.replace(',','.').split('.')[1] == 0) {
            value = Number(val).toFixed(2);
        } else {
            value = val;
        }
        // let sprintf= Math.pow(10, args);
        // value= (Math.round(Number(string) * sprintf) / sprintf).toFixed(args);
    } else {
        let splitValue = string.toLocaleString('en').split(','),
            decimal= '';

        switch (splitValue.length-1) {
            case 1:
                decimal= 'K';
                break;
            case 2:
                decimal= 'M';
                break;
            case 3:
                decimal= 'G';
                break;
            case 4:
                decimal= 'T';
                break;
            case 5:
                decimal= 'P';
                break;
            case 6:
                decimal= 'E';
                break;
            case 7:
                decimal= 'Z';
                break;
            case 8:
                decimal= 'Y';
                break;
        }

        value= splitValue[0]+decimal.toLowerCase();
    }

    return value;
};


Vue.prototype.httpHandler = (response) => {
    if (response.bodyText) {
        response = JSON.parse(response.bodyText);
        switch (response.status) {
            case 200:
                return response;
            case 401:
            case 403:
            case 419:
                location.reload();
                return false;
            case 404:
                routes.push({ name: 'not_found' });
                return false;
        }
        if (response.error && response.error.message instanceof Object) {
            return response;
        } else if (response.error) {
            Vue.notify({
                group: 'message',
                title: response.error.message,
                type: 'error'
            });
        } else {
            return false;
        }
    } else {
        return false;
    }
};


    Vue.prototype.$filterArray = (array, value, key= 'id', get= 'first') => {
    if(array) {

        let arr = array.filter(item => {
            if (value instanceof Array) {
                let check = true;
                for (let x = 0; x < value.length; x++) {
                    let attr = value[x],
                        condition;
                    switch (attr.operator) {
                        case '!=':
                            condition = item[attr.key] == attr.value;
                            break;
                        case '>':
                            condition = item[attr.key] > attr.value;
                            break;
                        case '<':
                            condition = item[attr.key] < attr.value;
                            break;
                        case '>=':
                            condition = item[attr.key] >= attr.value;
                            break;
                        case '<=':
                            condition = item[attr.key] <= attr.value;
                            break;
                        default:
                            condition = item[attr.key] != attr.value;
                    }

                    if (condition) {
                        return false
                    }
                }
                return check;
            } else {
                if (key instanceof Array) {
                    for (let x = 0; x < key.length; x++) {
                        item= item[key[x]];
                    }
                    return item == value;
                } else {
                    return item[key] == value;
                }
            }
        });
        if (get == 'first') {
            return (arr.length) ? arr[0] : null;
        } else if (get == 'last') {
            return (arr.length) ? arr[arr.length - 1] : null;
        } else {
            return (arr.length) ? arr : null;
        }
    }

    return null;
};


Vue.prototype.$filterIndexObject = (objects, value, key = 'id') => {
    if (objects instanceof Object) {
        return objects.map(function (e) { return String(e[key]); }).indexOf(String(value));
    } else {
        return null;
    }
};
Vue.prototype.$sortObject = (array, key, sub_key=false, sort='asc') => {
    return array.sort(function (a, b) {
        if(sub_key) {
            if(sort== 'desc') {
                return a[key][sub_key]-b[key][sub_key]
            } else {
                return b[key][sub_key]-a[key][sub_key]
            }
        } else {
            if(sort== 'desc') {
                return a[key]-b[key];
            } else {
                return b[key]-a[key];
            }
        }
    })
};

Vue.prototype.$arraySortPercent = (array, key, sub_key=false) => {
    let percent= [];
    let color= [];
    let sort_array= Vue.prototype.$sortObject(array, key, sub_key);
    let summ= 0;
    for (let i = 0; i < sort_array.length; i++) {
        let item= sort_array[i];

        if(sub_key) {
            summ= Number(summ)+ Number(item[key][sub_key]);
        } else {
            summ= Number(summ)+ Number(item[key]);
        }
    }

    for (let i = 0; i < sort_array.length; i++) {
        let item= sort_array[i];
        if(sub_key) {
            if(Number(item[key][sub_key]) > 0) {
                sort_array[i]['percent']= item[key][sub_key]/(summ/100);
            } else {
                sort_array[i]['percent']= 0;
            }
        } else {
            if(Number(item[key]) > 0) {
                sort_array[i]['percent']= item[key]/(summ/100);
            } else {
                sort_array[i]['percent']= 0;
            }
        }

        percent[i]= sort_array[i]['percent'];
        color[i]= Vue.prototype.$walletColors[item.payment.name_class];
    }
    if(summ== 0) {
        percent= [100];
        color= ['#F1F7FF'];
    }
    return {
        summ: summ,
        sort_array: sort_array,
        percent: percent,
        colors: color
    };
};

Vue.prototype.$cleanObject= (queryParams)=> {
    // const cleanParams = Object.keys(queryParams)
    //     .filter(key => (queryParams[key] != null))
    //     .reduce((acc, key) => Object.assign(acc, { [key]: queryParams[key] }), {});
    // return cleanParams;
    return (queryParams) ? JSON.parse(JSON.stringify(queryParams)) : null;
};
Vue.prototype.$cleanArray= (queryParams)=> {
    console.log('queryParams');
    console.log(queryParams);
    const cleanParams = queryParams.filter(function(x) {
        return x !== undefined && x !== null;
    });
    return cleanParams;
};




Vue.prototype.$validateMessage= {
    required: 'Required valid',
    integer: 'Integer valid',
    numeric: 'numeric valid',
    email: 'Email valid',
    decimal: 'Decimal valid',
    minLength: 'minLength :min valid',
    maxLength: 'maxLength :max valid',
    sameAsPassword: 'sameAs valid',
    url:  'url valid',
    sameAsActive: 'sameAsActive valid',
    minValue: 'minValue :min valid',
    maxValue: 'minValue :max valid',
    limit: 'Limit is exceeded',
};

Vue.prototype.$changeValidate = (formValidate, validate, type)=> {
    if(validate.$invalid) {
        formValidate[type]= [];
        for(var prop in validate.$params) {
            if(!validate[prop]) {
                let msg= Vue.prototype.__(Vue.prototype.$validateMessage[prop], validate.$params[prop]);
                formValidate[type].push(msg);
            }
        }
    } else {
        formValidate[type]= null;
    }
    return formValidate;
};
Vue.prototype.$formValidate = (massages, validator)=> {
    for (var type in massages) {
        let validate= validator[type];
        if(validate) {
            for(var prop in validate.$params) {
                if(!validate[prop]) {
                    let msg= Vue.prototype.__(Vue.prototype.$validateMessage[prop], validate.$params[prop]);
                    massages[type].push(msg);
                }
            }
        }
    }

    return massages;
};
Vue.prototype.$toFixed = (number, fixed=2)=> {
    return Number(Number(number).toFixed(fixed));
};
Vue.prototype.$opp_type = {
    1: 'Depoit',
    2: 'Withdraw',
    3: 'Buy plan',
    4: 'Profit plan',
    5: 'Referral',
    6: 'Reinvest',
};
Vue.prototype.$historyStatus = {
    1: {
        'status': 'complete',
        'class_name': 'success'
    },
    2: {
        'status': 'error',
        'class_name': 'error'
    },
    3: {
        'status': 'waiting',
        'class_name': 'waiting'
    },
    4: {
        'status': 'confirmation',
        'class_name': 'waiting'
    },
    5: {
        'status': 'cancel',
        'class_name': 'default'
    },
    6: {
        'status': 'end time',
        'class_name': 'error'
    },
    7: {
        'status': 'waiting',
        'class_name': 'wait'
    },
};
Vue.prototype.$balance_type = {
    1: '+',
    2: '-'
};
Vue.prototype.$withdrawStatus = {
    1: {
        'status': 'complete',
        'class_name': 'success'
    },
    2: {
        'status': 'wait',
        'class_name': 'waiting'
    },
    3: {
        'status': 'wait',
        'class_name': 'waiting'
    },
    4: {
        'status': 'canceled',
        'class_name': 'error'
    },
    5: {
        'status': 'wait',
        'class_name': 'waiting'
    },
    6: {
        'status': 'error',
        'class_name': 'error'
    }
};

Vue.prototype.$redirect = (code= 404)=> {
    switch (code) {
        default:
            routes.push({ name: 'not_found' });
            break;
    }
};
Vue.prototype.$sumObject = (col, object)=> {
    let sum= 0;
    Object.keys(object).forEach(key => {
        sum= sum+ Number(object[key][col]);
    });
    return sum;
};
Vue.prototype.$maxPercent = 10;
Vue.prototype.$walletColors = {
    btc: '#FC9A28',
    eth: '#4A84FF',
    usdt: '#4FD984',
    ltc: '#BCBCBC',
    doge: '#D4BE00',
    xrp: '#2DA9E8',
    link: '#2A5ADA',
    bch: '#8DC351',
    trx: '#C3332D',
    pm: '#FF0000',
    payeer: '#2A93FF',
    etc: '#1B8600',
    dai: '#F9AB11',
    usdt_trc: '#C3332D',
};


Vue.prototype.$random = (arr)=> {
    let j,
        temp;

    for(var i = arr.length - 1; i > 0; i--){
        j = Math.floor(Math.random()*(i + 1));
        temp = arr[j];
        arr[j] = arr[i];
        arr[i] = temp;
    }
    return arr;
};




Vue.prototype.$splitNum= (num, sprintf= false)=> {
    if(sprintf) {
        num= Vue.prototype.$primNum(num, sprintf);
    }

    let split= num.split('.');
    return "<span>"+split[0]+".</span><span class='counter'>"+split[1]+"</span>";
};
Vue.prototype.$copyMessage = (copy)=> {
    let body = document.getElementsByTagName('body')[0];
    let tempInput = document.createElement('INPUT');
    body.appendChild(tempInput);
    tempInput.setAttribute('value', copy)
    tempInput.select();
    document.execCommand('copy');
    body.removeChild(tempInput);
};

Vue.prototype.$toTime = (timestamp)=> {
    let hours = Math.floor(timestamp / 60 / 60);
    let minutes = Math.floor(timestamp / 60) - (hours * 60);
    let seconds = timestamp % 60;

    let formatted= [];

    if(hours) {
        formatted.push(hours.toString().padStart(2, '0'));
    }
    formatted.push(minutes.toString().padStart(2, '0'));
    formatted.push(seconds.toString().padStart(2, '0'));

    return formatted.join(':');
};


var _eventHandlers = {};

Vue.prototype.$addListener = (node, event, handler, capture= false)=> {
    Vue.prototype.$removeAllListeners(node,'scroll');

    if(!(node in _eventHandlers)) {
        // _eventHandlers stores references to nodes
        _eventHandlers[node] = {};
    }
    if(!(event in _eventHandlers[node])) {
        // each entry contains another entry for each event type
        _eventHandlers[node][event] = [];
    }
    // capture reference
    _eventHandlers[node][event].push([handler, capture]);
    node.addEventListener(event, handler, capture);
};
Vue.prototype.$removeAllListeners = (node, event)=> {
    if(node in _eventHandlers) {
        var handlers = _eventHandlers[node];
        if(event in handlers) {
            var eventHandlers = handlers[event];
            for(var i = eventHandlers.length; i--;) {
                var handler = eventHandlers[i];
                node.removeEventListener(event, handler[0], handler[1]);
            }
        }
    }
};

Vue.filter('clearStr', function (text, search= /.\(.+\)/) {
    return text.replace(search,'');
});


Vue.prototype.$dataChart = (data, currency)=> {
    let chart= [];

    for(let  x=0; x<data.length; x++){
        let item= data[x];
        chart.push(item[currency.toUpperCase()]);
    }

    return chart;
};

Vue.prototype.$minAgo = (data) => {
    let now = moment.utc().format('X');

    let time = Math.round((now - data) / 60);
    let strTime;

    let timeAgo = time;
    if(time >= 518400) {
        strTime = "long";
        timeAgo = null;
    }else if(time >= 43200){
        strTime = "months";
        timeAgo = time/43200;
    }else if(time >= 1440){
        strTime = "days";
        timeAgo = time/1440;
    }else if(time >= 60){
        strTime = "hours";
        timeAgo = time/60;
    } else {
        strTime = "min";
    }
    return ((timeAgo) ? parseInt(timeAgo)+' ' : '') + strTime;
};
