<template>
   <v-dialog
           v-model="dialog" persistent max-width="640px"
           v-on:click:outside="modalCancel"
   >
       <div class="modal-verification">
            <BlockVerificationPersonalInfo v-if="step == 1" @modalCancel="modalCancel" :step.sync = "step" :formVerificationPersonal.sync="formVerificationPersonal" :formVerificationDocument.sync="formVerificationDocument"/>
            <BlockVerificationDocumentInfo v-else-if="step == 2" @modalCancel="modalCancel" :step.sync = "step" :formVerificationDocument.sync="formVerificationDocument"/>
            <BlockVerificationFrontDocument v-else-if="step == 3" @modalCancel="modalCancel" :step.sync = "step" :formVerificationDocument.sync="formVerificationDocument"/>
            <BlockVerificationBackDocument v-else-if="step == 4" @modalCancel="modalCancel" :step.sync = "step" :formVerificationDocument.sync="formVerificationDocument"/>
       </div>
   </v-dialog>
</template>

<script>
    import BlockVerificationPersonalInfo from "./BlockVerificationPersonalInfo";
    import BlockVerificationDocumentInfo from "./BlockVerificationDocumentInfo";
    import BlockVerificationFrontDocument from "./BlockVerificationFrontDocument";
    import BlockVerificationBackDocument from "./BlockVerificationBackDocument";

    export default {
        name: 'ModalVerification',
        data: function () {
            let user = this.$store.getters.user;
            let application= user.application;

            let pesonalVer= (application) ?  {
                first_name: application.first_name,
                middle_name: application.middle_name,
                last_name: application.last_name,
                dob: application.dob,
                residence_country: application.residence_country,
                gender: application.gender,
            } :  {
                first_name: null,
                middle_name: null,
                last_name: null,
                dob: null,
                residence_country: null,
                gender: null,
            };

            let document= (application && application.documents.length) ? application.documents[0] : null;

            let documentVer= (document) ? {
                type: document.type,
                applicant_id: document.applicant_id,
                document_number: document.document_number,
                issuing_authority: document.issuing_authority,
                issue_date: document.issue_date,
                expiry_date: document.expiry_date,
                front_side_id: document.front_side_id,
                back_side_id: document.back_side_id,
                front_side: document.front_side,
                back_side: document.back_side,
                other_side_1_id: document.other_side_1_id,
                other_side_2_id: document.other_side_2_id,
            } : {
                type: null,
                applicant_id: null,
                document_number: null,
                issuing_authority: null,
                issue_date: null,
                expiry_date: null,
                front_side_id: null,
                back_side_id: null,
                front_side: null,
                back_side: null,
                other_side_1_id: null,
                other_side_2_id: null,
            };


            return {
                dialog: true,
                step: 1,
                formVerificationPersonal: pesonalVer,
                formVerificationDocument: documentVer
            }
        },
        props: {
           isVisible: {
               type: Boolean,
           }
        },

        computed: {
            user() {
                return this.$store.getters.user
            },
        },

        created() {

        },
        methods: {
            modalCancel() {
                this.$emit('update:isVisible', false)
            },

        },
        components: {
            BlockVerificationPersonalInfo,
            BlockVerificationDocumentInfo,
            BlockVerificationFrontDocument,
            BlockVerificationBackDocument
        }
    }
</script>

<style>

</style>
