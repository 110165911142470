<template>
    <div class="page-withdraw">
        <div class="block-head">
            <div class="block-head__left">
                <p class="head-title">{{__('web_page_withdraw_title')}}</p>
            </div>

            <div class="block-head__right">

            </div>
        </div>

        <div class="block-body">
            <div class="block-body__left">
                <div class="block-coin">
                    <p class="block-coin__title">{{__('web_input_label_coin')}}</p>
                    <DropdownDefault
                            v-bind:items="dropdownWallets"
                            v-model="wallet"
                            dropSlot
                    >
                        <template v-slot:selection="item">
                            <img :src=walletsGroup[item.name][0].img>
                            {{walletsGroup[item.name][0].name | clearStr}} ({{item.name}})
                        </template>
                        <template v-slot:item="item">
                            <div class="block-coin">
                                <img :src=walletsGroup[item.name][0].img>
                                {{walletsGroup[item.name][0].name | clearStr}}
                            </div>
                            <div class="block-amount">
                                <p>{{sumBalance(walletsGroup[item.name])['coin']}} {{item.name}}</p>
                                <span v-if="auth.currency == 'EUR'">{{$primNum(sumBalance(walletsGroup[item.name])['usd'] * auth.course_eur)}} EUR</span>
                                <span v-else>{{sumBalance(walletsGroup[item.name])['usd']}} USD</span>
                            </div>
                        </template>
                    </DropdownDefault>
                </div>

                <div class="block-address">
                    <p class="block-address__title">{{__('web_input_label_address')}}</p>
                    <InputLabelTextDefault
                            v-model="form.address"
                            v-bind:errors="formValidate.address"
                            @change="updateValidate('address')"
                            :placeholder="__('web_input_placeholder_enter_wallet_address')"
                    >
                    </InputLabelTextDefault>
                </div>

                <div v-if="networks.length" class="block-network">
                    <p class="block-network__title">{{__('web_input_label_network')}}</p>

                    <div class="block-dropdown" v-on:click="modal_select_network = true">
                        <p class="block-dropdown__text">{{network.sub}}</p>
                        <img src="/dist/images/arrow-dropdawn.svg" alt="">
                    </div>
                </div>

                <div v-if="walletsGroup[wallet.name][0].symbol.toLowerCase() == 'xrp'" class="block-tag">
                    <p class="block-tag__title">{{__('web_input_label_tag')}}</p>
                    <InputLabelTextDefault
                            v-model="form.tag"
                            v-bind:errors="formValidate.tag"
                            @change="updateValidate('tag')"
                            :placeholder="__('web_input_placeholder_enter_tag')"
                    >
                    </InputLabelTextDefault>

                    <p class="block-tag__info">{{__('web_text_tag_info')}}</p>
                </div>

                <div class="block-amount">
                    <div class="block-amount__head">
                        <p class="block-amount__title">{{__('web_input_label_withdraw_amount')}}</p>

                        <p v-if="network" class="block-amount__info">
                            {{__('web_input_info_available')}}
                            <span @click="pasteAmount">{{$primNum(network.balance, network.decimals)}} {{network.symbol}}</span>
                        </p>
                    </div>

                    <InputLabelTextDefault
                            type="number"
                            v-model="form.amount"
                            placeholder="0"
                            v-bind:errors="formValidate.amount"
                            @change="updateValidate('amount')"
                    >
                        <template slot="right">
                            <span class="right">{{walletsGroup[wallet.name][0].symbol}}</span>
                        </template>
                    </InputLabelTextDefault>

                    <p  class="block-amount__commission">
                        {{__('web_input_info_commission')}}

                        <span v-if="network">{{$primNum(commission, network.decimals)}} {{walletsGroup[wallet.name][0].symbol}}</span>
                    </p>
                </div>

                <v-btn
                    style="width: 100%"
                    :loading="submitDisabled"
                    class="btn-site"
                    v-on:click="onSubmit(false)"
                >
                    {{__('web_btn_continue')}}
                </v-btn>
            </div>

<!--            <div class="block-body__right">-->
<!--                <div class="block-info">-->
<!--                    <p class="block-info__title">{{__('web_page_withdraw_block_info_title')}}</p>-->

<!--                    <p class="block-info__desc">{{__('web_page_withdraw_block_info_desc')}}</p>-->

<!--                    <div class="block-notifications">-->
<!--                        <div class="block-notifications__item">-->
<!--                            <div class="dot"></div>-->
<!--                            <p>{{__('web_page_withdraw_block_info_item_1')}}</p>-->
<!--                        </div>-->

<!--                        <div class="block-notifications__item">-->
<!--                            <div class="dot"></div>-->
<!--                            <p>{{__('web_page_withdraw_block_info_item_2')}}</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>

        <ModalDefault v-if="modal_select_network" width="430" v-bind:modal_cancel.sync="modal_select_network">
            <div class="modal-select-network">
                <div class="modal-select-network__head">
                    <p>{{__('web_modal_select_network_title')}}</p>

                    <v-btn v-ripple="false" text v-on:click="modal_select_network = false">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.71209 3.71209C4.0782 3.34597 4.6718 3.34597 5.03791 3.71209L10 8.67418L14.9621 3.71209C15.3282 3.34597 15.9218 3.34597 16.2879 3.71209C16.654 4.0782 16.654 4.6718 16.2879 5.03791L11.3258 10L16.2879 14.9621C16.654 15.3282 16.654 15.9218 16.2879 16.2879C15.9218 16.654 15.3282 16.654 14.9621 16.2879L10 11.3258L5.03791 16.2879C4.6718 16.654 4.0782 16.654 3.71209 16.2879C3.34597 15.9218 3.34597 15.3282 3.71209 14.9621L8.67418 10L3.71209 5.03791C3.34597 4.6718 3.34597 4.0782 3.71209 3.71209Z" fill="#999999"/>
                        </svg>
                    </v-btn>
                </div>

                <div class="modal-select-network__body">
                    <div class="block-aside">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.1 10.5C11.1 10.0029 11.5029 9.59995 12 9.59995C12.497 9.59995 12.9 10.0029 12.9 10.5V13.5C12.9 13.997 12.497 14.4 12 14.4C11.5029 14.4 11.1 13.997 11.1 13.5V10.5Z" fill="#F29829"/>
                            <path d="M12 18C12.6627 18 13.2 17.4627 13.2 16.8C13.2 16.1372 12.6627 15.6 12 15.6C11.3372 15.6 10.8 16.1372 10.8 16.8C10.8 17.4627 11.3372 18 12 18Z" fill="#F29829"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1.19995C12.4431 1.19995 12.8502 1.44421 13.0588 1.83525L22.6588 19.8352C22.8571 20.2072 22.8458 20.6559 22.6289 21.0173C22.4121 21.3788 22.0215 21.6 21.6 21.6H2.39995C1.97844 21.6 1.58783 21.3788 1.37096 21.0173C1.15409 20.6559 1.14277 20.2072 1.34113 19.8352L10.9411 1.83525C11.1497 1.44421 11.5568 1.19995 12 1.19995ZM19.6 19.1999L12 4.94995L4.39995 19.1999H19.6Z" fill="#F29829"/>
                        </svg>

                        <p>{{__('web_modal_select_network_info')}}</p>
                    </div>

                    <div v-if="networks.length" class="list-networks">
                        <div class="list-networks__item" v-for="(item, key) in networks" :key="key" :class="(item== network) ? 'list-networks__item--active' : ''" v-on:click="changeWallet(item)">
                            {{item.sub}}
                        </div>
                    </div>
                </div>
            </div>
        </ModalDefault>

        <ModalDefault v-if="modal_confirm_withdraw" width="430" v-bind:modal_cancel.sync="modal_confirm_withdraw">
            <div class="modal-confirm-withdraw">
                <div class="modal-confirm-withdraw__head">
                    <div v-if="!isDone" class="header">
                        <p>{{__('web_modal_confirm_withdraw_title')}}</p>

                        <v-btn v-ripple="false" text v-on:click="modal_confirm_withdraw = false">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.71209 3.71209C4.0782 3.34597 4.6718 3.34597 5.03791 3.71209L10 8.67418L14.9621 3.71209C15.3282 3.34597 15.9218 3.34597 16.2879 3.71209C16.654 4.0782 16.654 4.6718 16.2879 5.03791L11.3258 10L16.2879 14.9621C16.654 15.3282 16.654 15.9218 16.2879 16.2879C15.9218 16.654 15.3282 16.654 14.9621 16.2879L10 11.3258L5.03791 16.2879C4.6718 16.654 4.0782 16.654 3.71209 16.2879C3.34597 15.9218 3.34597 15.3282 3.71209 14.9621L8.67418 10L3.71209 5.03791C3.34597 4.6718 3.34597 4.0782 3.71209 3.71209Z" fill="#999999"/>
                            </svg>
                        </v-btn>
                    </div>

                    <div v-if="isDone" class="block-success">
                        <div class="block-success__icon">
                            <svg width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5377 0.293518C26.9279 0.684386 26.9274 1.31755 26.5365 1.70773L9.97341 18.2416C9.58279 18.6315 8.9501 18.6312 8.55982 18.241L0.292893 9.97404C-0.0976311 9.58351 -0.0976311 8.95035 0.292893 8.55982C0.683418 8.1693 1.31658 8.1693 1.70711 8.55982L9.26755 16.1203L25.1235 0.292269C25.5144 -0.0979101 26.1476 -0.0973512 26.5377 0.293518Z" fill="#01AA4F"/>
                            </svg>
                        </div>

                        <p class="block-success__text">{{__('web_modal_confirm_withdraw_block_success_text')}}</p>
                    </div>

                    <div v-else class="icon-withdraw">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.83269 20.5215L7.47769 22.1665L19.8327 9.81151L19.8327 17.4998L22.166 17.4998L22.166 5.83318L10.4994 5.83317L10.4994 8.16651L18.1877 8.16651L5.83269 20.5215Z" fill="#F51E38"/>
                        </svg>
                    </div>

                    <div class="block-amount">
                        <p>{{$primNum(form.amount, network.decimals)}} {{network.symbol}}</p>
                        <span v-if="auth.currency == 'EUR'">€{{$primNum(+form.amount * courses[network.symbol] * auth.course_eur)}}</span>
                        <span v-else>${{$primNum(+form.amount * courses[network.symbol])}}</span>
                    </div>


                </div>

                <div class="modal-confirm-withdraw__body">
                    <div class="list-info">
                        <div class="list-info__item">
                            <span>{{__('web_text_from')}}</span>
                            <p>{{__('web_text_my')}} {{network.name | clearStr}} {{__('web_text_wallet')}}</p>
                        </div>

                        <div class="list-info__item">
                            <span>{{__('web_text_withdraw_to')}}</span>
                            <p>{{$ellipsisText(form.address, 25, 10)}}</p>
                        </div>

                        <div class="list-info__item">
                            <span>{{__('web_page_transaction_info_Network')}}</span>
                            <p>{{network.name}} ({{network.sub}})</p>
                        </div>

                        <div class="list-info__item">
                            <span>{{__('web_text_amount')}}</span>
                            <p>{{$primNum(form.amount, network.decimals)}} {{network.symbol}}</p>
                        </div>

                        <div class="list-info__item">
                            <span>{{__('web_text_commission')}}</span>
                            <p>{{$primNum(modal_confirm_withdraw.commission, network.decimals)}} {{network.symbol}}</p>
                        </div>
                    </div>
                    <v-btn
                        v-if="isDone"
                        style="width: 100%"
                        class="btn-site"
                        v-on:click="doneWithdraw"
                    >
                        {{__('web_btn_done')}}
                    </v-btn>

                    <v-btn
                        v-else
                        style="width: 100%"
                        :loading="submitDisabled"
                        class="btn-site"
                        v-on:click="onSubmit(true)"
                    >
                        {{__('web_btn_confirm_withdraw')}}
                    </v-btn>
                </div>
            </div>
        </ModalDefault>
    </div>
</template>

<script>
    // import DotLoader from 'vue-spinner/src/DotLoader.vue';
    import DropdownDefault from "../modules/DropdownDefault";
    import ModalDefault from "../modules/ModalDefault";
    import {maxValue, required, decimal, minValue} from "vuelidate/lib/validators";
    import InputLabelTextDefault from "../modules/InputLabelTextDefault";
    import _ from 'lodash';

    export default {
        name: 'CardSwapWithdraw',

        data: function() {
            return {
                wallet: null,
                networks: [],
                network: null,
                modal_select_network: false,
                form: {
                    address: null,
                    tag: null,
                    amount: null
                },
                formValidate: {
                    address: null,
                    tag: null,
                    amount: null
                },
                submitDisabled: false,
                commission: 0,
                modal_confirm_withdraw: false,
                isDone: false,
                wallets: null,
                walletsGroup: null,
            }
        },

        validations() {
            return {
                form: {
                    address: {
                        required
                    },
                    tag: {
                        required: ((this.walletsGroup[this.wallet.name][0].symbol.toLowerCase() == 'xrp')? required : false)
                    },
                    amount: {
                        required,
                        decimal,
                        maxValue: maxValue(Number(this.network.balance) - this.commission),
                        minValue: minValue(0.0001),
                    },
                },
            }
        },

        watch:{
            wallet(val) {
                // this.networks = this.walletsGroup[val.name];
                let sortArr = _.orderBy(this.walletsGroup[val.name], item => item.balance * this.courses[item.symbol], ['desc']);
                this.networks = sortArr;
                this.changeWallet(sortArr[0]);
            },
            network() {
               this.calculateCommission();
            }
        },
        computed: {
            dropdownWallets() {
                let array = [];
// Sort wallet on balance
                let sortWallets = _.orderBy(this.wallets, item => item.balance * this.courses[item.symbol], ['desc']);
                let groupSymbol = _.groupBy(sortWallets, 'symbol');
                let walletsArray = Object.keys(groupSymbol);
//

                // let walletsArray = Object.keys(this.walletsGroup);

                walletsArray.forEach((item, key) => {
                    let object = {
                        name: item,
                        id: key + 1,
                    };

                    array.push(object);
                });
                return array
            },
            auth() {
                return this.$store.getters.user
            },
            courses() {
                return this.$store.getters.courses
            },
            wallet_list() {
                return this.$route.params.wallet_list;
            },
        },

        created() {
            this.getBalance();

            this.sockets.subscribe('updateTransaction', ()=> {
                this.getBalance();
            });
        },

        methods: {

            getBalance() {
                this.$http[(process.env.NODE_ENV === 'production') ? 'get' : 'get']('ajax/cardswap/balance')
                    .then(response => {
                        return response.json()
                    })
                    .then((response) => {
                        this.wallets= response.data;

                        this.walletsGroup= _.groupBy(this.wallets, 'symbol')

                        this.wallet = this.dropdownWallets[0];
                    }).catch((err) => {
                    this.httpHandler(err);
                });
            },
            changeWallet(wallet) {
                this.network = wallet;
                this.modal_select_network = false;
            },
            updateValidate: function (type) {
                this.formValidate= this.$changeValidate(this.formValidate, this.$v.form[type], type);
            },
            calculateCommission() {
                // let commission = +value/100 * +this.auth.percent_out;
                // if(+commission < +this.network.commission_out) {
                //     commission = +this.network.commission_out;
                // }
                this.commission = (Number(this.auth.percent_out) / Number(this.courses[this.network.symbol])) + Number(this.network.commission_out);
            },

            pasteAmount() {
                this.calculateCommission();

                let amount = this.$primNum(this.network.balance - this.commission, this.network.decimals);

                if (amount > 0) {
                    this.form.amount = amount;
                } else {
                    this.form.amount = 0;
                }
            },
            onSubmit(send = false) {
                let valid = this.$v.form;

                if (valid.$invalid) {
                    let massages = {
                        address: [],
                        tag: [],
                        amount: [],
                    };

                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;
                    let form = this.$cleanObject(this.form);
                    let request = {
                        send: (send) ? 1 : 0,
                        address: form.address,
                        wallet_id: this.network.id,
                        tag: form.tag,
                        amount: this.$primNum(form.amount, this.network.decimals),
                    };

                    // if(send && ['BEP20'].includes(this.wallet.sub)) {
                    //     request['gasPrice']= send.gasPrice;
                    // }

                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/cardswap/withdraw/create', request)
                        .then(response => {
                            return response.json()
                        })
                        .then((response) => {
                            this.submitDisabled= false;
                            if (send) {
                                this.isDone = true;
                            } else {
                                this.modal_confirm_withdraw = response.data;
                            }
                        }).catch((err) => {

                        this.submitDisabled= false;
                        let response = this.httpHandler(err);

                        if(response.error) {
                            this.formValidate = response.error.message;
                        }
                    });
                }
            },
            doneWithdraw () {
                this.modal_confirm_withdraw = null;
                this.isDone = false;
                this.form.address = null;
                this.form.tag = null;
                this.form.amount = null;
                this.formValidate.address = null;
                this.formValidate.tag = null;
                this.formValidate.amount = null;
                this.wallet = this.dropdownWallets[0];

                this.$emit('update:withdrawWindow', false)
            },

            sumBalance(wallet) {
                let wallets = this.$cleanObject(wallet);
                let course_usd = this.courses[wallets[0].symbol];
                let coin = _.sumBy(wallets, 'balance');

                return {
                    coin: this.$primNum(coin, wallets[0].decimals),
                    usd: this.$primNum(coin*course_usd)
                }
            },
        },
        components: {
            DropdownDefault,
            // DotLoader,
            ModalDefault,
            InputLabelTextDefault,
        }
    }
</script>

<style scoped>

</style>
