<template>
    <div class="referral3lvl-item">
        <div class="left">
            <div class="img">
                <p>3</p>
            </div>
            <div class="block-user">
                <p class="block-user__name">{{item.name}} {{item.last_name}}</p>
                <!--            <p class="block-user__email">{{item.email}}</p>-->
            </div>
        </div>
        <div>
            <p class="block-profit">{{user.course_current.course_marker}} {{$primNum(item.profit * user.course_current.course_current)}}</p>
            <p class="desc">Profit</p>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'ReferralItem3lvl',
        data () {
            return {

            }
        },
        props: {
            item: {
                type: Object
            },
        },
        computed: {
            user() {
                return this.$store.getters.user
            },
        },
        methods: {

        },
        components: {

        },
    }
</script>

<style lang="scss">

</style>