export default {
    state: {
        wallets: null,
        walletsGroup: null,
        exchange: null,
        transaction: null,
        courses: null,
        charts: null,
        resp_tickets: null,
    },
    getters: {
        wallets(state){
            return state.wallets;
        },
        walletsGroup(state){
            return state.walletsGroup;
        },
        exchange(state){
            return state.exchange;
        },
        transaction(state){
            return state.transaction;
        },
        courses(state){
            return state.courses;
        },
        charts(state){
            return state.charts;
        },
    },
    actions: {
        asyncWallets(context, payload) {
            context.commit('updateWallets', payload);
        },
        asyncWalletsGroup(context, payload) {
            context.commit('updateWalletsGroup', payload);
        },
        asyncCourses(context, payload) {
            context.commit('updateCourses', payload);
        },
        asyncCharts(context, payload) {
            context.commit('updateCharts', payload);
        },
        asyncExchange(context, payload) {
            context.commit('updateExchange', payload);
        },
        asyncTransaction(context, payload) {
            context.commit('updateTransaction', payload);
        },
        asyncResp(context, payload) {
            context.commit('updateResp', payload);
        },
    },
    mutations: {
        updateWallets(state, data) {
            this.state.preference.wallets= data;
        },
        updateWalletsGroup(state, data) {
            this.state.preference.walletsGroup= data;
        },
        updateExchange(state, data) {
            this.state.preference.exchange = data;
        },
        updateCharts(state, data) {
            this.state.preference.charts= data;
        },
        updateTransaction(state, data) {
            this.state.preference.transaction= data;
        },
        updateCourses(state, data) {
            this.state.preference.courses= data;
        },
        updateResp(state, data) {
            if([0,1].includes(data)) {
                this.state.preference.resp_tickets= data;
            }
        },
    }
}
