<template>
    <div class="block-chat" >
        <div class="chat-content" ref="messages_list" v-if="messages.total > 0" @scroll="scrollMessages(this)">
            <MessengerMessagesList
                v-bind:messages="messages"
                v-bind:user_id="user_id"
            ></MessengerMessagesList>
        </div>
        <div v-else class="no-result">
            <p>{{__("web_page_support_block_chat_hello")}} {{user.name}}, {{__("web_page_support_block_chat_no_result_text")}}</p>
        </div>
        <div ref="btn_list" class="chat-control">
            <InputLabelTextDefault
                ref="message"
                type="textarea"
                v-model="new_message"
                v-on:keydown.enter.prevent.exact="keypressMessages"
                v-on:keydown.shift.enter.prevent.exact="newLine"
                :placeholder="__('web_input_placeholder_enter_your_message')"
                maxlength="1000"
                :disabled="((ticket.status) ? true : false)"
            >
                <template v-slot:right class="send-btn">
                    <v-btn
                        v-if="ticket.subject== 'Card swap'"
                        class="btn-site"
                        v-on:click="modal_upload= true"
                        :disabled="((ticket.status) ? true : false)"
                        :loading="submitDisabled"
                        style="right: unset; left: 10px"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M10.306 22C8.32797 22 6.48696 21.245 5.11896 19.873C2.23596 16.982 2.27915 12.235 5.21515 9.29004L10.9432 3.54602C11.9382 2.54802 13.26 1.99902 14.668 1.99902C16.076 1.99902 17.3971 2.54802 18.3931 3.54602C20.4451 5.60402 20.4451 8.95103 18.3931 11.008L12.637 16.779C11.453 17.965 9.38817 17.967 8.20417 16.779C6.98417 15.555 6.98417 13.565 8.20417 12.342L13.2601 7.27203C13.6511 6.88103 14.2831 6.88002 14.6741 7.27002C15.0661 7.66002 15.0661 8.29302 14.6761 8.68402L9.62018 13.754C9.17718 14.199 9.17718 14.922 9.62018 15.367C10.0492 15.796 10.793 15.795 11.221 15.367L16.9771 9.59604C18.2521 8.31704 18.2521 6.23704 16.9771 4.95804C15.7431 3.72104 13.5942 3.72104 12.3602 4.95804L6.63214 10.702C4.50915 12.831 4.46595 16.384 6.53595 18.46C7.52495 19.452 8.86495 19.999 10.3069 19.999C11.7869 19.999 13.2312 19.404 14.2662 18.365L19.293 13.323C19.684 12.932 20.3161 12.931 20.7071 13.321C21.0991 13.711 21.099 14.344 20.709 14.735L15.6819 19.777C14.2719 21.19 12.313 22 10.306 22Z" fill="#171F2D"/>
                      </svg>
                    </v-btn>
                    <v-btn
                        class="btn-site"
                        :disabled="((ticket.status) ? true : false)"
                        v-on:click="sendMessage()"
                        :loading="submitDisabled"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M18.447 3.06295L5.33394 6.34096C2.20094 7.12396 2.23193 11.588 5.37593 12.328L9.45503 13.288C9.55603 13.312 9.66303 13.282 9.73603 13.208L14.4741 8.46996C14.7641 8.17996 15.2439 8.17996 15.5339 8.46996C15.8239 8.75996 15.8239 9.23996 15.5339 9.52996L10.7939 14.2699C10.7209 14.3429 10.6901 14.45 10.7141 14.551L11.673 18.626C12.413 21.77 16.8769 21.801 17.6599 18.668L20.9379 5.55495C21.3129 4.04895 19.95 2.68695 18.447 3.06295Z" fill="#98A2B3"/>
                        </svg>
                    </v-btn>
                </template>
            </InputLabelTextDefault>
        </div>

      <ModalDefault v-if="modal_upload" v-bind:modal_cancel.sync="modal_upload">
        <div class="window-site">
          <div class="body window-dep">
            <v-btn
                    text
                    v-ripple="false"
                    class="btn-close"
                    v-on:click="modal_upload= false"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.9397 12.0002L17.7997 7.14017C17.9089 7.01264 17.9659 6.84859 17.9595 6.68081C17.953 6.51302 17.8834 6.35386 17.7647 6.23513C17.646 6.1164 17.4868 6.04685 17.319 6.04037C17.1512 6.03389 16.9872 6.09096 16.8597 6.20017L11.9997 11.0602L7.13966 6.19351C7.01212 6.08429 6.84807 6.02722 6.68029 6.0337C6.5125 6.04018 6.35334 6.10974 6.23461 6.22847C6.11589 6.3472 6.04633 6.50635 6.03985 6.67414C6.03337 6.84192 6.09044 7.00597 6.19966 7.13351L11.0597 12.0002L6.19299 16.8602C6.1232 16.9199 6.06652 16.9935 6.02651 17.0762C5.98649 17.1589 5.964 17.249 5.96046 17.3408C5.95691 17.4326 5.97238 17.5242 6.0059 17.6097C6.03941 17.6953 6.09025 17.773 6.15522 17.8379C6.22019 17.9029 6.29789 17.9538 6.38344 17.9873C6.46899 18.0208 6.56055 18.0363 6.65236 18.0327C6.74417 18.0292 6.83426 18.0067 6.91697 17.9667C6.99968 17.9266 7.07323 17.87 7.13299 17.8002L11.9997 12.9402L16.8597 17.8002C16.9872 17.9094 17.1512 17.9665 17.319 17.96C17.4868 17.9535 17.646 17.8839 17.7647 17.7652C17.8834 17.6465 17.953 17.4873 17.9595 17.3195C17.9659 17.1518 17.9089 16.9877 17.7997 16.8602L12.9397 12.0002Z" fill="#6A6E85"/>
              </svg>
            </v-btn>
            <div class="block-upload">
              <div class="block-upload__item" :class="isErrorUpload ? 'block-upload__item--error' : ''">
                <DragZone :target="'/ajax/chat/messages/upload/'+user_id" v-bind:file.sync="form" v-bind:isErrorUpload.sync="isErrorUpload">
                  <div class="block-upload__body" :style="((form.img) ? {background: 'url('+form.img+')'} : null)">

                    <div class="block-control" :style="((form.img) ? {backgroundColor: 'rgba(0, 0, 0, 0.65)'} : 'transparent')">
                      <div class="block-img-upload">
                        <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M118.199 99.4564H106.954V88.2114H99.4569V99.4564H88.2119V106.953H99.4569V118.198H106.954V106.953H118.199V99.4564Z" stroke="#01AA4F" stroke-opacity="0.4" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M106.953 74.8635V10.5479" stroke="#01AA4F" stroke-opacity="0.4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="47.81 59.76"/>
                          <path d="M106.953 5.74833V2H103.205" stroke="#01AA4F" stroke-opacity="0.4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M93.4591 2H10.6211" stroke="#01AA4F" stroke-opacity="0.4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="48.53 60.67"/>
                          <path d="M5.74833 2H2V5.74833" stroke="#01AA4F" stroke-opacity="0.4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M2 15.4939V98.3319" stroke="#01AA4F" stroke-opacity="0.4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="48.53 60.67"/>
                          <path d="M2 103.205V106.953H5.74833" stroke="#01AA4F" stroke-opacity="0.4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M15.3477 106.953H79.6633" stroke="#01AA4F" stroke-opacity="0.4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="47.81 59.76"/>
                          <g clip-path="url(#clip0_1056_45804)">
                            <path d="M78.7004 85.9636H25.3288C23.5096 85.9636 22.0364 84.4904 22.0364 82.6712V47.2358C22.0364 45.4166 23.5096 43.9434 25.3288 43.9434H78.7133C80.5324 43.9434 82.0057 45.4166 82.0057 47.2358V82.6841C81.9929 84.4905 80.5195 85.9637 78.7004 85.9637V85.9636ZM25.3288 44.968C24.0862 44.968 23.0613 45.9801 23.0613 47.2355V82.6839C23.0613 83.9265 24.0733 84.9514 25.3288 84.9514H78.7133C79.9559 84.9514 80.9808 83.9393 80.9808 82.6839V47.2355C80.9808 45.9929 79.9687 44.968 78.7133 44.968H25.3288Z" fill="#01AA4F"/>
                            <path d="M76.3555 38.78C76.0737 38.78 75.843 38.5494 75.843 38.2675C75.843 37.0249 75.0488 36 74.0623 36H29.9663C28.9798 36 28.1855 37.0121 28.1855 38.2675C28.1855 38.5494 27.9549 38.78 27.6731 38.78C27.3913 38.78 27.1606 38.5494 27.1606 38.2675C27.1606 36.4484 28.4162 34.9751 29.9663 34.9751H74.0623C75.6124 34.9751 76.8679 36.4484 76.8679 38.2675C76.8679 38.5494 76.6373 38.78 76.3555 38.78Z" fill="#01AA4F"/>
                            <path d="M73.7938 29.8122C73.5119 29.8122 73.2813 29.5816 73.2813 29.2997C73.2813 28.0699 72.5767 27.0322 71.744 27.0322H32.2851C31.4524 27.0322 30.7478 28.0699 30.7478 29.2997C30.7478 29.5816 30.5172 29.8122 30.2353 29.8122C29.9535 29.8122 29.7229 29.5816 29.7229 29.2997C29.7229 27.4806 30.8759 26.0073 32.2851 26.0073H71.744C73.1532 26.0073 74.3062 27.4806 74.3062 29.2997C74.3062 29.5816 74.0756 29.8122 73.7938 29.8122Z" fill="#01AA4F"/>
                            <path d="M46.8902 74.4334H31.5168C31.235 74.4334 31.0044 74.2028 31.0044 73.921V68.7965C31.0044 64.2742 34.6812 60.5974 39.2035 60.5974C43.7258 60.5974 47.4026 64.2742 47.4026 68.7965V73.921C47.4026 74.2028 47.172 74.4334 46.8902 74.4334ZM32.0287 73.4085H46.3772V68.7965C46.3772 64.8379 43.1616 61.6223 39.2029 61.6223C35.2443 61.6223 32.0287 64.8379 32.0287 68.7965V73.4085Z" fill="#01AA4F"/>
                            <path d="M39.2029 61.6217C36.0898 61.6217 33.566 59.0979 33.566 55.9848C33.566 52.8717 36.0898 50.3479 39.2029 50.3479C42.316 50.3479 44.8398 52.8717 44.8398 55.9848C44.8398 59.0979 42.316 61.6217 39.2029 61.6217ZM39.2029 51.3728C36.6535 51.3728 34.5909 53.4353 34.5909 55.9848C34.5909 58.5343 36.6535 60.5968 39.2029 60.5968C41.7524 60.5968 43.8149 58.5343 43.8149 55.9848C43.8149 53.4353 41.7524 51.3728 39.2029 51.3728Z" fill="#01AA4F"/>
                            <path d="M73.7938 61.6216H53.296C53.0142 61.6216 52.7836 61.391 52.7836 61.1091C52.7836 60.8273 53.0142 60.5967 53.296 60.5967H73.7938C74.0757 60.5967 74.3063 60.8273 74.3063 61.1091C74.3063 61.391 74.0757 61.6216 73.7938 61.6216Z" fill="#01AA4F"/>
                            <path d="M73.7938 69.3088H53.296C53.0142 69.3088 52.7836 69.0782 52.7836 68.7964C52.7836 68.5145 53.0142 68.2839 53.296 68.2839H73.7938C74.0757 68.2839 74.3063 68.5145 74.3063 68.7964C74.3063 69.0782 74.0757 69.3088 73.7938 69.3088Z" fill="#01AA4F"/>
                            <path d="M73.7938 79.5578H30.2353C29.9535 79.5578 29.7229 79.3272 29.7229 79.0454C29.7229 78.7636 29.9535 78.533 30.2353 78.533H73.7938C74.0756 78.533 74.3062 78.7636 74.3062 79.0454C74.3062 79.3272 74.0756 79.5578 73.7938 79.5578Z" fill="#01AA4F"/>
                            <path d="M54.5774 52.1423H57.1396V54.7046H54.5774V52.1423Z" fill="#01AA4F"/>
                            <path d="M62.2635 52.1423H64.8258V54.7046H62.2635V52.1423Z" fill="#01AA4F"/>
                            <path d="M69.9496 52.1423H72.5118V54.7046H69.9496V52.1423Z" fill="#01AA4F"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_1056_45804">
                              <rect width="60.0879" height="60" fill="white" transform="translate(22 26)"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div class="block-upload-desc">
                        <p :style="((form.img) ? {color: '#fff'} : '#242132')" v-if="form.img">{{__('mess_card_Successfully loaded')}}</p>
                        <p :style="((form.img) ? {color: '#fff'} : '#242132')" v-else>{{__('mess_card_Upload a photo of your transaction')}}</p>
                      </div>

                      <v-btn
                              v-ripple="false"
                              class="btn-site"
                              :loading="submitDisabled"
                      >
                                <span v-if="form.img">
                                  {{__('mess_card_Update the photo')}}
                                </span>
                                <span v-else>
                                  {{__('mess_card_Unload the photo')}}
                                </span>
                      </v-btn>
                    </div>
                  </div>
                </DragZone>
                <!--                    <div ref="document_img">-->
                <!--                        <p v-if="formValidate.document_img" class="desc-error">{{formValidate.document_img[0]}}</p>-->
                <!--                    </div>-->
              </div>
              <p v-if="isErrorUpload" class="text-error">Error upload photo (minimum resolution: 480px, maximum resolution: 7000px, maximum file size: 50 Mb)</p>
            </div>
          </div>
        </div>
      </ModalDefault>
    </div>
</template>

<script>
    import MessengerMessagesList from "./MessengerMessagesList";
    import DragZone from "../../modules/DragZone";
    import ModalDefault from "../../modules/ModalDefault";
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import {required, maxLength} from "vuelidate/lib/validators";

    export default {
        name: "Dialog",
        data: function() {
            return {
                submitDisabled: false,
                loadingPage: false,
                new_message: null,
                modal_upload: null,
                active_message: null,
                formValidate: {
                    document_img: null,
                },
                form: {
                    img: null,
                    user_id: this.user_id,
                },

                isErrorUpload: false,
            }
        },
        props: {
            messages: {
                type: Object
            },
            ticket: {
                type: Object
            },
            messages_id: {
                type: Array
            },
            user_id: {
                type: [String, Number]
            }
        },
        validations: {
            new_message: {
                required,
                maxLength: maxLength(1000)
            },
        },
        mounted() {
            this.chat();
        },
        computed: {
            user() {
              return this.$store.getters.user
            },
          message() {
            return this.$store.getters.message;
          },
        },
        watch: {
            messages(messages, old) {

              if(messages.total > old.total) {
                  this.chat();
              }
            },
            active_message() {
                this.$nextTick(() => {
                    this.scrollBottom();
                });
            },
            "file.img"() {

            }
        },
        methods: {
            chat() {
                let chat= false;

                if(this.user_id== 'bot') {
                    let messages= this.$cleanObject(this.messages);
                    if(messages) {
                        messages = messages.data.reverse();
                        for (let i = 0; i < messages.length; i++) {
                            let message = messages[i];
                            if (message.opp_type == 1 && message.command && message.command != 'validate' || message.opp_type == 1 && message.message== 'new_user' ) {
                                let message_parse;
                                try {
                                    message_parse= JSON.parse(message.message);
                                } catch (e) {
                                    message_parse= message.message;
                                }
                                message.message= message_parse;
                                chat= message;
                                break;
                            }
                        }
                    }
                }
                this.active_message= chat;
                this.scrollBottom();
            },
            keypressMessages() {
                this.sendMessage(this.edit_message);
            },
            newLine(e) {
                let caret = e.target.selectionStart;
                e.target.setRangeText("\n", caret, caret, "end");
                this.new_message = e.target.value;
            },
            scrollBottom() {
                this.$nextTick(() => {
                    if(this.$refs.messages_list) {
                        setTimeout(()=>{
                            this.$refs.messages_list.scrollTo(0, this.$refs.messages_list.scrollHeight+10000);
                        }, 50);
                    }
                });
            },
            sendMessage() {
                if(!this.submitDisabled) {
                    let valid = this.$v;
                    if (valid.$invalid) {
                        let massages = {
                            new_message: [],
                        };
                        this.formValidate = this.$formValidate(massages, valid);
                    } else if(!this.ticket.status) {
                        let new_message= this.new_message;

                        this.submitDisabled = true;
                        let response = {
                            message: new_message,
                            user_id: this.user_id
                        };

                        this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/chat/messages/send", response)
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                                this.$store.dispatch('asyncResp', response.resp_tickets);
                                this.submitDisabled= false;
                                this.new_message= null;
                                // response= response.data;
                                // this.$store.dispatch("asyncMessage", response);
                            })
                            .catch((err) => {
                                this.submitDisabled = false;
                                this.httpHandler(err);
                            });
                    }
                }
            },
            scrollMessages() {
                let messages_list= this.$refs.messages_list;
                const progress= messages_list.scrollTop / (messages_list.scrollHeight - messages_list.clientHeight);
                if(!this.loadingPage && this.messages.next_page_url) {
                    if (0.5 >= progress) {
                        this.loadingPage= true;
                        let response= {
                            user_id: this.user_id,
                            messages_id: this.messages_id,
                        };
                        this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/chat/messages/open", response)
                            .then(response => {
                                return response.json()
                            })
                            .then((response) => {
                                this.$store.dispatch('asyncResp', response.resp_tickets);
                                let messages= this.$cleanObject(this.messages);
                                response= response.data.messages;
                                response.data= response.data.reverse().concat(messages.data);

                                this.$emit('update:messages', response);


                                let messages_id= this.$cleanObject(this.messages_id);
                                for(let i=0; i < response.data.length; i++) {
                                    let message= response.data[i];
                                    messages_id.push(message.id);
                                }

                                this.$emit('update:messages_id', messages_id);
                                this.loadingPage= false;
                            })
                            .catch((err) => {
                                this.loadingPage= false;
                                this.httpHandler(err);
                            });
                    }
                }
            },
        },
        components: {
            InputLabelTextDefault,
            DragZone,
            ModalDefault,
            MessengerMessagesList
        }
    }

</script>

<style lang="scss">
  .block-control {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 25px 10px 10px;
  }

  .block-control  > * {
    margin: 10px;
    width: 100%;
  }
</style>