<template>
  <div class="wrap-item" v-on:scroll="scrollPage" ref="scroll">
    <slot v-bind="items"></slot>
<!--    <span v-if="loadingPage" class="loading"><DotLoader></DotLoader> {{__('Loading')}}</span>-->
  </div>
</template>

<script>
  // import DotLoader from 'vue-spinner/src/DotLoader.vue'
    export default {
        data () {
            return {
                games_history: null,
                loadingPage: false
            }
        },
        watch: {
            params() {
                this.loadingPage= false
            }
        },
        props: {
            items: {
                type: Object
            },
            scroll: {
                type: Boolean,
                default: true
            },
            next_page_url: {
                type: String
            },
            params: {
                type: Object,
                default: Object
            },
            type: {
                type: String,
                default: 'get'
            },
        },
        mounted() {

        },
        created: function () {
          // this.$addListener(window,'scroll', ()=> {
          //       this.scrollPage();
          //   }, false);

        },
        methods: {
            scrollPage: function(){
                if(this.scroll) {
                    let next_page_url = this.next_page_url;

                    if(!next_page_url) {
                        next_page_url = this.items.next_page_url;
                    }
                    const progress= this.$refs.scroll.scrollTop / (this.$refs.scroll.scrollHeight - this.$refs.scroll.clientHeight);
                    if(!this.loadingPage && next_page_url) {
                        let bottomOfWindow = (0.7 <= progress);
                        if (bottomOfWindow) {
                            let params = this.$cleanObject(this.params);

                            if(this.type== 'post') {
                                next_page_url = this.items.path;
                                params.page = this.items.current_page+1;
                            }

                            this.loadingPage = true;

                            this.$http[this.type](next_page_url, params)
                                .then(response => {
                                    return response.json()
                                })
                                .then(response => {
                                    this.loadingPage= false;
                                    response = response.data;
                                    if(response.data) {
                                        let items = this.items;
                                        response.data = items.data.concat(response.data);
                                        this.$emit('update:items', response);

                                    } else {
                                      this.$emit('scrollPagination', response);
                                    }
                                })
                                .catch((err) => {
                                    this.loadingPage= false;
                                    this.httpHandler(err);
                                });
                        }
                    }
                }
            },
        },
        components: {
            // DotLoader,
        }
    };
</script>