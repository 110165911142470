<template>
    <a class="news-item" :href="item.url" target="_blank">

        <img class="block-img" v-if="item.img" :src="item.img" alt="">

        <div v-else class="block-img-empty">
            <img src="/dist/images/logo-ofice.png" alt="">
        </div>

        <div class="block-text">
            <div class="block-text-top">
                <h2 class="block-text__title">{{item.title}}</h2>

                <p class="block-text__description">{{item.description}}</p>
            </div>
            <div class="block-info">

<!--                <span class="dot"></span>-->
                <p class="block-info__date">{{$minAgo(date)}} ago</p>
                <span class="dot"></span>
                <p class="block-info__site">{{item.site}}</p>
<!--                <p class="block-info__category">{{item.category}}</p>-->
            </div>
        </div>
    </a>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'NewsItem',

        data: function() {
            return {
                date: moment.utc(this.item.created_at).format('X')
            }
        },
        props: {
            item: {
                type: Object
            },
        },
        created() {

        },
        watch:{

        },
        computed: {

        },
        methods: {

        },
        components: {

        },
    }
</script>

<style>

</style>
