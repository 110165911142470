<template>
  <div>
    <MessengerMessagesListItem
      v-for="(message, key) in messages.data"
      :key="message.id"
      v-bind:message="message"
      v-bind:user_id="user_id"
      v-bind:index="key"
    ></MessengerMessagesListItem>
  </div>
</template>

<script>
    import MessengerMessagesListItem from "./MessengerMessagesListItem";

    export default {
        name: 'MessengerMessagesList',
        data () {
            return {
            }
        },
        props: {
            user_id: {
                type: [String, Number]
            },
            messages: {
                type: Object
            },
        },
        computed: {

        },
        asyncComputed: {

        },
        created() {
        },
        methods: {

        },
        components: {
            MessengerMessagesListItem
        },

    }
</script>
