<template>
  <div class="text-center">
    <my-upload
      field="img"
      :langExt="langExt"
      v-if="show"
      @crop-upload-success="cropUploadSuccess"
      @crop-upload-fail="cropUploadFail"
      v-model="show"
      :width="width"
      :height="height"
      :url="this.url"
      :params="params"
      :headers="headers"
      :langType="lang"
      img-format="png"
    ></my-upload>

    <div class="img-upload" @click="toggleShow">

      <slot />
    </div>
<!--        <v-btn class="default" @click="toggleShow" small>set avatar</v-btn>-->
  </div>
</template>

<script>
import "babel-polyfill"; // es6 shim
import myUpload from "vue-image-crop-upload";

export default {
  data() {
    return {
      langExt:{
        hint: 'Click or drag the file here to upload'
      },
      show: false,
      params: this.post_params,
      headers: {
        "X-CSRF-TOKEN": this.$token,
      },
      formValidate: null,
    };
  },
  computed: {
    lang() {
      return this.$store.state.language.lang;
    },
  },
  components: {
    "my-upload": myUpload,
  },
  props: {
    img: {
      type: String,
    },
    post_params: {
      type: Object,
      default: Object,
    },
    url: {
      type: String,
    },
    response: {
      default: null,
    },
    width: {
      type: [String, Number],
      default: 150,
    },
    height: {
      type: [String, Number],
      default: 150,
    },
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    // cropSuccess(imgDataUrl, field){
    //     // console.log('-------- crop success --------');
    //     // this.imgDataUrl = imgDataUrl;
    //     // console.log('field: ' + field);
    // },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData) {
      let data = jsonData.data;
      if (data.status) {
        this.$emit("update:response", { logo: data.img });
        this.show = false;
      } else {
        if (data.validation) {
          this.formValidate = data.validation;
        }

        if (data.message) {
          this.$store.commit("updateErrorSnackbars", data.message);
        }
      }
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },
  },
};
</script>


<style lang="scss">

  .vue-image-crop-upload .vicp-wrap {
    height: 330px;
    padding-top: 50px;
    border-radius: 8px;
    cursor: default;

    /*&:after {*/
    /*  content: '';*/
    /*  text-align: left;*/
    /*  width: 600px;*/
    /*  height: 60px;*/
    /*  padding: 20px;*/
    /*  background-color: #f0f6fe;*/
    /*  position: absolute;*/
    /*  left: 0;*/
    /*  top: 0;*/
    /*  border-radius: 8px 8px 0 0;*/
    /*  font-weight: 400;*/
    /*  font-size: 18px;*/
    /*  line-height: 22px;*/
    /*  color: #002b50;*/
    /* }*/

  }

  .vue-image-crop-upload .vicp-wrap .vicp-step1 .vicp-drop-area {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 35px;
    height: 190px;
    background-color: transparent;
    text-align: center;
    border: 4px dashed #ecf4ff;
    border-radius: 8px;
    overflow: hidden;
  }

  .vue-image-crop-upload .vicp-wrap .vicp-step1 .vicp-drop-area:hover {
    cursor: pointer;
    border-color: #ecf4ff;
    background-color: transparent;
  }

  .vue-image-crop-upload .vicp-wrap .vicp-step1 .vicp-drop-area .vicp-hint {
    display: block;
    padding: 15px;
    font-size: 22px;
    color: #96afd1;
    line-height: 30px;
  }

  /*.vue-image-crop-upload .vicp-wrap .vicp-step1 .vicp-drop-area .vicp-icon1 {*/
  /*  display: none;*/
  /*}*/

  .vue-image-crop-upload .vicp-wrap .vicp-step1 .vicp-drop-area .vicp-icon1 .vicp-icon1-arrow {
    display: block;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-bottom: 14.7px solid #E7EFFF;
    border-left: 14.7px solid transparent;
    border-right: 14.7px solid transparent;
  }
  .vue-image-crop-upload .vicp-wrap .vicp-step1 .vicp-drop-area .vicp-icon1 .vicp-icon1-body {
    display: block;
    width: 12.6px;
    height: 14.7px;
    margin: 0 auto;
    background-color: #E7EFFF;
  }
  .vue-image-crop-upload .vicp-wrap .vicp-step1 .vicp-drop-area .vicp-icon1 .vicp-icon1-bottom {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    height: 12.6px;
    border: 6px solid #E7EFFF;
    border-top: none;
  }

  .vue-image-crop-upload .vicp-wrap .vicp-operate a {
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    transition: 300ms;
    padding: 0 20px;
    border: 1px solid rgba(39, 39, 39, 0.1);
    border-radius: 8px;
    display: inline-block;
    background-color: #ffffff;
    color: rgba(39, 39, 39, 0.7);
    box-sizing: border-box;

    &:hover {
      transition: 300ms;
      background: #f3f3f3;
      color: rgba(39, 39, 39, 0.9);
    }

    &:nth-child(2) {
      background-color: #01AA4F;
      color: #fff;

      &:hover {
        background-color: #13964f;
      }
    }

  }

  .vue-image-crop-upload .vicp-wrap .vicp-close {
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 10;
  }

  .vue-image-crop-upload .vicp-wrap .vicp-close .vicp-icon4::after, .vue-image-crop-upload .vicp-wrap .vicp-close .vicp-icon4::before {
    box-shadow: none;
    background-color: #96AFD1;
    height: 2px;
    width: 14px;
  }

  .vue-image-crop-upload .vicp-wrap .vicp-close .vicp-icon4:hover {
     transform: rotate(0);
     transform: scale(1.1);
  }

  .vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range .vicp-icon5, .vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range .vicp-icon6 {
    position: absolute;
    top: -3px;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: #E7EFFF;

    &:hover {
      background-color: #CDDFF7;
    }
  }
  .vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-right .vicp-preview .vicp-preview-item span {
    color: #96AFD1;
  }

  .vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-right .vicp-preview .vicp-preview-item img {
    border: 1px solid #CDDFF7;
  }

  @media screen and (max-width: 600px) {
    .vue-image-crop-upload .vicp-wrap .vicp-step1 .vicp-drop-area {
      height: 220px;
      margin-top: 80px;
    }

    .vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-right .vicp-preview .vicp-preview-item {
      margin-right: 40px;
    }
  }
</style>
