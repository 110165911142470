import Vue from 'vue'
import App from './App.vue'
import 'vue-awesome/icons/flag';
import 'vue-awesome/icons';
import Icon from 'vue-awesome/components/Icon';
import './assets/css/main.scss';
import Routes from './routes/routes';
import VueResource from 'vue-resource';
import AsyncComputed from 'vue-async-computed';
import { VclFacebook, VclInstagram, VueContentLoading, VclTable  } from 'vue-content-loading';
import vuetify from "@/plugins/vuetify";
require('./env');
import Vuelidate from 'vuelidate';
window.Pusher = require('pusher-js');
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts);
// window.io = require('socket.io-client');
import Notifications from 'vue-notification';
// import Echo from "laravel-echo";
import VueTabsWithActiveLine from "./components/modules/VueTabsWithActiveLine";
import store from './store';

import VueSocketIO from 'vue-socket.io';
import SocketIO from 'socket.io-client';


var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo)

import VAnimateCss from 'v-animate-css';

Vue.use(VAnimateCss);

import vWow from 'v-wow';
Vue.use(vWow);



import moment from 'moment';

Vue.component('Tabs', VueTabsWithActiveLine);

Vue.filter('truncate', function (text, stop, clamp) {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
})


Vue.filter('moment', function(value, format='MMM DD, YYYY') {
    if (value) {
        return moment.utc(String(value)).format(format);
    } else {
        return moment.utc().format(format);
    }
});
Vue.filter('formatTime', function(value) {
    if (value) {
        return moment.utc(String(value)).format('HH:mm');
    } else {
        return moment.utc().format('HH:mm');
    }
});
Vue.filter('formatFullTime', function(value) {
    if (value) {
        return moment.utc(String(value)).format('YYYY/MM/DD HH:mm:ss');
    } else {
        return moment.utc().format('YYYY/MM/DD HH:mm:ss');
    }
});

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment.utc(String(value)).format('DD.MM.YYYY HH:mm');
    } else {
        return moment.utc().format('DD.MM.YYYY HH:mm');
    }
});

Vue.filter('formatYMD', function(value) {
    if (value) {
        return moment.utc(String(value)).format('DD.MM.YYYY');
    } else {
        return moment.utc().format('DD.MM.YYYY');
    }
});


let token = document.head.querySelector('meta[name="csrf-token"]');
//
// window.io = require('socket.io-client');
//
// Vue.prototype.$echo= new Echo({
//     broadcaster: "socket.io",
//     key: 'c71c3982b312f7b5fa86ca04a83bdfd7',
//     cluster: 'mt1',
//     wsHost: 'kripers.org',
//     host: 'https://kripers.org:8000',
//     // csrfToken : token,
//     wsPort: 8000,
//     wssPort: 8000,
//     disableStats: true,
//     // authEndpoint: '/broadcast',
//     authEndpoint: "broadcasting/auth",
//     // encrypted: true,
//     auth: {
//         headers: {
//             authorization: 'Bearer ' + token,
//             'X-CSRF-TOKEN': 'too_long_csrf_token_hardcoded'
//         }
//     }
// });

Vue.use(new VueSocketIO({
    'debug': true,
    'connection': SocketIO(location.hostname, {
        extraHeaders: {
            'Authorization': 'Bearer '+ token,
            'X-CSRF-TOKEN': 'too_long_csrf_token_hardcoded',
            'Authoriz': 'Bearer'
        }
    }),
    'options': {path: '/socket/'}
}));


Vue.use(Vuelidate);
Vue.use(Notifications);

Vue.component('v-icon', Icon);

require('./locale');
require('./global_function');
Vue.use(VueResource);

Vue.config.productionTip = false;

Vue.http.options.root= (process.env.NODE_ENV === 'production') ? Vue.prototype.$APP_URL+'/' : 'http://localhost:3000/';

var tokenCSRF = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
Vue.http.interceptors.push(request=> {
    request.headers.set('X-CSRF-TOKEN', tokenCSRF);
});

Vue.use(AsyncComputed);
Vue.component('VclFacebook', VclFacebook);
Vue.component('VclInstagram', VclInstagram);
Vue.component('VueContentLoading', VueContentLoading);
Vue.component('VclTable', VclTable);

new Vue({
    router: Routes,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app');
